import React, {useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import './style.css'
import Grid from "@material-ui/core/Grid";
import Deleteroom from "./icons/Delete.svg";
import Meetingroom from "./icons/room.svg";
import Draghandle from "./icons/Move.svg";

export function SortableItem({item, selected, toggleSelect, handleDelete,onIdClick, ...restProps}) {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: item.id});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  function handleMouseDown() {
    toggleSelect(item.id)
  }
  function handlegetidClick(){
    onIdClick(item.id,item.meetingname,item.mid)
  }

  const selectedClassName = selected ? 'selected' : ''
  const className = `${selectedClassName}`;
  return (
      <>
    <div
        className="divcss"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...restProps}
    >
      <Grid container spacing={0}>
        <Grid item xs={1}  className={className} onMouseDown={handleMouseDown}>
          <div className="cccenter">
          <img src={Draghandle} alt="drag this" width="15px"/>
          </div>
        </Grid>
        <Grid item xs={10} onClick={() => {
          handlegetidClick(item.id,item.meetingname,item.mid);
        }} className="hightlightroomlist">
          <img src={Meetingroom} alt="meeting room" width="18px" />
          &nbsp;
          <span> {item.meetingname}</span>
        </Grid>
        <Grid item xs={1} className="rightside">
        <img src={Deleteroom} onClick={() => {
          handleDelete(item.id);
        }} width="30" height="30" alt="delete room"/>
        </Grid>
      </Grid>
      <hr className="itemshrcss"/>
    </div>
        </>
  );
}
