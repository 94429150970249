import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import VideoRecorder from "react-video-recorder";
import {
    useParams,
    useHistory
} from "react-router-dom";
import "./videorecordpager.css";
import Leftarrow from "../../img/left-arrow.png";

const FromVideoRecorder = ({ push }) => {
    const { id } = useParams();
    return (
        <VideoRecorder
            chunkSize={50}
            isFlipped={true}
            timeLimit = {120000}
            countdownTime={3000}
            mimeType='video/webm;codecs="vp8,opus"'
            constraints={{
                audio: true,
                video: {
                    width: { exact: 480, ideal: 480 },
                    height: { exact: 360, ideal: 360 },
                    aspectRatio: { exact: 1, ideal: 1 },
                    resizeMode: "crop-and-scale"
                }
            }}
            showReplayControls={true}
            onRecordingComplete={(videoBlob) => {
                push(`/teamsideopreviewpager/${id}`, { videoBlob });
            }}
        />
    );
};

const Teamsideorecordr = (props) => {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    function scp(){
        history.push(`/teamseditroom/${id}`);
    }
    return (
        isOwner === isUser ?
            <>
                <div className="uploadpptx">
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <br/>
        <div className="videorecordepage">
            <p>Record a video up to 2 minutes long. When you stop, you will be able to review, re-record, or use the video.
                <br/>Usually available in 1 minute to view.</p>
            <div style={{ width: "100%", maxWidth: 480, height: 360 }}>
                <FromVideoRecorder push={props.history.push} />
            </div>
        </div>
                </div>
            </>
                :""
    );
};

export default Teamsideorecordr
