import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {API, Auth} from "aws-amplify";
import Foot from "../components/Foot";
import {Form, Row, Col, Button} from 'react-bootstrap';
import Navbars from "../components/Navbar";
import "./css/profile.css";
import Login from "./login";
import {Helmet} from "react-helmet";

const Profile = ()  => {
    const history = useHistory();
    const [user, setUser] = useState();
    const [isEmail, setIsemail] = useState();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                const username = user.username;
                setIsemail(user.attributes.email);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSignout = async () => {
        try {
            await Auth.signOut();
            history.replace("/");
            window.location.reload();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    async function deleteUser() {
        let text = "Are you sure you want to delete this account? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                await Auth.deleteUser();
                history.replace("/");
                window.location.reload();
            } catch (error) {
                console.log('Error deleting user', error);
            }
        }
    }
    return(
        <>
            <Helmet>
                <title>MY ACCOUNT</title>
            </Helmet>
            <Navbars />
            {!user ?
                <Login />
                :(
                <div className="profiles">
                    <Form>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="4">
                                Email
                            </Form.Label>
                            <Col sm="8">
                                <p>{isEmail}</p> <p user={user} className="newunderline" onClick={handleSignout}>Sign Out</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <br/>
                        <br/>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Form.Label column sm="4">
                            </Form.Label>
                            <Col sm="8">
                                <p user={user}  className="newunderline" onClick={deleteUser}>Delete my Account</p>
                            </Col>
                        </Form.Group>
                    </Form>
                    </div>
                )
            }
                    <Foot />
                </>

    )
}


export default Profile;
