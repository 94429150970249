import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";

function Iroom() {
    const [curl, setCurl] = useState("");
    const { id } = useParams();
    const history = useHistory();
    const [showthis, setShowthis] = useState(false);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    })
    useEffect(() => {
        const reachumnname = localStorage.getItem("vreachumnewname");
        if(!reachumnname){
            setShowthis(true)
        }
    }, []);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const v = datas.aplaypause;
            if(v === true){
                const thetimer = document.getElementById('theFrame');
                thetimer.src =  "/timer";
            }else{
                setCurl(datas.clink);
            }
            const isplay = datas.vplaypause;
            if(isplay === true){
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
            }else{
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
            }
        }catch(error){
            console.log("hi")
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    const closeNavs = () => {
        document.getElementById("myNavs").style.width = "0%";
    }
    useHotkeys('/', () => {
        infobuttons()
    })
    function ends(){
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            localStorage.setItem("vreachumnewname", ssname);
            setShowthis(false)
        }catch(err){
            console.log(err)
        }
    }
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {curl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    width="100%"
                                    height="100vh"
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className="bottoms">
                        <Grid item xs={7}>
                            <div className="whitethis">
                                <i>Screen blank, please tap.</i>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <img src={Ending} onClick={ends} className="viewbimg"/>
                        </Grid>
                    </Grid>
                </div>
            <Modal
                size="lg"
                show={showthis}
                centered
            >
                <Modal.Body>
                    <div className="enteryournameform">
                        <Form className="market-header">
                            <p>Please fill out the fields below to track participation during activities and events. </p>
                            <br/>
                            <h5>Your Full Name</h5>
                            <Form.Item>
                                <Input
                                    type="text"
                                    value={stateData.meetname}
                                    onChange={meetname => setStateData({
                                        ...stateData,
                                        meetname: meetname
                                    })}
                                    maxLength={80}
                                    placeholder="Full Name"
                                    className="craeteroominput"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6} className="rightside">
                                        <Button
                                            disabled={!stateData.meetname}
                                            onClick={handleSubmit2}
                                            size="lg"
                                            className="ibuttnsform"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Iroom;
