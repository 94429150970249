import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateTextslide} from '../../graphql/mutations';
import {useParams} from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex'
import "../css/poll.css";
import "./style.css";
import Resizer from "react-image-file-resizer";
import {getPlayslide, getTextslide} from "../../graphql/queries";
const resizeFile = (file,width,height) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const buttonList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['paragraphStyle', 'blockquote'],
    ['table', 'link', 'image', 'video', 'audio', 'math'],
    [ 'showBlocks', 'codeView'],
    ['preview', 'print'],
    ['save']
]
const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
];
function Newerslideedit() {
    const { id,rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [defaultValue, setDefaultValue] = useState("")
    const [defaultFontSize, setDefaultFontSize] = useState('24px');
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
        fetchDas()
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getTextslide,
                variables: {id:rid}
            })
            const datas = response.data.getTextslide;
            const tests = datas.bgcolor;
            if(tests !== null) {
                setDefaultValue(tests);
            }
        }catch(err){
            console.log(err)
        }
    }
    const sortedFontOptions = [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        "Roboto",
        "Poppins",
        ...defaultFonts
    ].sort();
    const defaultSizes = [
        14, 16, 18, 20, 22, 24, 26, 28,30,34,36,40,48,56,60,68,72,82
    ].sort();
    const options = {
        buttonList,
        katex,
        font: sortedFontOptions,
        fontSize: defaultSizes,
        defaultStyle: `font-size: ${defaultFontSize};`,
        onChange: (contents, core) => {
            const currentFontSize =
                core.getSelectedElement().style.fontSize || defaultFontSize;
            setDefaultFontSize(currentFontSize);
        },
        minHeight: '100vh',
        callBackSave: handleSave
    }
    function handleSave (content) {
        try {
            const input = {
                id: rid,
                bgcolor:content
            }
            API.graphql({
                query: updateTextslide,
                variables: {input}
            });
            setUploadedmessage("Updated!")

        } catch (err) {
            console.log(err)
        }
    }
    async function handleImageUpload(target, index, state, imageInfo) {
        if(target) {
            const response = await fetch(target.src);
            const blob = await response.blob()
            const resized = await resizeFile(blob, target.width, target.height)
            target.src = resized
        }
    }
    return (
        isOwner === isUser ?
        <>
            <div className="slidepage">
                <br/>
                <div className="cccenter">
                    <span className="redthis">{uploadedmessage}</span>
                </div>
                <SunEditor setOptions={options} setContents={defaultValue} onImageUpload={handleImageUpload} />
                <br/>
                <br/>
            </div>
        </>
            :""
    );
}
export default Newerslideedit;
