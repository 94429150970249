import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "../css/menus.css";
import {Modal} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Info from "../../img/dash/pop-up-icon.svg";
import Textslidedis from "../../img/menu/dis/1-text-slide.png";
import Imagedis from "../../img/menu/dis/2-image.png";
import Videodis from "../../img/menu/dis/3-video.png";
import Pptxdis from "../../img/menu/dis/4-pptx.png";
import Pdfdis from "../../img/menu/dis/5-pdf.png";
import Gifdis from "../../img/menu/dis/6-gif.png";
import Linktodis from "../../img/menu/dis/7-link-to-webpage.png";
import Videolinkdis from "../../img/menu/dis/8-video-link.png";
import Recordvideo1dis from "../../img/menu/dis/10-1-record-video.png";
import Recordvideo2dis from "../../img/menu/dis/10-2-record-video.png";
import Giphydis from "../../img/menu/dis/gif.gif";
import Pollsdis from "../../img/menu/dis/Polls.gif";
import Openended1dis from "../../img/menu/dis/eazy-ask-response-submission.gif";
import Openended2dis from "../../img/menu/dis/eazyask-highlight-a-response.gif";
import Openended3dis from "../../img/menu/dis/eazyask-viewer-experience.gif";
import Singleselectdis from "../../img/menu/dis/Single-Select.gif";
import Multiselectdis from "../../img/menu/dis/Multi-Select.gif";
import Fillindis from "../../img/menu/dis/Fill-in-the-Blank.gif";
import Videoquiz1dis from "../../img/menu/dis/Video-Quiz.gif";
import Mmgdis from "../../img/menu/dis/Memory-Matching-Game.gif";
import Taptapdis from "../../img/menu/dis/Tap-Tap.gif";
import Sequencingdis from "../../img/menu/dis/sequrncing.gif";
import Rankingpoll from "../../img/menu/dis/Ranking-Poll.gif";
import Adobeoverview from "../../img/menu/dis/overview-express.jpg";
import Wordcloudoverview from "../../img/menu/dis/word-cloud_640x360.jpg";
import Answer from "../../components/answers/answer";
import Blackboard from "../../components/blackboard/blackboard";
import Giphy from "../../components/gif/giphy";
import Fastanswer from "../../components/leaderboardgames/fastanswer/fastanswer";
import Flipcards from "../../components/leaderboardgames/flipcard/flipcards";
import Createmultioptions from "../../components/leaderboardgames/multipleoptions/createmultioptions";
import Createoptions from "../../components/leaderboardgames/options/createoptions";
import Createsequencing from "../../components/leaderboardgames/sequencing/createsequencing";
import Categorydragdrop from "../../components/leaderboardgames/taptap/categorydragdrop";
import Videoquizfile from "../../components/leaderboardgames/videoquiz/videoquizfile"
import Polls from "../../components/poll/polls"
import Createranking from "../../components/ranking/createranking";
import Textslide from "../../components/slides/textslide";
import Uploadimg from "../../components/uploadfiles/img/uploadimg";
import Uploadpdf from "../../components/uploadfiles/pdf/uploadpdf";
import Uploadpptx from "../../components/uploadfiles/pptx/uploadpptx";
import Uploadgifs from "../../components/uploadfiles/uploadgif/uploadgifs";
import Uploadvideo from "../../components/uploadfiles/vid/uploadvideo";
import Uploadurl from "../../components/uploadurl/uploadurl";
import Uploadvimeo from "../../components/vimeo/uploadvimeo";
import Words from "../../components/wordcloud/words";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const Menus = ({ id })  => {
    const history = useHistory();
    const [show31, setShow31] = useState(false);
    const handleClose31 = () => {setShow31(false)};
    const [show32, setShow32] = useState(false);
    const handleClose32 = () => {setShow32(false)};
    const [show33, setShow33] = useState(false);
    const handleClose33 = () => {setShow33(false)};
    const [show34, setShow34] = useState(false);
    const handleClose34 = () => {setShow34(false)};
    const [show35, setShow35] = useState(false);
    const handleClose35 = () => {setShow35(false)};
    const [show36, setShow36] = useState(false);
    const handleClose36 = () => {setShow36(false)};
    const [show37, setShow37] = useState(false);
    const handleClose37 = () => {setShow37(false)};
    const [show38, setShow38] = useState(false);
    const handleClose38 = () => {setShow38(false)};
    const [show40, setShow40] = useState(false);
    const handleClose40 = () => {setShow40(false)};
    const [show10, setShow10] = useState(false);
    const handleClose10 = () => {setShow10(false)};
    const [show11, setShow11] = useState(false);
    const handleClose11 = () => {setShow11(false)};
    const [show12, setShow12] = useState(false);
    const handleClose12 = () => {setShow12(false)};
    const [show13, setShow13] = useState(false);
    const handleClose13 = () => {setShow13(false)};
    const [show14, setShow14] = useState(false);
    const handleClose14 = () => {setShow14(false)};
    const [show15, setShow15] = useState(false);
    const handleClose15 = () => {setShow15(false)};
    const [show16, setShow16] = useState(false);
    const handleClose16 = () => {setShow16(false)};
    const [show18, setShow18] = useState(false);
    const handleClose18 = () => {setShow18(false)};
    const [show20, setShow20] = useState(false);
    const handleClose20 = () => {setShow20(false)};
    const [show21, setShow21] = useState(false);
    const handleClose21 = () => {setShow21(false)};
    const [show22, setShow22] = useState(false);
    const handleClose22 = () => {setShow22(false)};
    const [show23, setShow23] = useState(false);
    const handleClose23 = () => {setShow23(false)};
    const [show24, setShow24] = useState(false);
    const handleClose24 = () => {setShow24(false)};
    const [show50, setShow50] = useState(false);
    const handleClose50 = () => {setShow50(false)};
    const [show51, setShow51] = useState(false);
    const handleClose51 = () => {setShow51(false)};
    const [show53, setShow53] = useState(false);
    const handleClose53 = () => {setShow53(false)};
    const [show55, setShow55] = useState(false);
    const handleClose55 = () => {setShow55(false)};
    const [show56, setShow56] = useState(false);
    const handleClose56 = () => {setShow56(false)};
    const [show60, setShow60] = useState(false);
    const handleClose60 = () => {setShow60(false)};
    const [show61, setShow61] = useState(false);
    const handleClose61 = () => {setShow61(false)};
    const [show62, setShow62] = useState(false);
    const handleClose62 = () => {setShow62(false)};
    const [show63, setShow63] = useState(false);
    const handleClose63 = () => {setShow63(false)};
    const [show64, setShow64] = useState(false);
    const handleClose64 = () => {setShow64(false)};
    const [show66, setShow66] = useState(false);
    const handleClose66 = () => {setShow66(false)};
    const [show67, setShow67] = useState(false);
    const handleClose67 = () => {setShow67(false)};
    const [show68, setShow68] = useState(false);
    const handleClose68 = () => {setShow68(false)};
    const [show69, setShow69] = useState(false);
    const handleClose69 = () => {setShow69(false)};
    const [show70, setShow70] = useState(false);
    const handleClose70 = () => {setShow70(false)};
    const [show71, setShow71] = useState(false);
    const handleClose71 = () => {setShow71(false)};
    const [show72, setShow72] = useState(false);
    const handleClose72 = () => {setShow72(false)};
    const [show73, setShow73] = useState(false);
    const handleClose73 = () => {setShow73(false)};
    const [show74, setShow74] = useState(false);
    const handleClose74 = () => {setShow74(false)};
    const [show75, setShow75] = useState(false);
    const handleClose75 = () => {setShow75(false)};
    const [show76, setShow76] = useState(false);
    const handleClose76 = () => {setShow76(false)};


    const videofunctions = async () => {
        setShow73(true);
    }
    const pdffunctions = async () => {
        setShow70(true);
    }
    const imgfunctions = async () => {
        setShow69(true);
    }
    const urlfunctions = async () => {
        setShow74(true)
    }
    const vimeofunctions = async () => {
        setShow75(true)
    }
    const pptxfunctions = async () => {
        setShow71(true);
    }
    const giffunctions = async () => {
        setShow53(true);
    }
    const pollfunctions = async () => {
        setShow66(true)
    }
    const cosgiffunctions = async () => {
        setShow72(true);
    }
    const fastfunctions = async () => {
        setShow55(true)
    }
    const optionsfunctions = async () => {
        setShow61(true);
    }
    const flipcardfunctions = async () => {
        setShow56(true)
    }
    const videoquizefunctions = async () => {
        setShow64(true)
    }
    const recordfunctions = async () => {
        history.push(`/teamsideorecordr/${id}`);
    }
    function godragdrop(){
        setShow63(true);
    }
    function gomultiple(){
        setShow60(true);
    }
    function textfunctions(){
        setShow68(true)
    }
    function answeraquestionfunctions(){
        setShow50(true)
    }
    function sequecingfunctions(){
        setShow62(true);
    }
    function rankingfunctions(){
        setShow67(true)
    }
    function adobefunction(){
        history.push(`/teamsadobe/${id}`);
    }

    function wordcloudfunctions(){
        setShow76(true)
    }
    return(
        <>
                    <Table responsive bordered>
                        <tbody>
                        <tr>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlinemedia">Media</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Upload existing content or create new assets without leaving the platform.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={pptxfunctions}>Upload PowerPoint </span>
                                        <img src={Info} onClick={() => {
                                            setShow34(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={urlfunctions}>Show Webpage </span>
                                        <img src={Info} onClick={() => {
                                            setShow37(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={textfunctions}>Text Slide </span>
                                        <img src={Info} onClick={() => {
                                            setShow31(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={imgfunctions}>Upload Image </span>
                                        <img src={Info} onClick={() => {
                                            setShow32(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={videofunctions}>Upload Video </span>
                                        <img src={Info} onClick={() => {
                                            setShow33(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={vimeofunctions}>Video Link </span>
                                        <img src={Info} onClick={() => {
                                            setShow38(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={recordfunctions}>Record a Video </span>
                                        <img src={Info} onClick={() => {
                                            setShow10(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={pdffunctions}>Upload PDF </span>
                                        <img src={Info} onClick={() => {
                                            setShow35(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={cosgiffunctions}>Upload GIF </span>
                                        <img src={Info} onClick={() => {
                                            setShow36(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={giffunctions}>Giphy Library </span>
                                        <img src={Info} onClick={() => {
                                            setShow11(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={adobefunction}>Adobe Express </span>
                                        <img src={Info} onClick={() => {
                                            setShow23(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlinediscussion">Discussion Tools</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Stimulate animated conversations using Discussion Tools. Identities protected.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={pollfunctions}>Poll </span>
                                        <img src={Info} onClick={() => {
                                            setShow12(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={answeraquestionfunctions}>SafeAsk </span>
                                        <img src={Info} onClick={() => {
                                            setShow13(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={wordcloudfunctions}>Word Cloud </span>
                                        <img src={Info} onClick={() => {
                                            setShow24(true)
                                        }} width="18px"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={rankingfunctions}>Ranking Poll </span>
                                        <img src={Info} onClick={() => {
                                            setShow40(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlineleaderboard">Leaderboard Games</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Create leaderboard game competition using time-based or untimed scoring.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={optionsfunctions}>Single Select </span>
                                        <img src={Info} onClick={() => {
                                            setShow14(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={gomultiple}>Multi Select </span>
                                        <img src={Info} onClick={() => {
                                            setShow15(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={fastfunctions}>Fill in the Blank </span>
                                        <img src={Info} onClick={() => {
                                            setShow16(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={videoquizefunctions}>Video Quiz </span>
                                        <img src={Info} onClick={() => {
                                            setShow18(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={godragdrop}>Tap Tap </span>
                                        <img src={Info} onClick={() => {
                                            setShow21(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={sequecingfunctions}>Sequencing </span>
                                        <img src={Info} onClick={() => {
                                            setShow22(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={flipcardfunctions}>Memory Matching Game </span>
                                        <img src={Info} onClick={() => {
                                            setShow20(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
            <Modal show={show31} onHide={handleClose31} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Text Slide</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Textslidedis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Create an editable text slide with our rich text editor. Use this tool to create a simple slide or a complex navigation menu.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show32} onHide={handleClose32} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Image</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Imagedis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a jpeg or png image.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show33} onHide={handleClose33} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videodis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a video for precise playback control across all student devices.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show34} onHide={handleClose34} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">PowerPoint</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pptxdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a PowerPoint deck. PowerPoint files are converted to separate static images that can be reordered or deleted within the REACHUM platform. Please ensure that the fonts are embedded in the PPTX file before uploading.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show35} onHide={handleClose35} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">PDF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pdfdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a PDF. PDF pages are converted to individual slides that can be reordered or deleted within the REACHUM platform.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show36} onHide={handleClose36} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">GIF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Gifdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload your own animated gifs in REACHUM.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show37} onHide={handleClose37} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Show Webpage</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Linktodis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Anything available by link is shareable in REACHUM, with full collaboration possible. Work together on documents and spreadsheets, or send a 3rd party quiz, a poll, or a shopping cart for secure, in-class transactions!</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show38} onHide={handleClose38} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video Link</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videolinkdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>YouTube and Vimeo links are shareable in REACHUM.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show10} onHide={handleClose10} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Record a Video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="videoss"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="videoss" title="Record video">
                                <img src={Recordvideo1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="displayvideos" title="Play video">
                                <img src={Recordvideo2dis}  className="disfull"/>
                            </Tab>
                        </Tabs>
                        <br/>
                        <br/>
                        <p>REACHUM hosts can record a video up to 2 minutes long.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show11} onHide={handleClose11} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Giphy Library</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="centerthisgiphy">
                        <img src={Giphydis}  width="360px"/>
                        <br/>
                        <br/>
                        <p>Import a gif to set the  mood using GIPHY, the largest library of animated gifs on the internet.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show12} onHide={handleClose12} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Polls</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pollsdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Measure your audience’s attitude, sentiment, or opinion with a poll.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show13} onHide={handleClose13} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">SafeAsk</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="teacher"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="teacher" title="Teacher view">
                                <img src={Openended1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="highlight" title="Spotlight a response">
                                <img src={Openended2dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="student" title="Student view">
                                <img src={Openended3dis}  className="disfull"/>
                            </Tab>
                        </Tabs>
                        <br/>
                        <br/>
                        <p>Ask your class an open-ended question and receive identified or anonymous responses. Click on a response to display it on student screens to stimulate conversation.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show14} onHide={handleClose14} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Single Select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Singleselectdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Teachers ask a question and learners select the single correct answer.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show15} onHide={handleClose15} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Multi Select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Multiselectdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Use for questions with more than one correct answer. Scores are weighted by the number of correct answers.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show16} onHide={handleClose16} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Fill in the Blank</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Fillindis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Students type their answer in a single input field. Perfect matches score points.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show18} onHide={handleClose18} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video Quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videoquiz1dis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Record or upload a video and insert  a timed quiz at a specified time.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show20} onHide={handleClose20} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Memory Matching Game</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mmgdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Click one of 12 cards to flip it over and reveal its value. Click another card to try and find a match. When a match is revealed, the pair disappears. The game is successfully completed when all pairs are matched.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show21} onHide={handleClose21} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Tap Tap</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Taptapdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Users tap a list item to select it and tap the  corresponding category to add. Scores are weighted by the number of correct answers.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show22} onHide={handleClose22} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Sequecing</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Sequencingdis}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Sequencing is useful for assessing timeline knowledge, number sense, process steps, paragraph structures, and more.</p>
                        <p>Use this game for activities that require putting items in a particular order.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show40} onHide={handleClose40} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Ranking Poll</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Rankingpoll}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>A ranking poll is a survey where people order a list of items by preference. You can use it for elections, product preferences, event planning, decisions, or market research.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show23} onHide={handleClose23} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Adobe Express</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Adobeoverview}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Adobe Express enables you to create designs more efficiently with access to a vast library of professionally designed templates, Adobe Stock photos, videos, music, and more.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show24} onHide={handleClose24} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Word Cloud</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Wordcloudoverview}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Word Cloud is an excellent icebreaker activity to kick off a meeting, workshop, or any gathering where participants are encouraged to engage and interact. Limit student responses to 25 characters.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show50} onHide={handleClose50} onClose={handleClose50} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Answer id={id} handleClose50={handleClose50}/>
                </Modal.Body>
            </Modal>
            <Modal show={show51} onHide={handleClose51} onClose={handleClose51} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Blackboard id={id} handleClose51={handleClose51}/>
                </Modal.Body>
            </Modal>
            <Modal show={show53} onHide={handleClose53} onClose={handleClose53} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Giphy id={id} handleClose53={handleClose53}/>
                </Modal.Body>
            </Modal>
            <Modal show={show55} onHide={handleClose55} onClose={handleClose55} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Fastanswer id={id} handleClose55={handleClose55}/>
                </Modal.Body>
            </Modal>
            <Modal show={show56} onHide={handleClose56} onClose={handleClose56} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Flipcards id={id} handleClose56={handleClose56}/>
                </Modal.Body>
            </Modal>
            <Modal show={show60} onHide={handleClose60} onClose={handleClose60} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createmultioptions id={id} handleClose60={handleClose60}/>
                </Modal.Body>
            </Modal>
            <Modal show={show61} onHide={handleClose61} onClose={handleClose61} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createoptions id={id} handleClose61={handleClose61}/>
                </Modal.Body>
            </Modal>
            <Modal show={show62} onHide={handleClose62} onClose={handleClose62} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createsequencing id={id} handleClose62={handleClose62}/>
                </Modal.Body>
            </Modal>
            <Modal show={show63} onHide={handleClose63} onClose={handleClose63} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Categorydragdrop id={id} handleClose63={handleClose63}/>
                </Modal.Body>
            </Modal>
            <Modal show={show64} onHide={handleClose64} onClose={handleClose64} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Videoquizfile id={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show66} onHide={handleClose66} onClose={handleClose66} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Polls id={id} handleClose66={handleClose66}/>
                </Modal.Body>
            </Modal>
            <Modal show={show67} onHide={handleClose67} onClose={handleClose67} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createranking id={id} handleClose67={handleClose67}/>
                </Modal.Body>
            </Modal>
            <Modal show={show68} onHide={handleClose68} onClose={handleClose68} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Textslide id={id} handleClose68={handleClose68}/>
                </Modal.Body>
            </Modal>
            <Modal show={show69} onHide={handleClose69} onClose={handleClose69} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadimg id={id} handleClose69={handleClose69}/>
                </Modal.Body>
            </Modal>
            <Modal show={show70} onHide={handleClose70} onClose={handleClose70} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadpdf id={id} handleClose70={handleClose70}/>
                </Modal.Body>
            </Modal>
            <Modal show={show71} onHide={handleClose71} onClose={handleClose71} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadpptx id={id} handleClose71={handleClose71}/>
                </Modal.Body>
            </Modal>
            <Modal show={show72} onHide={handleClose72} onClose={handleClose72} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadgifs id={id} handleClose72={handleClose72}/>
                </Modal.Body>
            </Modal>
            <Modal show={show73} onHide={handleClose73} onClose={handleClose73} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadvideo id={id} handleClose73={handleClose73}/>
                </Modal.Body>
            </Modal>
            <Modal show={show74} onHide={handleClose74} onClose={handleClose74} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadurl id={id} handleClose74={handleClose74}/>
                </Modal.Body>
            </Modal>
            <Modal show={show75} onHide={handleClose75} onClose={handleClose75} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadvimeo id={id} handleClose75={handleClose75}/>
                </Modal.Body>
            </Modal>
            <Modal show={show76} onHide={handleClose76} onClose={handleClose76} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Words id={id} handleClose76={handleClose76}/>
                </Modal.Body>
            </Modal>
                </>

    )
}


export default Menus;
