import React, {useEffect,useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import "../css/vshowroom.css";

function Iurlshowroom() {
    const [lvfile, setLvfile] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const datasn = datas.playlistlinks;
            const newdatas = datasn.filter(data => data.rid === rid);
            const vbucket = newdatas[0].lurl;
            setLvfile(vbucket);
        }catch(error){
            console.log("can't display")
        }
    }
    return (
        <>
            <iframe src={lvfile} className="iurlroom" allowfullscreen allowpaymentrequest></iframe>
        </>
    );
}

export default Iurlshowroom;
