/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const newCreatePlayslide = /* GraphQL */ `
  subscription NewCreatePlayslide {
    newCreatePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newUpdatePlayslide = /* GraphQL */ `
  subscription NewUpdatePlayslide {
    newUpdatePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newDeletePlayslide = /* GraphQL */ `
  subscription NewDeletePlayslide {
    newDeletePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newCreateAnswerslist = /* GraphQL */ `
  subscription NewCreateAnswerslist {
    newCreateAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newUpdateAnswerslist = /* GraphQL */ `
  subscription NewUpdateAnswerslist {
    newUpdateAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newDeleteAnswerslist = /* GraphQL */ `
  subscription NewDeleteAnswerslist {
    newDeleteAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newCreateHightlightanswers = /* GraphQL */ `
  subscription NewCreateHightlightanswers {
    newCreateHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newUpdateHightlightanswers = /* GraphQL */ `
  subscription NewUpdateHightlightanswers {
    newUpdateHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newDeleteHightlightanswers = /* GraphQL */ `
  subscription NewDeleteHightlightanswers {
    newDeleteHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const newCreateFolders = /* GraphQL */ `
  subscription NewCreateFolders {
    newCreateFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newUpdateFolders = /* GraphQL */ `
  subscription NewUpdateFolders {
    newUpdateFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newDeleteFolders = /* GraphQL */ `
  subscription NewDeleteFolders {
    newDeleteFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newCreateDesktopitems = /* GraphQL */ `
  subscription NewCreateDesktopitems {
    newCreateDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newUpdateDesktopitems = /* GraphQL */ `
  subscription NewUpdateDesktopitems {
    newUpdateDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const newDeleteDesktopitems = /* GraphQL */ `
  subscription NewDeleteDesktopitems {
    newDeleteDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePlayslide = /* GraphQL */ `
  subscription OnCreatePlayslide {
    onCreatePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePlayslide = /* GraphQL */ `
  subscription OnUpdatePlayslide {
    onUpdatePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePlayslide = /* GraphQL */ `
  subscription OnDeletePlayslide {
    onDeletePlayslide {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFolders = /* GraphQL */ `
  subscription OnCreateFolders {
    onCreateFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFolders = /* GraphQL */ `
  subscription OnUpdateFolders {
    onUpdateFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFolders = /* GraphQL */ `
  subscription OnDeleteFolders {
    onDeleteFolders {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDesktopitems = /* GraphQL */ `
  subscription OnCreateDesktopitems {
    onCreateDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDesktopitems = /* GraphQL */ `
  subscription OnUpdateDesktopitems {
    onUpdateDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDesktopitems = /* GraphQL */ `
  subscription OnDeleteDesktopitems {
    onDeleteDesktopitems {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePptxFile = /* GraphQL */ `
  subscription OnCreatePptxFile {
    onCreatePptxFile {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePptxFile = /* GraphQL */ `
  subscription OnUpdatePptxFile {
    onUpdatePptxFile {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePptxFile = /* GraphQL */ `
  subscription OnDeletePptxFile {
    onDeletePptxFile {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGiphyGif = /* GraphQL */ `
  subscription OnCreateGiphyGif {
    onCreateGiphyGif {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGiphyGif = /* GraphQL */ `
  subscription OnUpdateGiphyGif {
    onUpdateGiphyGif {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGiphyGif = /* GraphQL */ `
  subscription OnDeleteGiphyGif {
    onDeleteGiphyGif {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePolllist = /* GraphQL */ `
  subscription OnCreatePolllist {
    onCreatePolllist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePolllist = /* GraphQL */ `
  subscription OnUpdatePolllist {
    onUpdatePolllist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePolllist = /* GraphQL */ `
  subscription OnDeletePolllist {
    onDeletePolllist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePolls = /* GraphQL */ `
  subscription OnCreatePolls {
    onCreatePolls {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePolls = /* GraphQL */ `
  subscription OnUpdatePolls {
    onUpdatePolls {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePolls = /* GraphQL */ `
  subscription OnDeletePolls {
    onDeletePolls {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFasteranswer = /* GraphQL */ `
  subscription OnCreateFasteranswer {
    onCreateFasteranswer {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFasteranswer = /* GraphQL */ `
  subscription OnUpdateFasteranswer {
    onUpdateFasteranswer {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFasteranswer = /* GraphQL */ `
  subscription OnDeleteFasteranswer {
    onDeleteFasteranswer {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOptionslist = /* GraphQL */ `
  subscription OnCreateOptionslist {
    onCreateOptionslist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOptionslist = /* GraphQL */ `
  subscription OnUpdateOptionslist {
    onUpdateOptionslist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOptionslist = /* GraphQL */ `
  subscription OnDeleteOptionslist {
    onDeleteOptionslist {
      id
      pfname
      ptimer
      pquestion
      ptest {
        id
        panswer
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTextslide = /* GraphQL */ `
  subscription OnCreateTextslide {
    onCreateTextslide {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTextslide = /* GraphQL */ `
  subscription OnUpdateTextslide {
    onUpdateTextslide {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTextslide = /* GraphQL */ `
  subscription OnDeleteTextslide {
    onDeleteTextslide {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDraganddrop = /* GraphQL */ `
  subscription OnCreateDraganddrop {
    onCreateDraganddrop {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDraganddrop = /* GraphQL */ `
  subscription OnUpdateDraganddrop {
    onUpdateDraganddrop {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDraganddrop = /* GraphQL */ `
  subscription OnDeleteDraganddrop {
    onDeleteDraganddrop {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFlipcard = /* GraphQL */ `
  subscription OnCreateFlipcard {
    onCreateFlipcard {
      id
      pfname
      ptimer
      fwords {
        type
        text
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlipcard = /* GraphQL */ `
  subscription OnUpdateFlipcard {
    onUpdateFlipcard {
      id
      pfname
      ptimer
      fwords {
        type
        text
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlipcard = /* GraphQL */ `
  subscription OnDeleteFlipcard {
    onDeleteFlipcard {
      id
      pfname
      ptimer
      fwords {
        type
        text
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMultioptionslist = /* GraphQL */ `
  subscription OnCreateMultioptionslist {
    onCreateMultioptionslist {
      id
      mfname
      mtimer
      mquestion
      mtest {
        id
        panswer
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMultioptionslist = /* GraphQL */ `
  subscription OnUpdateMultioptionslist {
    onUpdateMultioptionslist {
      id
      mfname
      mtimer
      mquestion
      mtest {
        id
        panswer
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMultioptionslist = /* GraphQL */ `
  subscription OnDeleteMultioptionslist {
    onDeleteMultioptionslist {
      id
      mfname
      mtimer
      mquestion
      mtest {
        id
        panswer
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideooptionslist = /* GraphQL */ `
  subscription OnCreateVideooptionslist {
    onCreateVideooptionslist {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideooptionslist = /* GraphQL */ `
  subscription OnUpdateVideooptionslist {
    onUpdateVideooptionslist {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideooptionslist = /* GraphQL */ `
  subscription OnDeleteVideooptionslist {
    onDeleteVideooptionslist {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLeaderboard = /* GraphQL */ `
  subscription OnCreateLeaderboard {
    onCreateLeaderboard {
      id
      name
      answerid
      score
      thisgamescore
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLeaderboard = /* GraphQL */ `
  subscription OnUpdateLeaderboard {
    onUpdateLeaderboard {
      id
      name
      answerid
      score
      thisgamescore
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLeaderboard = /* GraphQL */ `
  subscription OnDeleteLeaderboard {
    onDeleteLeaderboard {
      id
      name
      answerid
      score
      thisgamescore
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEngagement = /* GraphQL */ `
  subscription OnCreateEngagement {
    onCreateEngagement {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEngagement = /* GraphQL */ `
  subscription OnUpdateEngagement {
    onUpdateEngagement {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEngagement = /* GraphQL */ `
  subscription OnDeleteEngagement {
    onDeleteEngagement {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnswers = /* GraphQL */ `
  subscription OnCreateAnswers {
    onCreateAnswers {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnswers = /* GraphQL */ `
  subscription OnUpdateAnswers {
    onUpdateAnswers {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnswers = /* GraphQL */ `
  subscription OnDeleteAnswers {
    onDeleteAnswers {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnswerslist = /* GraphQL */ `
  subscription OnCreateAnswerslist {
    onCreateAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnswerslist = /* GraphQL */ `
  subscription OnUpdateAnswerslist {
    onUpdateAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnswerslist = /* GraphQL */ `
  subscription OnDeleteAnswerslist {
    onDeleteAnswerslist {
      id
      name
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHightlightanswers = /* GraphQL */ `
  subscription OnCreateHightlightanswers {
    onCreateHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHightlightanswers = /* GraphQL */ `
  subscription OnUpdateHightlightanswers {
    onUpdateHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHightlightanswers = /* GraphQL */ `
  subscription OnDeleteHightlightanswers {
    onDeleteHightlightanswers {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSequencinggame = /* GraphQL */ `
  subscription OnCreateSequencinggame {
    onCreateSequencinggame {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSequencinggame = /* GraphQL */ `
  subscription OnUpdateSequencinggame {
    onUpdateSequencinggame {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSequencinggame = /* GraphQL */ `
  subscription OnDeleteSequencinggame {
    onDeleteSequencinggame {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAdobeexpress = /* GraphQL */ `
  subscription OnCreateAdobeexpress {
    onCreateAdobeexpress {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAdobeexpress = /* GraphQL */ `
  subscription OnUpdateAdobeexpress {
    onUpdateAdobeexpress {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAdobeexpress = /* GraphQL */ `
  subscription OnDeleteAdobeexpress {
    onDeleteAdobeexpress {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRankingpoll = /* GraphQL */ `
  subscription OnCreateRankingpoll {
    onCreateRankingpoll {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRankingpoll = /* GraphQL */ `
  subscription OnUpdateRankingpoll {
    onUpdateRankingpoll {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRankingpoll = /* GraphQL */ `
  subscription OnDeleteRankingpoll {
    onDeleteRankingpoll {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRankingresultlist = /* GraphQL */ `
  subscription OnCreateRankingresultlist {
    onCreateRankingresultlist {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRankingresultlist = /* GraphQL */ `
  subscription OnUpdateRankingresultlist {
    onUpdateRankingresultlist {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRankingresultlist = /* GraphQL */ `
  subscription OnDeleteRankingresultlist {
    onDeleteRankingresultlist {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
