import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {getMultioptionslist} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/correctanswerpage.css";

function Dragdropcorrctanswer() {
    const [thisqq, setThisqq] = useState("");
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getMultioptionslist,
            variables:{id:rid}
        })
        const datas = response.data.getMultioptionslist;
       const qq = datas.mquestion;
        const tests = datas.mtest;
        const ct = datas.manswer;
        let cts =[];
        let text = "";
        for (let i = 0; i < ct.length; i++) {
            const t = ct[i].checkans;
            cts.push(t);
            text += tests[Number(t)].panswer + " . "
        }
        setAns(JSON.stringify(cts));
        setCans(text);
       setThisqq(qq);
    }

    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <h4>{thisqq}</h4>
                <p>The correct answer is: </p>
                <div className="redthis">
                    {cans}
                </div>
            </div>
            </div>
    );
}

export default Dragdropcorrctanswer;
