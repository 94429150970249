import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import {getPlayslide} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import "../css/room.css";
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import Ending from "../../img/view/exit.svg";
import Content from "../../img/view/content.svg";
import People from "../../img/view/people.svg";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import ReactSwipe from 'react-swipe';

function Mcroom() {
    const [curl, setCurl] = useState("");
    const [vurl, setVurl] = useState("");
    const [isopenthiscontent, setIsopenthiscontent] = useState(true);
    const { id } = useParams();
    const history = useHistory();
    const [showthis, setShowthis] = useState(false);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    })
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension(){
        return {
            height: window.innerHeight
        }
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    let reactSwipeEl;
    useEffect(() => {
        const reachumnname = localStorage.getItem("vreachumnewname");
        if(!reachumnname){
            history.push(`/rform/${id}`);
        }
    }, []);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        async function fetchData() {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id}
                })
                const datas = response.data.getPlayslide;
                setCurl(datas.clink);
                const v = datas.aplaypause;
                if(v === true){
                    const thetimer = document.getElementById('theFrame');
                    thetimer.src =  "/timer";
                }else{
                    setCurl(datas.clink);
                }
                const isplay = datas.vplaypause;
                if(isplay === true){
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
                }else{
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
                }
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            const yourusername = localStorage.getItem("vreachumnewname");
            const cc= `${datas.clientrlink}?embed&minimal&chat=on&screenshare=on&people=off&logo=on&displayName=${yourusername}`;
            setCurl(datas.clink)
            setVurl(cc);
        }catch(error){
            console.log("Cannot display")
        }
    }
    function ends(){
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            localStorage.setItem("vreachumnewname", ssname);
            setShowthis(false)
        }catch(err){
            console.log(err)
        }
    }
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
            <div className="thisfullpage">
                <ReactSwipe
                    className="carousel"
                    swipeOptions={{ continuous: true }}
                    ref={el => (reactSwipeEl = el)}
                >
                <div>
                        {vurl !== "" && (
                                <iframe
                                    className="fullpage"
                                    id="leftsid"
                                    src={vurl}
                                    loading="lazy"
                                    height={screenSize.height}
                                    title="clienturl"
                                    frameBorder="0"
                                    allow="camera; microphone; fullscreen; speaker; display-capture"
                                />
                            )}
                </div>
                    <div>
                            {curl !== "" && (
                                <iframe
                                    className="fullpage"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    height={screenSize.height}
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                </div>
                </ReactSwipe>
            </div>
            {isopenthiscontent ?
                <img src={Content} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(false)}} width="55" className="mbottom" />
                :
                <img src={People} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(true)}} width="55" className="mbottom" />
            }
            <img src={Ending} onClick={ends} className="thismobileexit"/>
            <Modal
                size="lg"
                show={showthis}
                centered
            >
                <Modal.Body>
                    <div className="enteryournameform">
                        <Form className="market-header">
                            <p>Please fill out the fields below to track participation during activities and events. </p>
                            <br/>
                            <h5>Your Full Name</h5>
                            <Form.Item>
                                <Input
                                    type="text"
                                    value={stateData.meetname}
                                    onChange={meetname => setStateData({
                                        ...stateData,
                                        meetname: meetname
                                    })}
                                    maxLength={80}
                                    placeholder="Full Name"
                                    className="craeteroominput"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6} className="rightside">
                                        <Button
                                            disabled={!stateData.meetname}
                                            onClick={handleSubmit2}
                                            size="lg"
                                            className="ibuttns"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            </>
    );
}
export default Mcroom;
