import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateMultioptionslist} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getMultioptionslist, getPlayslide} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/optionshowroom.css";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";

function Multioptionsedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [dname, setDname] = useState('')
	const [polltimer, setPolltimer] = useState(0)
	const [dquestion, setDquestion] = useState('')
	const [items1, setItems1] = useState([{ panswer: '' }])
	const [showtimerform, setShowtimerform] = useState(false)
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getMultioptionslist,
				variables:{id:rid}
			})
			const datas = response.data.getMultioptionslist;
			const dn = datas.mfname
			setDname(dn)
			const tm = datas.mtimer
			if(tm !== 0) {
				setPolltimer(tm)
				setShowtimerform(true)
			}
			const q = datas.mquestion
			setDquestion(q)
			const i1 = datas.mtest
			setItems1(i1)
			const num = datas.manswer;
			for (var i=0; i< num.length; i++) {
				const t = Number(num[i].checkans);
				document.getElementsByName('options')[t].checked=true
			}
		})()
	}, []);

	function changedtimer(event){
		setPolltimer(event.target.value)
	}
	function changedquestion(event){
		setDquestion(event.target.value)
	}
	function addItem1 () {
		setItems1(state => [...state, ''])
	}

	const items1View = items1.map((item, index) => {
		function deleteItem () {
			setItems1(state => {
				return state.filter((item, stateIndex) => index !== stateIndex)
			})
		}
		function changeItem (event) {
			event.persist()
			setItems1(state => {
				return state.map((item, stateIndex) => {
					const match = index === stateIndex
					if (match) {
						const newItem = { ...item, panswer: event.target.value, id: stateIndex }
						return newItem
					}
					return item
				})
			})
		}
		return (
			<li key={index} className="optionslistdistanse">
				<input
					name="options"
					value={`${index}`} // make sure to set up defaultValue
					type="checkbox"
					onChange={()=> {
						console.log(`${index}`)
					}}
				/>
				<input value={item.panswer} onChange={changeItem} placeholder=""  className="optionsullwidth"/>
				<img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
			</li>
		)
	})

	async function submitItems (event) {
		event.preventDefault()
		const u=[];
		const checkbox = document.getElementsByName( "options" );
		for (let i = 0; i < checkbox.length; i++) {
					if ( checkbox[i].checked === true ){
                       u.push({"checkans": i})
					}
			};
		if(u.length !== 0) {
			try {
				const input = {
					id: rid,
					mtimer: polltimer,
					mquestion: dquestion,
					mtest: items1,
					manswer: u
				}
				await API.graphql({
					query: updateMultioptionslist,
					variables: {input}
				});
				setUploadedmessage("Updated!")

			} catch (err) {
				console.log(err)
			}
		}else{
			setUploadedmessage("Please check the correct ones.")
		}
	}

	return (
		isOwner === isUser ?
		<div className="orderlist">
			<form onSubmit={submitItems}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Game name: {dname}</Form.Label>
				</Form.Group>
				<Grid container spacing={0}>
					<Grid item xs={2}>
						Timer : &nbsp;
					</Grid>
					<Grid item xs={10}>
						<Grid container spacing={0}>
							<Grid item xs={7} className="cccenter">
								<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
								{showtimerform ?
									<input
										value={polltimer}
										className="dragdroptimer"
										type="number"
										maxLength="3"
										onChange={(e)=>setPolltimer(e.target.value)}
									/>
									: ""
								}
							</Grid>
							<Grid item xs={5} className="cccenter">
								<input type="radio" name="timer" value="NoTimer" onChange={()=>{
									setShowtimerform(false);
									setPolltimer(0)
								}} /> No Timer
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<br/>
				<Form.Group className="mb-3">
					<Form.Label>Question :</Form.Label>
					<br/>
					<textarea type="text" value={dquestion} onChange={changedquestion} className="thiseditque" />
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Answers :</Form.Label>
					<ol>
						{items1View}
					</ol>
					<div>
						<img src={Add} onClick={addItem1} />
					</div>
				</Form.Group>
				<hr/>
				<br/>
				<Table>
					<tr>
						<td>
						</td>
						<td className="orderright">
							{uploadedmessage} <input type="submit" value="Update" id="summitb" />
						</td>
					</tr>
				</Table>
			</form>
		</div>
			:""
	);
}
export default Multioptionsedit;
