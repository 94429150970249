import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {getMultioptionslist, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import { useForm } from 'react-hook-form';
import "../../css/optionshowroom.css";
import "../../css/multioptions.css";
import {createLeaderboard, updateLeaderboard,createEngagement} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

function Multioptionshowroom() {
    const { register, handleSubmit, errors } = useForm();
    const [isShow, setIsShow] = useState(true);
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const [thissaved, setThissaved] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [showclock, setShowclock] = useState(false);
    useEffect( () => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
            }
        }
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getMultioptionslist,
                variables: {id:rid}
            })
            const datas = response.data.getMultioptionslist;
            const timers = datas.mtimer;
            if(timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            }else{
                setTimeval(40)
            }
            const ques = datas.mquestion;
            setQuest(ques);
            const tests = datas.mtest;
            setClist(tests);
            const ct = datas.manswer;
            let cts =[];
            let text = "";
            for (let i = 0; i < ct.length; i++) {
                const t = ct[i].checkans;
                cts.push(t);
                text += tests[Number(t)].panswer + " . "
            }
            setAns(JSON.stringify(cts));
            setCans(text)

        }catch(err){
            console.log(err)
        }
    }
    const onSubmit = async (data) => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
        let mymeetnames = localStorage.getItem("vreachumnewname");
        const thisdata = JSON.stringify(data.panswer);
            if (thisdata === ans) {
                try {
                    API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "name": {
                                    eq: mymeetnames
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit:10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playsl = plays.length;
                        if (playsl > 0) {
                            const myscore = plays[0].score;
                            const myid = plays[0].id;
                            const myscores = timeval + myscore;
                            try {
                                const input = {
                                    id: myid,
                                    score: myscores,
                                    thisgamescore:timeval
                                }
                                API.graphql({
                                    query: updateLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        } else {
                            try {
                                const input = {
                                    answerid: id,
                                    name: mymeetnames,
                                    score: timeval,
                                    thisgamescore:timeval
                                }
                                API.graphql({
                                    query: createLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        setMessages(`You answered correctly and scored ${timeval} points.`)
                    })
                } catch (err) {
                    console.log(err)
                }
            } else {
                try {
                    API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "name": {
                                    eq: mymeetnames
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit:10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playsl = plays.length;
                        if (playsl === 0) {
                            try {
                                const input = {
                                    answerid: id,
                                    name: mymeetnames,
                                    score: 0,
                                    thisgamescore:0
                                }
                                API.graphql({
                                    query: createLeaderboard,
                                    variables: {input}
                                });

                            } catch (err) {
                                console.log(err)
                            }
                        }else{
                            const myid = plays[0].id;
                            try {
                                const input = {
                                    id: myid,
                                    thisgamescore:0
                                }
                                API.graphql({
                                    query: updateLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
                setMessages("You scored 0 points.")
            }
        setIsShows(false);
        setIsShow(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    };
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewname");
        if(mymeetnams) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "name": {
                                eq: mymeetnams
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                score: 0,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setMessages("You scored 0 points.")
            setIsShows(false);
            setIsShow(false);
            try {
                const input = {
                    questionid: id,
                    engage: 0,
                    people: 1
                }
                API.graphql({
                    query: createEngagement,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
        }else{
            setMessages("Time is up.")
            setIsShows(false);
            setIsShow(false);
        }
    }
    return (
        <>
            <div className="drawoptionpage2">
                        <div className="greenback">
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    {showclock ?
                                        <div className="lpollheadclock2">
                                            <CountdownCircleTimer
                                                isPlaying={playpause}
                                                duration={counter}
                                                size={50}
                                                colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                                colorsTime={[7, 5, 2, 0]}
                                                onComplete={getdatas}
                                            >
                                                {renderTime}
                                            </CountdownCircleTimer>
                                        </div>
                                        : ""
                                    }
                                </Grid>
                                <Grid item xs={10}>
                                    <div className="fastanswes">
                                        <h4>{quest}</h4>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                        <div className="drawoptioninsidepage">
                        {isShows ?
                            <>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {
                                            clist.map((item, index) => {
                                                    return (
                                                        <>
                                                        <label key={index} className="muticontainer">
                                                            <input
                                                                type="checkbox"
                                                                value={item.id}
                                                                name="panswer"
                                                                ref={register({ required: 'Please select' })}
                                                            />{''}
                                                          {item.panswer}
                                                            <span className="multicheckmark"></span>
                                                        </label>
                                                            </>
                                                    )
                                                }
                                            )
                                        }

                                    <br/>
                                    <Grid container spacing={0} className="pollbottoms">
                                        <Grid item xs={7}>
                                        </Grid>
                                        <Grid item xs={5} className="centerside">
                                            <input type="submit" className="inputpolllist" disabled={isDisabled} />
                                        </Grid>
                                    </Grid>
                                </form>
                                </div>
                            </> :
                            <>
                                <div className="fastanswers">
                                    <br/>
                                    <br/>
                                <h4 className="pollmaeeage">{messages}</h4>
                                    <br/>
                                    <br/>
                                </div>
                            </>
                        }
                        </div>
            </div>
            </>
            )
}

export default Multioptionshowroom;
