import React, {useEffect,useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Ratio} from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

function Vimeoshowroom() {
    const [lvfile, setLvfile] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const datasn = datas.playlistlinks;
            const newdatas = datasn.filter(data => data.rid === rid);
            const vbucket = newdatas[0].lurl;
            setLvfile(vbucket);
        }catch(error){
            console.log("can't display")
        }
    }
    return (
        <>
                <div className="vidshows">
                <Ratio aspectRatio="16x9">
                        <div className='player-wrapper'>
                            <ReactPlayer
                                id="reactplayer"
                                url={lvfile}
                                playsinline
                                width='100%'
                                height='100%'
                                controls
                            />
                        </div>
                        </Ratio>
                </div>
        </>
    );
}

export default Vimeoshowroom;

