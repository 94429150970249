import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import QRCode from 'qrcode'
import "./css/qrshow.css";
import {Helmet} from "react-helmet";

var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    margin: 10,
    width:400
}

function Qrcodepage() {
    const [jvfile, setVjfile] = useState();
    const { id } = useParams();
    QRCode.toDataURL(`https://exeltis.reachum.com/iview/${id}`, opts)
        .then(url => {
            setVjfile(url)
        })
        .catch(err => {
            console.error(err)
        })
    return (
        <>
            <Helmet>
                <title>QR code</title>
            </Helmet>
            <div className="qrshowsroom">
                <Image src={jvfile} fluid className="imgviewsite"/>
            </div>
        </>
    );
}

export default Qrcodepage;
