import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateFlipcard} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getFlipcard, getPlayslide} from "../../../graphql/queries";
import {useParams,useHistory} from "react-router-dom";
import "../../css/poll.css";
import Grid from "@material-ui/core/Grid";

function Flipcardsedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [fname, setFname] = useState('')
	const [ftimer, setFtimer] = useState(0)
	const [showtimerform, setShowtimerform] = useState(false)
	const [type1, setType1] = useState('')
	const [type2, setType2] = useState('')
	const [type3, setType3] = useState('')
	const [type4, setType4] = useState('')
	const [text1, setText1] = useState('')
	const [text2, setText2] = useState('')
	const [text3, setText3] = useState('')
	const [text4, setText4] = useState('')
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getFlipcard,
				variables:{id:rid}
			})
			const datas = response.data.getFlipcard;
            const fnames = datas.pfname;
			setFname(fnames)
			const ftimers = datas.ptimer;
			if(ftimers !== 0) {
				setFtimer(ftimers)
				setShowtimerform(true)
			}
			const fwordss = datas.fwords;
			setText1(fwordss[0].text)
			setType1(fwordss[0].type)
			setText2(fwordss[1].text)
			setType2(fwordss[1].type)
			setText3(fwordss[2].text)
			setType3(fwordss[2].type)
			setText4(fwordss[3].text)
			setType4(fwordss[3].type)
		})()
	}, [id]);


	async function handleSubmit (event){
		event.preventDefault()
		const card1 = { type: type1, text: text1 }
		const card2 = { type: type2, text: text2 }
		const card3 = { type: type3, text: text3 }
		const card4 = { type: type4, text: text4 }
		const newCards = [card1, card2, card3, card4]
		try {
			const input = {
				id:rid,
				ptimer:ftimer,
				fwords:newCards
			}
			await API.graphql({
				query: updateFlipcard,
				variables: {input}
			});
				setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}

	function changeType1 (event) {
		setType1(event.target.value)
	}

	function changeText1 (event) {
		setText1(event.target.value)
	}

	function changeType2 (event) {
		setType2(event.target.value)
	}

	function changeText2 (event) {
		setText2(event.target.value)
	}

	function changeType3 (event) {
		setType3(event.target.value)
	}

	function changeText3 (event) {
		setText3(event.target.value)
	}

	function changeType4 (event) {
		setType4(event.target.value)
	}

	function changeText4 (event) {
		setText4(event.target.value)
	}


	return (
		isOwner === isUser ?
			<>
						<form onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Game name: {fname}</Form.Label>
							</Form.Group>
							<Grid container spacing={0}>
								<Grid item xs={2}>
									Timer : &nbsp;
								</Grid>
								<Grid item xs={10}>
									<Grid container spacing={0}>
										<Grid item xs={7} className="cccenter">
											<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
											{showtimerform ?
												<input
													value={ftimer}
													className="dragdroptimer"
													type="number"
													maxLength="3"
													onChange={(e)=>setFtimer(e.target.value)}
												/>
												: ""
											}
										</Grid>
										<Grid item xs={5} className="cccenter">
											<input type="radio" name="timer" value="NoTimer" onChange={()=>{
												setShowtimerform(false);
												setFtimer(0)
											}} /> No Timer
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<br/>
							<p>Update a word/text</p>
							<Form.Group className="mb-3" controlId="word1">
									<ol>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type1} type="text" onChange={changeType1} maxLength="175" className="finput"/>
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text1} type="text" onChange={changeText1} maxLength="175" className="finput"/>
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
											<Grid item xs={5}>
											<input value={type2} type="text" onChange={changeType2} maxLength="175" className="finput" />
											</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text2} type="text" onChange={changeText2} maxLength="175" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type3} type="text" onChange={changeType3} maxLength="175" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text3} type="text" onChange={changeText3} maxLength="175" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type4} type="text" onChange={changeType4} maxLength="175" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text4} type="text" onChange={changeText4} maxLength="175" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
									</ol>
							</Form.Group>
							<Table>
								<tr>
									<td>
									</td>
									<td className="orderright">
										{uploadedmessage} <input type="submit" value="Update" className="answersubmit"/>
									</td>
								</tr>
							</Table>
					</form>
			</>
			:""

	);
}
export default Flipcardsedit;
