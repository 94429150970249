import {Auth, API, Hub} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {listPlayslides, listFolders, listDesktopitems} from "../../graphql/queries";
import {
    newUpdatePlayslide,
    newDeletePlayslide,
    newUpdateDesktopitems,
    newUpdateFolders
} from "../../graphql/subscriptions";
import {
    createFolders,
    deletePlayslide,
    updatePlayslide,
    createDesktopitems,
    updateFolders,
    updateDesktopitems,
    createPlayslide
} from "../../graphql/mutations";
import "../css/dashboard.css";
import Login from "../login";
import {Button, Modal} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {v4 as uuid} from "uuid";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    useDroppable,
    pointerWithin
} from '@dnd-kit/core';
import Grid from "@material-ui/core/Grid";
import { SortableItem } from './SortableItem';
import Folder from "./Folder";
import DesktopView from "./desktop";
import {Form, Input} from "element-react";
import moment from "moment-timezone";
import {Helmet} from "react-helmet";
import Navbars from "../../components/Navbar";
import Foot from "../../components/Foot";
import Foott from "../../components/Foott";
import {useHistory} from "react-router-dom";
import Inviteform from "../invitepeople/inviteform";
import Invitecoform from "../invitepeople/invitecoform";
import Shareroom from "../shareroom";
import ListGroup from "react-bootstrap/ListGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Inviteform1 from "../invitepeople/inviteform1";
import Inviteondemandform from "../invitepeople/inviteondemandform";
import Layoute from "../../img/host/explain/layout.png";
import Slidelist from "../../img/host/explain/slidelist.png";
import Timer from "../../img/host/explain/timer.png";
import Plays from "../../img/host/explain/play.png";
import Backs from "../../img/host/explain/left.png";
import Nexts from "../../img/host/explain/next.png";
import Syns from "../../img/host/explain/syn.png";
import Newinfo from "../../img/info.png";
import Leftarrow from "../../img/left-arrow.png";
import Cams from "../../img/host/explain/cam.png";
import Mics from "../../img/host/explain/mic.png";
import Shares from "../../img/host/explain/share.png";
import Records from "../../img/host/explain/record.png";
import Chats from "../../img/host/explain/chat.png";
import Peoples from "../../img/host/explain/mpeoplepng.png";
import Leaves from "../../img/host/explain/leave.png";
import Maxs from "../../img/host/explain/max.png";
import Replays from "../../img/host/explain/replay.png";
import Qrcodes from "../../img/host/explain/qrcode.png";
function Dashboard() {
    const [myData, setMyData] = useState([])
    const [myroomData, setMyroomData] = useState([])
    const [user, setUser] = useState();
    const [username, setUsername] = useState("");
    const [newFolderName, setNewFolderName] = useState('')
    const [folders, setFolders] = useState([]);
    const [desktopitems, setDesktopitems] = useState([]);
    const [copied, setCopied] = useState("");
    const [icopied, setCiopied] = useState("");
    const [ccopied, setCcopied] = useState("");
    const [cionopied, setCionopied] = useState("");
    const [isFixed, setIsFixed] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [savebuttonshow, setSavebuttonshow] = useState(false)
    const [draggingIds, setDraggingIds] = useState([]);
    const [changesavedmessage, setChangesavedmessage] = useState("");
    const [mymeeing, setMymeeting] = useState();
    const [extime, setExtime] = useState();
    const [thisname,setThisname] = useState("");
    const [thisid,setThisid] = useState("");
    const [thismid,setThismid] = useState("");
    const [show7, setShow7] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {setShow2(false);setShow(true)};
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {setShow3(false);setShow(true)};
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => {setShow4(false);setShow(true)};
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mname, setMname] = useState("");
    const [myplan, setMyplan] = useState("");
    const [openthis, setOpenthis] = useState(true);
    const [showprocess, setShowprocess] = useState("");
    const [openthisitems, setOpenthisitems] = useState(false);
    const [openinfomation, setOpeninfomation] = useState(true);
    const [openroles, setOpenroles] = useState(false);
    const [opencalenders, setOpencalenders] = useState(false);
    const [openipinfomation, setOpenipinfomation] = useState(true);
    const [openipcalenders, setOpenipcalenders] = useState(false);
    const [openssinfomation, setOpenssinfomation] = useState(true);
    const [opensscalenders, setOpensscalenders] = useState(false);
    const [openssexplainer, setOpenssexplainer] = useState(false);
    const [openGuest, setOpenGuest] = useState(true);
    const [opencohost, setOpencohost] = useState(false);
    const [openexplainer, setOpenexplainer] =useState(false);
    const [openipexplainer, setOpenipexplainer] =useState(false);
    const [selectedOption, setSelectedOption] = useState('Guest');
    const [stateData, setStateData] = useState({
        meetingname:"",
        fields: ["hostRoomUrl"]
    });
    let history = useHistory();
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5
            }
        }),
    );
    const [selectedIds, setSelectedIds] = useState([])
    const d = uuid();
    useEffect(() => {
        getUserData();
        Hub.listen('auth', (data) => {
            const { payload } = data;
            onAuthEvent(payload);
        });
        fetchfolder();
        fetchdesktofiles();
        fetchData();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        });
        const updateFolders = API.graphql(
            {
                query:newUpdateFolders
            }
        ).subscribe({
            next: () => {
                fetchfolder();
            }
        })
        const updateDesktopitems = API.graphql(
            {
                query:newUpdateDesktopitems
            }
        ).subscribe({
            next: () => {
                fetchdesktofiles();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe();
            updateDesktopitems.unsubscribe();
            updateFolders.unsubscribe()
        }
    }, []);
    const  getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        if(user){
            setUsername(user.attributes.email);
            setUser(user);
        }else{
            setUser(null)
        }
        const usernames = user.attributes.email;
        window.$productFruits.push(['init', 'COTdf6SRBMA6khBF', 'en', { username: usernames, email:usernames}]);
    }
        function onAuthEvent(payload)  {
        switch (payload.event) {
            case "signIn":
                getUserData();
                fetchfolder();
                fetchdesktofiles();
                fetchData();
                break;
            case "signUp":
                break;
            default:
                return
        }
    }
    async function fetchData() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit: 10000
                }
            });
        const datas = response.data.listPlayslides.items;
        if(datas.length !== 0 ) {
            const transformedData = datas.map(item => ({
                id: item.id,
                meetingname: item.meetingname,
                mid: item.mid
            }));
            setMyData(transformedData);
        }
    }

    async function fetchfolder() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
            const response = await API.graphql({
                query: listFolders,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listFolders.items;
            if (datas.length !== 0) {
                const thisfolder = JSON.parse(datas[0].folderlists);
                setFolders(thisfolder)
            }
    }
    async function fetchdesktofiles() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
           const response = await API.graphql({
               query: listDesktopitems,
               variables: {
                   filter: {
                       "owner": {
                           eq: thisuser
                       }
                   },
                   limit: 10000
               }
           })
           const datas = response.data.listDesktopitems.items;
           if (datas.length !== 0) {
               if (datas[0].deskitems.length !== 0) {
                   const thisfolder = JSON.parse(datas[0].deskitems);
                   setDesktopitems(thisfolder);
                  setMyData(current => {
                       const newItems = thisfolder.filter(thisItem => {
                           return !current.some(currentItem => currentItem.id === thisItem.id)
                       })
                       return [...current, ...newItems]
                   })
               }
           } else {
               const response = await API.graphql({
                   query: listPlayslides,
                   variables: {
                       filter: {
                           "owner": {
                               eq: thisuser
                           }
                       },
                       limit: 10000
                   }
               });
               const datas = response.data.listPlayslides.items;
               const transformedData = datas.map(item => ({
                   id: item.id,
                   meetingname: item.meetingname,
                   mid: item.mid
               }));
               const input = {
                   deskitems: JSON.stringify(transformedData),
                   owner: thisuser
               }
               await API.graphql({
                   query: createDesktopitems,
                   variables: {input}
               });
               setDesktopitems(transformedData);
           }
       }

    const handleDelete = async (itemId) => {
        setSavebuttonshow(true);
        let text = "Are you sure you want to delete this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deletePlayslide,
                variables: {input}
            });
            setOpenthisitems(false)
            const response = await API.graphql({
                query: listFolders,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listFolders.items;
            if(datas.length !== 0) {
                const thisfolderid = datas[0].id;
                const thisfolder = JSON.parse(datas[0].folderlists);
                const updatedData = { ...thisfolder };
                for (const key in updatedData) {
                    updatedData[key].items = updatedData[key].items.filter(item => item.id !== itemId);
                }
                setFolders(current => {
                    const currentEntries = Object.entries(current)
                    const newEntries = currentEntries.map(entry => {
                        const folder = entry[1]
                        const newItems = folder.items.filter(item => item.id !== itemId)
                        const newFolder = { ...folder, items: newItems }
                        return [entry[0], newFolder]
                    })
                    return Object.fromEntries(newEntries)
                })
                const input ={
                    id:thisfolderid,
                    folderlists: JSON.stringify(updatedData)
                }
                await API.graphql({
                    query: updateFolders,
                    variables: {input}
                });
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const thisitemid = datas1[0].id;
                const thisfolderitem = JSON.parse(datas1[0].deskitems);
                const updatedData1 = thisfolderitem.filter(item => item.id !== itemId);
                setDesktopitems(updatedData1)
                const input1 ={
                    id:thisitemid,
                    deskitems: JSON.stringify(updatedData1)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input:input1}
                });
            }
            setDraggingIds(current => current.filter(draggingId => draggingId !== itemId))
            setSelectedIds(current => current.filter(selectedId => selectedId !== itemId))
        }
    };
    const goCreate = () => {
        setShow7(true);
    }
    function sharelist() {
        setShow5(true)
    }
    function selectId(id) {
        setSelectedIds(current => {
            const newIds = [id, ...current]
            const newItems = myData.filter(item => {
                return newIds.includes(item.id)
            })
            return newItems.map(item => item.id)
        })
    }
    function toggleSelect(id) {
        setSelectedIds(current => {
            const selected = current.includes(id)
            if (selected) {
                return current.filter(currentId => currentId !== id)
            } else {
                const newIds = [id, ...current]
                const newItems = myData.filter(item => {
                    return newIds.includes(item.id)
                })
                return newItems.map(item => item.id)
            }
        })
    }
    function onIdClick(id,mname,mid){
        setThisid(id);
        setThisname(mname);
        setThismid(mid);
        setOpenthisitems(true)
    }
    const desktopViews = desktopitems.map(item => {
        const selected = selectedIds.includes(item.id);
        return (
            <SortableItem
                key={item.id}
                item={item}
                selected={selected}
                toggleSelect={toggleSelect}
                handleDelete={handleDelete}
                onIdClick={onIdClick}
            />
        )
    })
    const selectedItems = myData.filter(item => {

        return selectedIds.includes(item.id)
    })
    const selectedClassName = selectedItems ? 'selected' : ''
    const className = `divcss ${selectedClassName}`
    const draggingItems = dragging && selectedItems.map((item) => {
        return (
            <div className={className} key={item.id}>
                <div className="cccenter">
                        <span> {item.meetingname}</span>
                </div>
            </div>
        )
    })
    function handleNewFolderNameChange(event) {
        setNewFolderName(event.target.value)
    }
    function handleCreateFolderClick() {
        if (newFolderName === '') {
            return
        }
        setFolders(current => {
            const id = uuid()
            return {
                ...current,
                [id]: {
                    id,
                    name: newFolderName,
                    items: []
                }
            }
        })
        setTimeout(() => {
            setNewFolderName("")
        }, 500);
        setSavebuttonshow(true)
    }
    const folderValues = Object.values(folders)
    const topFolders = folderValues.filter(folder => folder.parentId == null)
    const folderViews = topFolders.map(folder => {
        function changeFolderName(newName) {
            setFolders(current => {
                const currentFolder = current[folder.id]
                return {
                    ...current,
                    [folder.id]: {
                        ...currentFolder,
                        name: newName
                    }
                }
            });
            setSavebuttonshow(true)
        }
        function deleteFolder(folderId) {
            let text = `Are you sure you want to delete this folder? \nEither OK or Cancel.`;
            if (window.confirm(text) === true) {
                setFolders(current => {
                    const currentEntries = Object.entries(current)
                    const newEntries = currentEntries.filter(entry => {
                        if (entry[1].id === folderId) {
                            const itr = entry[1].items;
                            const itemslength = itr.length;
                            for (let i = 0; i < itemslength; i++) {
                                if (itr[i].id) {
                                    const input = {
                                        id: itr[i].id
                                    };
                                    API.graphql({
                                        query: deletePlayslide,
                                        variables: {input}
                                    });
                                }}
                            return false
                        }
                        if (entry[1].parentId === folderId) {
                            const itr = entry[1].items;
                            const itemslength = itr.length;
                            for (let i = 0; i < itemslength; i++) {
                                if (itr[i].id) {
                                    const input = {
                                        id: itr[i].id
                                    };
                                    API.graphql({
                                        query: deletePlayslide,
                                        variables: {input}
                                    });
                                }}
                            return false
                        }
                        return true
                    })
                    const newFolders = Object.fromEntries(newEntries)
                    return newFolders
                })
            }
            setSavebuttonshow(true)
        }
        function createSubfolder (name) {
            setFolders(current => {
                const id = uuid()
                const newFolder = {
                    id,
                    name,
                    items: [],
                    parentId: folder.id
                }
                const newFolders = {...current, [id]: newFolder}
                return newFolders
            })
        }
        return (
            <Folder
                key={folder.id}
                folder={folder}
                changeFolderName={changeFolderName}
                deleteFolder={deleteFolder}
                toggleSelect={toggleSelect}
                selectedIds={selectedIds}
                createSubfolder={createSubfolder}
                folders={folders}
                subfolder={false}
                handleDelete={handleDelete}
                onIdClick={onIdClick}
            />
        )
    })
    function handleDragStart({ active }) {
        setSavebuttonshow(true);
        selectId(active.id)
        setDragging(true)
        const newSelectedIds = [...selectedIds, active.id]
        const selectedItems = myData.filter(item => {
            return newSelectedIds.includes(item.id)
        })
        const draggingIds = selectedItems.map(item => {
            return item.id
        })
        setDraggingIds(draggingIds)
    }
    function handleDragOver(event) {
        const { active, over } = event;
        if (over == null) {
            return
        }
        const overFolderItemFolder = folderValues.find(folder => {
            return folder.items.some(item => item.id === over.id)
        })
        const overFolder = folders[over.id]
        const folderOver = overFolderItemFolder ?? overFolder
        const overDesktopArea = over.id === 'desktop'
        const overDesktopItem = desktopitems.find(item => item.id === over.id)
        const overDesktop = overDesktopArea || overDesktopItem;
        const selectedItems = draggingIds.map(id => {
            const item = myData.find(item => item.id === id)
            return item
        })
        const overSelected = selectedIds.includes(over.id)
        let foldersClone = JSON.parse(JSON.stringify(folders))
        let desktopClone = JSON.parse(JSON.stringify(desktopitems))
        if (overSelected) {
            if (overDesktop) {
                const withoutSelectedExceptOver = desktopitems.filter(item => {
                    const selected = selectedIds.includes(item.id)
                    if (!selected) {
                        return true
                    }
                    const overItem = item.id === over.id
                    if (overItem) {
                        return true
                    }
                    return false
                })
                const newOverIndex = withoutSelectedExceptOver.findIndex(item => item.id === over.id)
                const beforeNewOver = withoutSelectedExceptOver.slice(0, newOverIndex)
                const afterNewOver = withoutSelectedExceptOver.slice(newOverIndex + 1)
                const newDesktop = [...beforeNewOver, ...selectedItems, ...afterNewOver]
                setDesktopitems(newDesktop)
                return
            }
        }
        if (folderOver != null) {
            const newFolderValues = folderValues.map(folder => {
                if (folder.id === folderOver.id) {
                    const notInFolderItems = selectedItems.filter(item => {
                        return folder.items.every(folderItem => folderItem.id !== item.id)
                    })
                    const newItems = [...folder.items, ...notInFolderItems]
                    const newFolder = {
                        ...folder,
                        items: newItems
                    }
                    return newFolder
                }
                const newItems = folder.items.filter(item => !selectedIds.includes(item.id))
                const newFolder = { ...folder, items: newItems }
                return newFolder
            })
            newFolderValues.forEach(folder => {
                foldersClone[folder.id] = folder
            })
            desktopClone = desktopClone.filter(item => !selectedIds.includes(item.id))
        } else if (overDesktop) {
            const activeIndex = desktopitems.findIndex(item => item.id === active.id)
            const overIndex = desktopitems.findIndex(item => item.id === over.id)
            const forward = overIndex > activeIndex
            const newWithout = desktopitems.filter(item => {
                return !selectedIds.includes(item.id)
            })
            const newOverIndex = newWithout.findIndex(item => item.id === over.id)
            const overFirst = newOverIndex === 0
            if (overFirst && !forward) {
                return [...selectedItems, ...newWithout]
            }
            const offset = forward ? 1 : 0
            const beforeNew = newWithout.slice(0, newOverIndex + offset)
            const afterNew = newWithout.slice(newOverIndex + offset)
            desktopClone = [...beforeNew, ...selectedItems, ...afterNew]
            const newFolderValues = folderValues.map(folder => {
                const newItems = folder.items.filter(item => !selectedIds.includes(item.id))
                return { ...folder, items: newItems }
            })
            newFolderValues.forEach(folder => {
                foldersClone[folder.id] = folder
            })
        }
        setFolders(foldersClone)
        setDesktopitems(desktopClone)
    }
    function handleDragEnd(event) {
        setSelectedIds([])
        setDragging(false)
    }
    const handleOptionChange = (event) => {
        const cc = event.target.value;
        if(cc === "coHost"){
            setOpenGuest(false);
            setOpencohost(true);
            setSelectedOption("coHost")
        }else{
            setOpenGuest(true);
            setOpencohost(false);
            setSelectedOption("Guest")
        }
    };
    function openqrcodeage(){
        window.open(`https://exeltis.reachum.com/ssqrcodepage/${thisid}`, "Reachum Self-Study QR code", "popup" )
    }
    function openipqrcodeage(){
        window.open(`https://exeltis.reachum.com/ipqrcodepage/${thisid}`, "Reachum in-Person QR code", "popup" )
    }
    const renderDatalinks = () => {
        return (
            <ListGroup.Item >
                <Tabs
                    defaultActiveKey="full"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                        <Tab eventKey="full" title="Remote">
                            <br/>
                            {openinfomation ?
                                <>
                                    <Grid container spacing={0} className="topspace1">
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                        <Grid item xs={5} className="cccenter">
                                            <button onClick={() => {
                                                window.open(`/host/${thisid}/${thismid}`, "_blank");
                                            }} className="newstartbuttoncolor">
                                                Start Meeting
                                            </button>
                                        </Grid>
                                        <Grid item xs={5} className="cccenter">
                                            <button className="newcalendarbuttoncolor" onClick={()=> {
                                                setOpeninfomation(false);
                                                setOpencalenders(true);
                                                setOpenroles(false);
                                                setOpenexplainer(false)
                                            }}>Calendar Invite</button>
                                        </Grid>
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <br/>
                                    <div className="cccenter">
                                        <p className="newunderline" onClick={()=>{
                                            setOpenexplainer(true);
                                            setOpeninfomation(false);
                                            setOpencalenders(false);
                                            setOpenroles(false);
                                        }}> View the Host Control Guide.</p>
                                    </div>
                                    <br/>
                                    <div className="cccenter">
                                        <p> Click any of the buttons below to copy a remote class link. <img src={Newinfo} alt="info" onClick={()=> {
                                            setOpeninfomation(false);
                                            setOpenroles(true);
                                            setOpencalenders(false);
                                            setOpenexplainer(false)
                                        }}/></p>
                                    </div>
                                    <br/>
                                    <Grid container spacing={0} className="topspace1">
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5} className="cccenter">
                                            <CopyToClipboard text={`https://exeltis.reachum.com/view/${thisid}`} onCopy={() => {
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 3000);
                                            }}>
                                                <button className="newclasslinksbutton"> Guest Link </button>
                                            </CopyToClipboard>
                                            {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={5} className="cccenter">
                                            <CopyToClipboard text={`https://exeltis.reachum.com/cohost/${thisid}/${thismid}`}
                                                             onCopy={() => {
                                                                 setCcopied(true);
                                                                 setTimeout(() => {
                                                                     setCcopied(false);
                                                                 }, 3000);
                                                             }}>
                                                <button className="newclasslinksbutton"> CoHost Link </button>
                                            </CopyToClipboard>
                                            {ccopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                    </Grid>
                                </>:
                                <div>
                                    <p className="sharedropbtn" onClick={()=> setOpeninfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> Remote Meeting Links </span> </p>
                                    {openroles ?
                                        <div>
                                            <div className="cccenter">
                                                <h5>What are REACHUM roles?</h5>
                                                <br/>
                                                <p>REACHUM has 3 roles: host, cohost and guest.<br/>
                                                    Hover over the text below to see what each one does.</p>
                                            </div>
                                            <br/>
                                            <Grid container spacing={0} className="topspace1">
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4} className="cccenter">
                                                    <div className="upgradenew20">
                                                        <p>Guest</p>
                                                        <span className="tooltiptext20">Guest have permissions to screenshare without requesting permission each time.</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} className="cccenter">
                                                    <div className="upgradenew20">
                                                        <p>CoHost</p>
                                                        <span className="tooltiptext20">Cohosts have the same permissions you do as the meeting host, minus the ability to lock the room. Cohosts can go backwards or forwards in a presentation and silence or delete other users.</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        : ""
                                    }
                                    {openexplainer ?
                                        <div>
                                            <div className="cccenter">
                                                <h5>Host Control Guide</h5>
                                                <br/>
                                                <p>Confused on how to use REACHUM Remote? Don’t worry! We got your back.<br/>
                                                    Hover over an icon below to see what it does.</p>
                                            </div>
                                            <br/>
                                            <div className="cccenter">
                                                <div className="upgradenew2">
                                                    <img src={Layoute} width="50px" alt="layout" className="leftmargin"/>
                                                    <span className="tooltiptext2"> Layout button: <br/>  Change the layout of your screen from a video call with the activity window to just a video call. You can also spotlight or remove a spotlight from individual participants by hovering over the upper right hand corner of their window.</span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Slidelist} width="50px" alt="slide list" className="leftmargin"/>
                                                    <span className="tooltiptext2">Content List button: <br/>
Skip forward or backward in your lesson with a single click. Open your list and click on the desired slide. Then, when you’re done reviewing, click the “next” button to return to the slide after your previous one.
</span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Timer} width="50px" alt="timer button" className="leftmargin"/>
                                                    <span className="tooltiptext2">Timer button: <br/>
Instantly start 1 minute timer at any point in your lesson. The timer button is a great classroom management tool that comes in handy for keeping group discussions, writing prompts, and other class activities on track. Click the “next” button to return to the slide after your previous one.
</span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Plays} width="50px" alt="play button" className="leftmargin"/>
                                                    <span className="tooltiptext2">Play button: <br/>
Begin a self-embedded video. (Note: Does not work on video links such as linked YouTube videos. To view a YouTube video, click the YouTube play icon in the center of the screen.)
</span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Syns} width="50px" alt="Sync button" className="leftmargin"/>
                                                    <span className="tooltiptext2">Sync button: <br/>
If you manually move forward or backward in a self-embedded video while the video is paused, you’ll see the sync button pop up on your meeting controls. Click the sync button to sync the video playback across all guest devices to your own.
</span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                    <span className="tooltiptext2">Back button: <br/> Move to the previous slide in your REACHUM presentation. </span>
                                                </div>
                                                <div className="upgradenew2">
                                                    <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                    <span className="tooltiptext2">Next button: <br/>
Move to the next slide in your REACHUM presentation.
 </span>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="cccenter">
                                                <div className="upgradenew20">
                                                    <img src={Cams} width="50px" alt="cam" className="leftmargin"/>
                                                    <span className="tooltiptext20">Cam icon:<br/>
                                                    <ul>
<li>Select a camera other than your computer’s default camera if applicable.</li>
                                                        <li>Background effects: Lightly or strongly blur your background, or replace your background with an image. By default, REACHUM has 6 different background choices, but you can also upload a background image of your own.</li>
    <li>Video settings: Change your default camera, microphone, or speaker, and refine your sound and video settings to your liking. Your video settings give you the option to reduce background noise, prefer HD and/or widescreen video, and mirror your video.</li>
    <li>You can also turn your cam on/off from the Cam icon.</li>
                                                        </ul>
</span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Mics} width="50px" alt="mic" className="leftmargin"/>
                                                    <span className="tooltiptext20">Mic icon: <br/>
                                                    <li>Choose your default audio input/output and turn on background noise reduction.</li>
                                                        <li>Audio settings: Change your default camera, microphone, or speaker, and refine your sound and video settings to your liking. Just like your video settings, your audio settings give you the option to reduce background noise, prefer HD and/or widescreen video, and mirror your video.</li>
                                                    <li>You can also turn your cam on/off from the Mic icon.</li>
                                                    </span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Shares} width="50px" alt="share" className="leftmargin"/>
                                                    <span className="tooltiptext20">Share icon: <br/>
                                                    <li>Miro whiteboard: Create a miro whiteboard to engage in live collaboration activities such as brainstorming and mind mapping. Instructors can create up to 3 active whiteboards with a free account.</li>
<li>YouTube: Pull up a YouTube video at any time during your lecture by copying and pasting the URL. Click the ellipses in the upper left hand corner and select “Full Screen” to make the video full screen for your class.</li>
                                                   <li>Share screen: Share a tab, window, or your entire screen with your class.</li>
                                                    </span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Records} width="50px" alt="Record" className="leftmargin"/>
                                                    <span className="tooltiptext20">Record icon: <br/>
Start a recording of your meeting and export as a video file to share with your class.
</span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Chats} width="50px" alt="chat" className="leftmargin"/>
                                                    <span className="tooltiptext20">Chat icon: <br/> Start a text chat with your class. Your participants can also use emojis to react to what you’re saying in real time. The thumbs up emoji can be a way for your learners to say they have no questions and are ready to move on to the next part of your lesson. They can also raise their hand to alert you when they have a question.</span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Peoples} width="50px" alt="people" className="leftmargin"/>
                                                    <span className="tooltiptext20">People icon:  <br/>
<li>Breakout Groups: Assign participants to breakout rooms either automatically or manually. The Breakout Groups feature also contains a Shuffle option to automatically redistribute the people in each group.
</li>
                                                        <li>People: See a list of all participants in your room. </li>

                                                    </span>
                                                </div>
                                                <div className="upgradenew20">
                                                    <img src={Leaves} width="50px" alt="Leave" className="leftmargin"/>
                                                    <span className="tooltiptext20">Leave icon:  <br/> Choose to end the meeting for all participants or leave the room individually while allowing all other participants to remain connected to REACHUM.
</span>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    {opencalenders ?
                                        <div>
                                            <div className="cccenter">
                                                <h5>Calendar Invite</h5>
                                                <br/>
                                                <p>Choose a role to send an invite for. Then, fill out the rest of the
                                                    fields.</p>
                                            </div>
                                            <br/>
                                            <Grid container spacing={0} className="topspace1">
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5} className="cccenter">
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        value="Guest"
                                                        checked={selectedOption === 'Guest'}
                                                        onChange={handleOptionChange}
                                                        className="newinviteradio"
                                                    />
                                                    <label className="newinviteradiolabel"> Guest </label>
                                                </Grid>
                                                <Grid item xs={5} className="cccenter">
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        value="coHost"
                                                        checked={selectedOption === 'coHost'}
                                                        onChange={handleOptionChange}
                                                        className="newinviteradio"
                                                    />
                                                    <label className="newinviteradiolabel">
                                                        CoHost
                                                    </label>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                            </Grid>
                                            <div>
                                                {openGuest ?
                                                    <Inviteform data={thisid}/>
                                                    : ""
                                                }
                                            </div>
                                            <div>
                                                {opencohost ?
                                                    <Invitecoform data={thisid}/>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </div>
                            }
                            <br/>
                            <br/>
                        </Tab>
                    <Tab eventKey="share" title="In-Person">
                        <br/>
                        {openipinfomation ?
                            <>
                                <Grid container spacing={0} className="topspace1">
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button onClick={() => {
                                            window.open(`/ihost/${thisid}/${thismid}`, "_blank");
                                        }} className="newstartbuttoncolor">
                                            Start Meeting
                                        </button>
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button className="newcalendarbuttoncolor" onClick={() => {
                                            setOpenipinfomation(false);
                                            setOpenipcalenders(true);
                                            setOpenipexplainer(false)
                                        }}>Calendar Invite
                                        </button>
                                    </Grid>
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                </Grid>
                                <br/>
                                <div className="cccenter">
                                    <p className="newunderline" onClick={()=>{
                                        setOpenipinfomation(false);
                                        setOpenipcalenders(false);
                                        setOpenipexplainer(true)
                                    }}> View the Host Control Guide.</p>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p> Click the button below to copy the In-Person class link.</p>
                                    <br/>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                        <Grid item xs={5}>
                                    <CopyToClipboard text={`https://exeltis.reachum.com/iview/${thisid}`} onCopy={() => {
                                        setCiopied(true);
                                        setTimeout(() => {
                                            setCiopied(false);
                                        }, 5000);
                                    }}>
                                        <button className="newclasslinksbutton"> Guest Link </button>
                                    </CopyToClipboard>
                                    {icopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <button className="newclasslinksbutton" onClick={openipqrcodeage}>Generate QR Code</button>
                                        </Grid>
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                    </Grid>
                                </div>
                                <br/>
                                <br/>
                            </> :
                            <div>
                                <p className="sharedropbtn" onClick={()=> setOpenipinfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> In-Person Meeting Links </span> </p>
                                {openipcalenders ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Invite Guests</h5>
                                        </div>
                                        <Inviteform1 data={thisid}/>
                                    </div>
                                    : ""
                                }
                                {openipexplainer ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Host Control Guide</h5>
                                            <br/>
                                            <p>Confused on how to use REACHUM In-Person? Don’t worry! We got your back.<br/>
                                                Hover over an icon below to see what it does.</p>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew2">
                                                <img src={Maxs} width="50px" alt="Max" className="leftmargin"/>
                                                <span className="tooltiptext2">Maximize View button: <br/>
Maximize your screen and bring your lesson into focus.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Slidelist} width="50px" alt="slide list" className="leftmargin"/>
                                                <span className="tooltiptext2">Content List button: <br/>
Skip forward or backward in your lesson with a single click. Open your list and click on the desired slide. Then, when you’re done reviewing, click the “next” button to return to the slide after your previous one.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Qrcodes} width="50px" alt="QR code" className="leftmargin"/>
                                                <span className="tooltiptext2">QR Code button: <br/>
Instantly pull up a QR code at any point in your lesson so users who are late to a class can still participate. Click to toggle between showing and hiding.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Plays} width="50px" alt="play button" className="leftmargin"/>
                                                <span className="tooltiptext2">Play button: <br/>
Begin a self-embedded video. (Note: Does not work on video links such as linked YouTube videos. To view a YouTube video, click the YouTube play icon in the center of the screen.)
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Syns} width="50px" alt="Sync button" className="leftmargin"/>
                                                <span className="tooltiptext2">Sync button: <br/>
If you manually move forward or backward in a self-embedded video while the video is paused, you’ll see the sync button pop up on your meeting controls. Click the sync button to sync the video playback across all Guest devices to your own.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                <span className="tooltiptext2">Back button: <br/> Move to the previous slide in your REACHUM presentation. </span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                <span className="tooltiptext2">Next button: <br/>
Move to the next slide in your REACHUM presentation.
 </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                    : ""
                                }
                            </div>
                        }

                    </Tab>
                    <Tab eventKey="selfstudy" title="Self-Study">
                        <br/>
                        {openssinfomation ?
                            <>
                                <div className="cccenter">
                                    <button className="newcalendarbuttoncolor" onClick={() => {
                                        setOpenssinfomation(false);
                                        setOpenssexplainer(false);
                                        setOpensscalenders(true);
                                    }}>Calendar Invite
                                    </button>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p className="newunderline" onClick={() => {
                                        setOpenssinfomation(false);
                                        setOpenssexplainer(true);
                                        setOpensscalenders(false);
                                    }}> View the Guest Control Guide.</p>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p> Click the button below to copy the Self-Study link.</p>
                                    <br/>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} >
                                        </Grid>
                                        <Grid item xs={5}>
                                    <CopyToClipboard text={`https://exeltis.reachum.com/selfStudy/${thisid}`} onCopy={() => {
                                        setCionopied(true);
                                        setTimeout(() => {
                                            setCionopied(false);
                                        }, 5000);
                                    }}>

                                        <button className="newclasslinksbutton">Guest Link</button>

                                    </CopyToClipboard>
                                    {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <button className="newclasslinksbutton" onClick={openqrcodeage}>Generate QR Code</button>
                                        </Grid>
                                        <Grid item xs={1} >
                                        </Grid>
                                    </Grid>
                                </div>
                            </> :
                            <div>
                                <p className="sharedropbtn" onClick={()=> setOpenssinfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> Self-Study Link </span> </p>
                                {opensscalenders ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Invite Guests</h5>
                                        </div>
                                        <Inviteondemandform data={thisid}/>
                                    </div>
                                    : ""
                                }
                                {openssexplainer ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Guest Control Guide</h5>
                                            <br/>
                                            <p>Confused on how to use REACHUM Self-Study? Don’t worry! We got your back.<br/>
                                                Hover over an icon below to see what it does.</p>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew2">
                                                <img src={Replays} width="50px" alt="replay button" className="leftmargin"/>
                                                <span className="tooltiptext2">Replay button: <br/>
Restart a REACHUM lesson.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                <span className="tooltiptext2">Back button: <br/> Move to the previous slide. </span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                <span className="tooltiptext2">Next button: <br/>
Move to the next slide.
 </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                    : ""
                                }
                            </div>
                        }
                        <br/>
                        <br/>
                    </Tab>
                </Tabs>
            </ListGroup.Item>

        )
    }
    async function storeState () {
        const response = await API.graphql({
            query: listFolders,
            variables: {
                filter: {
                    "owner": {
                        eq: username
                    }
                },
                limit:10000
            }
        })
        const datas = response.data.listFolders.items;
        if(datas.length !== 0) {
            const id = datas[0].id;
            const input ={
                id,
                folderlists: JSON.stringify(folders)
            }
            await API.graphql({
                query: updateFolders,
                variables: {input}
            });
        }else{
            const input ={
                folderlists: JSON.stringify(folders),
                owner:username
            }
            await API.graphql({
                query: createFolders,
                variables: {input}
            });
        }
        const response1 = await API.graphql({
            query: listDesktopitems,
            variables: {
                filter: {
                    "owner": {
                        eq: username
                    }
                },
                limit:10000
            }
        })
        const datas1 = response1.data.listDesktopitems.items;
        if(datas1.length !== 0) {
            const id = datas1[0].id;
            const input ={
                id,
                deskitems: JSON.stringify(desktopitems)
            }
            await API.graphql({
                query: updateDesktopitems,
                variables: {input}
            });
        }else{
            const input1 = {
                deskitems:JSON.stringify(desktopitems),
                owner:username
            }
            await API.graphql({
                query: createDesktopitems,
                variables: {input:input1}
            });
        }
        setChangesavedmessage("Saved");
        setTimeout(() => {
            setChangesavedmessage("")
        }, 500);
    }
    var mid = Math.random() * 1000000000000000000;
    const  gobackdash = async () => {
        setSavebuttonshow(true);
        try {
            const input = {
                ctime:extime,
                mid: mid,
                myrlink: myroomData.myrlinks,
                clientrlink: myroomData.clientrlinks,
                meetingname: mymeeing,
                fulls:false,
                counts:0,
                vplaypause:false,
                aplaypause:false,
                owner:username
            };
            const response = await API.graphql({
                query: createPlayslide,
                variables: {input}
            });
            const newitems = response.data.createPlayslide;
            var ids = newitems.id;
            const newitem = {
                id: ids,
                meetingname: mymeeing,
                mid:mid
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const id = datas1[0].id;
                const deskitemss = [...desktopitems, newitem]
                const input ={
                    id,
                    deskitems: JSON.stringify(deskitemss)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input}
                });
            }else{
                const input1 = {
                    deskitems:JSON.stringify(newitem),
                    owner:username
                }
                await API.graphql({
                    query: createDesktopitems,
                    variables: {input:input1}
                });
            }
            setStateData( {
                meetingname:"",
                fields: ["hostRoomUrl"]
            });
            setOpenthis(true);
            history.push(`/editroom/${ids}`);
        }catch(err){
            console.log(err)
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        setShowprocess("Creating...");
        try {
            var thedate = moment();
            var newDate = thedate.add(1, "y");
            const utcendDate = newDate.utc().format();
            const data = {
                body: {
                    endDate: utcendDate,
                    roomMode: "group",
                    fields: ["hostRoomUrl"]
                }
            }
            const exptime = newDate.format('MM/DD/YYYY');
            return await API.post("exeltiswhereby","/exeltiswhereby",data)
                .then(datas => {
                    const myrlinks = datas.hostRoomUrl;
                    const clientrlinks = datas.roomUrl;
                    const daas = {
                        myrlinks,
                        clientrlinks,
                        meetingname: stateData.meetingname,
                    };
                    setMyroomData(daas)
                    setMymeeting(daas.meetingname)
                    setExtime(exptime)
                    setShowprocess("")
                    setOpenthis(false)
                })
        }catch(err){
            console.log(err)
        }
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsFixed(scrollTop > 400); // Adjust this value based on when you want the div to become fixed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
        <Helmet>
            <title>REACHUM | DASHBOARD</title>
        </Helmet>
    <Navbars/>
    {!user ?
        <Login />
        :(
            <>
                                     <div className="homepage">
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={3}>
                                                            <div className={isFixed ? 'fixed-div' : ''}>
                                                            <div className="cccenter">
                                                                <h4>Room Menu</h4>
                                                            </div>
                                                            <br/>
                                                            <div className="inputfoldername">
                                                            {' '}
                                                            <input
                                                                type="text"
                                                                placeholder="Enter new folder name"
                                                                value={newFolderName}
                                                                onChange={handleNewFolderNameChange}
                                                                className="dasheditroomnamefoder"
                                                            />
                                                            <br/>
                                                            <div className="addfolderbutton">
                                                            <Button onClick={handleCreateFolderClick} className="foldersavechanges" size="sm">Add Folder</Button>
                                                            </div>
                                                            </div>
                                                                <br/>
                                                            <div className="cccenter">
                                                                <Button onClick={goCreate} className="addsavechanges">Add Room</Button>
                                                                <br/>
                                                                <br/>
                                                                {savebuttonshow ?
                                                                    <>
                                                                    <Button onClick={storeState}
                                                                            className="newdashboardsave">Save
                                                                        Changes</Button> <span className="redthis"> {changesavedmessage} </span>
                                                                    </>
                                                                    :""
                                                                }
                                                            </div>
                                                            <br/>
                                                            <hr/>
                                                            <br/>
                                                            {openthisitems ?
                                                                <>
                                                                    <div className="cccenter">
                                                                        <h5>
                                                                            Room Selected:
                                                                        </h5>
                                                                        <p className="redthisrechum">{thisname}</p>
                                                                        <br/>
                                                                        <p onClick={() => {
                                                                            history.push(`/editroom/${thisid}`);
                                                                        }}>
                                                                            Manage Content
                                                                        </p>
                                                                        <p onClick={() => {
                                                                            history.push(`/hostleaderboard/${thisid}`);
                                                                        }}>Manage Leaderboard
                                                                        </p>
                                                                        <p onClick={() => {
                                                                            setMname(thisname);
                                                                            setThisid(thisid);
                                                                            sharelist();
                                                                        }}> Share Room
                                                                        </p>
                                                                        <button onClick={() => {
                                                                            setShow(true);
                                                                            setThisname(thisname);
                                                                            setThisid(thisid);
                                                                            setThismid(thismid);
                                                                        }} className="golivebutton">Meeting Links</button>
                                                                    </div>
                                                                </>
                                                                : ""
                                                            }
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={9} className="cccenter">
                                                            <div className="dashvl"></div>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={9}>
                                                               <h4> Room List </h4>
                                                                </Grid>
                                                                <Grid item xs={3} className="rightside leftview">
                                                                    <p>{myplan}</p>
                                                                </Grid>
                                                            </Grid>
                                                            <div className="newroomlistpage">
                                                                <div className="newdashheader">
                                                                    <Grid container spacing={0}>
                                                                        <Grid item xs={1} className="cccenter">
                                                                            <div className="upgradenew20">
                                                                                <p>Move <img src={Newinfo} width="17px"/></p>
                                                                                <span className="tooltiptext20">Organizing your room list<br/>
To move rooms up or down in your list or to add them to a folder, click the Move icon (⬍) to the left of each room name you’d like to select, and drag your mouse to your desired location.
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={10} className="newleftside">
                                                                            <p>Room Name</p>
                                                                        </Grid>
                                                                        <Grid item xs={1} className="rightside">
                                                                            <p>Delete</p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <DndContext
                                                                    sensors={sensors}
                                                                    collisionDetection={pointerWithin}
                                                                    onDragEnd={handleDragEnd}
                                                                    onDragOver={handleDragOver}
                                                                    onDragStart={handleDragStart}
                                                                >
                                                                    <DesktopView>
                                                                        {desktopViews}
                                                                    </DesktopView>
                                                                    <DragOverlay>
                                                                        {draggingItems}
                                                                    </DragOverlay>
                                                                    {folderViews}
                                                                </DndContext>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                    <Modal show={show7} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                                        <Modal.Body>
                                            <Form className="market-header">
                                                <div className="cccenter">
                                                <div className="createheadnote">
                                                    <span className="redthis">{showprocess}</span>
                                                </div>
                                                    <br/>
                                                    {openthis ?
                                                        <>
                                                            <h4>Name the Room</h4>
                                                            <p>(holds up to 200 people)</p>
                                                        </> :
                                                        <>
                                                            <h4>Create a Room</h4>
                                                            <p>(holds up to 200 people)</p>
                                                        </>
                                                    }
                                                <Form.Item>
                                                    <Input
                                                        type="text"
                                                        value={stateData.meetingname}
                                                        onChange={meetingname => setStateData({
                                                            ...stateData,
                                                            meetingname: meetingname
                                                        })}
                                                        placeholder="Room Name"
                                                        className="newcrroominput"
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    {openthis ?
                                                        <>
                                                            <Button onClick={()=>setShow7(false)} className="foldersavechanges"> &nbsp; &nbsp; Cancel &nbsp; &nbsp;</Button> &nbsp; &nbsp; &nbsp;
                                                        <Button
                                                            disabled={!stateData.meetingname}
                                                            onClick={handleSubmit2}
                                                            className="foldersavechanges"
                                                        >
                                                            &nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;
                                                        </Button>
                                                        </>
                                                        :
                                                        <Button onClick={()=>{
                                                            gobackdash();
                                                            setShow7(false)
                                                        }
                                                        } className="newdashboardsave">Create Room</Button>
                                                    }
                                                </Form.Item>
                                                <br/>
                                                <br/>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                    <Modal.Header closeButton>
                        {thisname} - Meeting Links
                    </Modal.Header>
                    <Modal.Body>
                        {renderDatalinks()}
                    </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                    <Modal.Header closeButton>
                        Calendar Invite
                    </Modal.Header>
                    <Modal.Body>
                        <Inviteform data={thisid}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show4} onHide={handleClose4} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                    <Modal.Header closeButton>
                        Calendar Invite
                    </Modal.Header>
                    <Modal.Body>
                        <Invitecoform data={thisid}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show5} onHide={handleClose5} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                    <Modal.Header closeButton>
                        {mname}
                    </Modal.Header>
                    <Modal.Body>
                        <Shareroom data = {thisid} />
                    </Modal.Body>

                </Modal>
            </>
        )
    }
            <Foot/>
            <Foott />
                                </>


    );
}
export default Dashboard;

