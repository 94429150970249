import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import { updatePlayslide} from "../../graphql/mutations";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Login from "../../pages/login";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import "../css/mroom.css";
import Playb from "../../img/host/play-btn.png";
import Pauseb from "../../img/host/pause-btn.png";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import Modal from 'react-modal';
import Fullscreen from "../../img/host/fullscreen.png";
import Expand from "../../img/host/expand.png"
import Close from "../../img/host/close.png"
import Qrcode from "../../img/host/qr-code.png";

Modal.setAppElement('#root');
const Popup = ({ src }) => {
    return (
        <div className="popupqr">
            <div className="popupqr-inner">
                <iframe src={src} title="QR code" className="thisqrcode" loading="lazy"></iframe>
            </div>
        </div>
    );
};

function Imroom() {
    const [urls, setUrls] = useState([]);
    const [user, setUser] = useState();
    const [count, setCount] = useState(0);
    const [isShows, setIsShows] = useState(true);
    const [mylists, setMylists] = useState([]);
    const [isShowfulls, setIsShowfulls] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupqr, setShowPopupqr] = useState(false);
    const { id } = useParams();
    let history = useHistory();
    const reachumnname = localStorage.getItem("vreachumnewname");
    if(reachumnname){
        localStorage.removeItem("vreachumnewname")
    }
    useEffect(() => {
        (async () => {
            const input = {
                id,
                exiton: false
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
            })
            .catch((err) => console.log(err));
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const vv = datas.vplaypause;
            if(vv === true){
                setIsShows(false)
            }else{
                setIsShows(true)
            };
            const cid = datas.id;
            const h = datas.playlistlinks;
            let lists = [];
            const ilists = h.length; // playlist length
            for (let j = 0; j < ilists; j++) {
                const parray = h[j].rurl;
                lists.push(parray)
            };
            setMylists(lists);
            let i = ilists - 1;
            if (count <= i) {
                setUrls(h[count].rurl);
                const input = {
                    id: cid,
                    aplaypause:false,
                    clink: h[count].rurl
                };
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);
    const handleplay = async () =>{
        const isPlay = true;
        const pts = {id};
        const input = {
            id: pts.id,
            vplaypause:isPlay
        };
        const theFrame = document.getElementById('theFrame');
        const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document
        theFrameDocument.getElementById('frameBtn').click();
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setIsShows(false)
    }
    const handlepause = async () =>{
        const isPlay = false;
        const ptds = {id};
        const input = {
            id: ptds.id,
            vplaypause:isPlay
        };
        const theFrame = document.getElementById('theFrame');
        const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document
        theFrameDocument.getElementById('frameBtn').click();
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setIsShows(true)
    }
    const renderSlides = () => {
        return (
            <>
                    {mylists.map((slideContent, index) => (
                        <div className="slides" key={index}>
                                <img src={Noi} className="noi" width="125" height="72" onClick={ () => {
                                    const input = {
                                        id,
                                        aplaypause:false,
                                        clink: slideContent
                                    };
                                    API.graphql({
                                        query: updatePlayslide,
                                        variables: {input}
                                    });
                                    const thelist = document.getElementById('theFrame');
                                    thelist.src = slideContent;
                                    setShowPopup(false)
                                }} />
                                <iframe src={slideContent} scrolling="no" className="editsframe" loading="lazy"></iframe>
                            </div>
                    ))}
            </>
        );
    }

    useHotkeys('n',()=>{
        setCount(counts => counts + 1);
    })
    useHotkeys('b',()=>{
        setCount(counts => counts - 1);
    })
    useHotkeys('.', () => {
        if (isShows === false) {
            handlepause()
        }else{
            handleplay()
        }
    },{}, [isShows])

    function handlePopupqrButtonClick(){
        setShowPopupqr(!showPopupqr);
    }
    const ends  = async() =>{
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    function handlePopupButtonClick() {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
        setShowPopup(true);
        setIsShowfulls(false)
    }
    function handlefullscreen(){
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
    }
    function handleminiscreen(){
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
    function handleFullscreenChange() {
        if (!document.fullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
        if (!document.webkitFullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return (
        <>
            <Helmet>
                <title>HOST</title>
            </Helmet>
            {!user ?
                <Login />
                :(
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                loading="lazy"
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={9} className="centerside">
                                        <>
                                            {isShowfulls ?
                                                <img src={Expand} onClick={()=>{handlefullscreen();setIsShowfulls(false)}} className="mimg"/>

                                                :
                                                <img src={Fullscreen} onClick={() => {handleminiscreen();setIsShowfulls(true)}} className="mimg"/>
                                            }
                                            <div className="upgradenew3">
                                                <img src={Lists} onClick={handlePopupButtonClick} className="mimg" />
                                                <span className="tooltiptext3">Content List. <br/> Click on any slide to show</span>
                                            </div>
                                            <img src={Qrcode} onClick={handlePopupqrButtonClick} className="mimg" alt="QR code" />
                                            {showPopupqr && <Popup src={`/qrcodepage/${id}`} />}
                                            {isShows ?

                                                    <img src={Playb} onClick={handleplay} className="mimg" />

                                                :

                                                    <img src={Pauseb} onClick={handlepause} className="mimg" />

                                            }

                                                <img src={Left} className="mimg" onClick={() => {
                                                    setCount(count - 1);
                                                    if (count === 0) {
                                                        setCount(0)
                                                    }
                                                }} id="5" />

                                                <img src={Right} onClick={() => setCount(count + 1)} className="mimg" />
                                        </>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="ileftside">
                                            <img src={Ending} onClick={ends} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Modal isOpen={showPopup} ariaHideApp={false}>
                                <div className="rightside">
                                    <img src={Close} onClick={() => setShowPopup(false)}/>
                                </div>
                                <br/>
                                {renderSlides()}
                            </Modal>
                    </>
                )
            }
        </>
    );
}
export default Imroom;
