import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Room from "./components/view/room";
import Iroom from "./components/view/iroom";
import Mcroom from "./components/view/mcroom";
import Rform from "./components/view/rform";
import Support from "./pages/support";
import Dashboard from "./pages/dashboard/dashboard";
import Rehearse from "./pages/rehearse";
import Editroom from "./pages/editroom";
import Profile from "./pages/profile";
import Tutorials from "./pages/tutorials";
import TermandConditions from "./pages/termandConditions";
import Imroom from "./components/host/imroom";
import Mroom from "./components/host/mroom";
import Comroom from "./components/cohost/mroom";
import Timer from "./components/timer";
import Vshowroom from "./components/uploadfiles/vid/vshowroom";
import Pdfshowroom from "./components/uploadfiles/pdf/pdfshowroom";
import Imgshowroom from "./components/uploadfiles/img/imgshowroom";
import Gifshowroom from "./components/gif/gifshowroom";
import Leaderboard from "./components/leaderboard/leaderboard";
import Hostleaderboard from "./components/leaderboard/hostleaderboard";
import Pollshowroom from "./components/poll/pollshowroom";
import Qrcodepage from "./pages/qrcodepage";
import Ipqrcodepage from "./pages/ipqrcodepage";
import Ssqrcodepage from "./pages/ssqrcodepage";
import Inviteform from "./pages/invitepeople/inviteform";
import Inviteform1 from "./pages/invitepeople/inviteform1";
import Gifroom from "./components/uploadfiles/uploadgif/gifroom";
import Iurlshowroom from "./components/uploadurl/iurlshowroom";
import Urlshowroom from "./components/uploadurl/urlshowroom";
import Liveshowroom from "./components/livestream/liveshowroom";
import Uploadlive from "./components/livestream/uploadlive";
import Vimeoshowroom from "./components/vimeo/vimeoshowroom";
import Fasteranswerroom from "./components/leaderboardgames/fastanswer/fasteranswerroom";
import Optionshowroom from "./components/leaderboardgames/options/optionshowroom";
import Flipshowroom from "./components/leaderboardgames/flipcard/flipshowroom";
import Multioptionshowroom from "./components/leaderboardgames/multipleoptions/multioptionshowroom";
import Pptxshowroom from "./components/uploadfiles/pptx/pptxshowroom";
import Newtextshowroom from "./components/slides/newtextshowroom";
import Textshowroom from "./components/slides/textshowroom";
import Newtextslideedit from "./components/slides/newtextslideedit";
import Textslideedit from "./components/slides/textslideedit";
import Videoquizsetup from "./components/leaderboardgames/videoquiz/videoquizsetup";
import Videoplayer from "./components/leaderboardgames/videoquiz/videoplayer";
import Videorecord from "./components/leaderboardgames/videoquiz/videorecord/videorecord";
import Videopreviewpage from "./components/leaderboardgames/videoquiz/videorecord/videopreviewpage";
import Ideorecordr from "./components/recordvideo/ideorecordr";
import Ideopreviewpager from "./components/recordvideo/ideopreviewpager";
import Fastansweredit from "./components/leaderboardgames/fastanswer/fastansweredit";
import Multioptionsedit from "./components/leaderboardgames/multipleoptions/multioptionsedit";
import Optionsedit from "./components/leaderboardgames/options/optionsedit";
import Ideoedit from "./components/recordvideo/ideoedit";
import Pollsedit from "./components/poll/pollsedit";
import Oneoptionquesedit from "./components/leaderboardgames/videoquiz/oneoptionquesedit";
import Imgedit from "./components/uploadfiles/img/imgedit";
import Giphyedit from "./components/gif/giphyedit";
import Flipcardsedit from "./components/leaderboardgames/flipcard/flipcardsedit";
import Urledit from "./components/uploadurl/urledit";
import Vimeoedit from "./components/vimeo/vimeoedit";
import Videoedit from "./components/uploadfiles/vid/videoedit";
import Gifsedit from "./components/uploadfiles/uploadgif/gifsedit";
import Blackboardedit from "./components/blackboard/blackboardedit";
import Blackboardshowroom from "./components/blackboard/blackboardshowroom";
import Answerroom from "./components/answers/answerroom";
import Answeredit from "./components/answers/answeredit";
import Fastercorrctanswer from "./components/leaderboardgames/fastanswer/fastercorrctanswer";
import Flipcardcorrctanswer from "./components/leaderboardgames/flipcard/flipcardcorrctanswer";
import Multicorrctanswer from "./components/leaderboardgames/multipleoptions/multicorrctanswer";
import Onecorrctanswer from "./components/leaderboardgames/options/onecorrctanswer";
import Videocorrctanswer from "./components/leaderboardgames/videoquiz/videocorrctanswer";
import Showdragdrop from "./components/leaderboardgames/taptap/showdragdrop";
import Categorytaptapedit from "./components/leaderboardgames/taptap/categorytaptapedit";
import Correcttaptap from "./components/leaderboardgames/taptap/correcttaptap";
import Morereports from "./components/leaderboard/morereports";
import Showsequencing from "./components/leaderboardgames/sequencing/showsequencing";
import Sequencingedit from "./components/leaderboardgames/sequencing/sequencingedit";
import Correctsequencing from "./components/leaderboardgames/sequencing/correctsequencing";
import Rankingedit from "./components/ranking/rankingedit";
import Showranking from "./components/ranking/showranking";
import Adobe from "./components/adobeexpress/adobe";
import Adobeshow from "./components/adobeexpress/adobeshow";
import Adobeedit from "./components/adobeexpress/adobeedit";
import SelfStudy from "./pages/ondemand/selfStudy";
import Inviteondemandform from "./pages/invitepeople/inviteondemandform";
import Newteslideedit from "./components/slides/newteslideedit";
import Newteshowroom from "./components/slides/newteshowroom";
import Newerslideedit from "./components/slides/newerslideedit";
import Newershowroom from "./components/slides/newershowroom";
import Wordcloudroom from "./components/wordcloud/wordcloudroom";
import Wordcloudedit from "./components/wordcloud/wordcloudedit";
import Teams from "./pages/teams/dashboard/teams";
import Teamseditroom from "./pages/teams/teamseditroom";
import Teamsadobe from "./components/adobeexpress/teamsadobe";
import Teamsideorecordr from "./components/recordvideo/teamsideorecordr";
import Teamsideopreviewpager from "./components/recordvideo/teamsideopreviewpager";

export const UserContext = React.createContext();

const App = () => {
    return (
                <Router>
                        <div>
                            <Route exact path={"/host/:id/:mid"} component={Mroom} />
                            <Route exact path="/cohost/:id/:mid" component={Comroom} />
                            <Route exact path={"/ihost/:id/:mid"} component={Imroom} />
                            <Route exact path={"/view/:id"} component={Room} />
                            <Route exact path={"/rform/:id"} component={Rform} />
                            <Route exact path={"/iview/:id"} component={Iroom} />
                            <Route exact path={"/view/mc/:id"} component={Mcroom} />
                            <Route exact path="/videorecord/:id" component={Videorecord} />
                            <Route exact path="/videopreviewpage/:id" component={Videopreviewpage} />
                            <Route exact path="/ideorecordr/:id" component={Ideorecordr} />
                            <Route exact path="/teamsideorecordr/:id" component={Teamsideorecordr} />
                            <Route exact path="/ideopreviewpager/:id" component={Ideopreviewpager} />
                            <Route exact path="/videoquizsetup/:id/:rid" component={Videoquizsetup} />
                            <Route exact path="/videoplayer/:id/:rid" component={Videoplayer} />
                            <Route exact path={"/vshowroom/:id/:rid"} component={Vshowroom} />
                            <Route exact path="/morereports/:id" component={Morereports} />
                            <Route exact path="/fastansweredit/:id/:rid" component={Fastansweredit} />
                            <Route exact path="/fastercorrctanswer/:id/:rid" component={Fastercorrctanswer} />
                            <Route exact path="/flipcardcorrctanswer/:id/:rid" component={Flipcardcorrctanswer} />
                            <Route exact path="/multicorrctanswer/:id/:rid" component={Multicorrctanswer} />
                            <Route exact path="/onecorrctanswer/:id/:rid" component={Onecorrctanswer} />
                            <Route exact path="/videocorrctanswer/:id/:rid" component={Videocorrctanswer} />
                            <Route exact path="/imgedit/:id/:rid" component={Imgedit} />
                            <Route exact path="/vimeoedit/:id/:rid" component={Vimeoedit} />
                            <Route exact path="/videoedit/:id/:rid" component={Videoedit} />
                            <Route exact path="/answeredit/:id/:rid" component={Answeredit} />
                            <Route exact path="/answerroom/:id/:rid" component={Answerroom} />
                            <Route exact path="/urledit/:id/:rid" component={Urledit} />
                            <Route exact path="/flipcardsedit/:id/:rid" component={Flipcardsedit} />
                            <Route exact path="/multioptionsedit/:id/:rid" component={Multioptionsedit} />
                            <Route exact path="/optionsedit/:id/:rid" component={Optionsedit} />
                            <Route exact path="/pollsedit/:id/:rid" component={Pollsedit} />
                            <Route exact path="/ideoedit/:id/:rid" component={Ideoedit} />
                            <Route exact path="/adobe/:id" component={Adobe} />
                            <Route exact path="/teamsadobe/:id" component={Teamsadobe} />
                            <Route exact path="/adobeshow/:id/:rid" component={Adobeshow} />
                            <Route exact path="/adobeedit/:id/:rid" component={Adobeedit} />
                            <Route exact path="/newtextshowroom/:id/:rid" component={Newtextshowroom} />
                            <Route exact path="/textshowroom/:id/:rid" component={Textshowroom} />
                            <Route exact path="/newtextslideedit/:id/:rid" component={Newtextslideedit} />
                            <Route exact path="/textslideedit/:id/:rid" component={Textslideedit} />
                            <Route exact path="/giphyedit/:id/:rid" component={Giphyedit} />
                            <Route exact path="/oneoptionquesedit/:id/:rid" component={Oneoptionquesedit} />
                            <Route exact path="/gifsedit/:id/:rid" component={Gifsedit} />
                            <Route exact path="/flipshowroom/:id/:rid" component={Flipshowroom} />
                            <Route exact path={"/pdfshowroom/:id/:rid"} component={Pdfshowroom} />
                            <Route exact path={"/imgshowroom/:id/:rid"} component={Imgshowroom} />
                            <Route exact path={"/uploadlive"} component={Uploadlive} />
                            <Route exact path={"/gifshowroom/:id/:rid"} component={Gifshowroom} />
                            <Route exact path={"/liveshowroom/:id/:rid"} component={Liveshowroom} />
                            <Route exact path={"/gifroom/:id/:rid"} component={Gifroom} />
                            <Route exact path={"/hostleaderboard/:id"} component={Hostleaderboard} />
                            <Route exact path={"/leaderboard/:id"} component={Leaderboard} />
                            <Route exact path="/showdragdrop/:id/:rid" component={Showdragdrop} />
                            <Route exact path="/correcttaptap/:id/:rid" component={Correcttaptap} />
                            <Route exact path="/categorytaptapedit/:id/:rid" component={Categorytaptapedit} />
                            <Route exact path={"/pptxshowroom/:id/:rid"} component={Pptxshowroom} />
                            <Route exact path={"/urlshowroom/:id/:rid"} component={Urlshowroom} />
                            <Route exact path={"/iurlshowroom/:id/:rid"} component={Iurlshowroom} />
                            <Route exact path={"/Multioptionshowroom/:id/:rid"} component={Multioptionshowroom} />
                            <Route exact path={"/pollshowroom/:id/:rid"} component={Pollshowroom} />
                            <Route exact path={"/vimeoshowroom/:id/:rid"} component={Vimeoshowroom} />
                            <Route exact path={"/fasteranswerroom/:id/:rid"} component={Fasteranswerroom} />
                            <Route exact path="/optionshowroom/:id/:rid" component={Optionshowroom} />
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/timer" component={Timer} />
                            <Route exact path="/tutorials" component={Tutorials} />
                            <Route exact path={"/blackboardshowroom/:id/:rid"} component={Blackboardshowroom} />
                            <Route exact path={"/blackboardedit/:id/:rid"} component={Blackboardedit} />
                            <Route exact path="/termandConditions" component={TermandConditions} />
                            <Route exact path="/showsequencing/:id/:rid" component={Showsequencing} />
                            <Route exact path="/correctsequencing/:id/:rid" component={Correctsequencing} />
                            <Route exact path="/sequencingedit/:id/:rid" component={Sequencingedit} />
                            <Route exact path={"/showranking/:id/:rid"} component={Showranking} />
                            <Route exact path={"/rankingedit/:id/:rid"} component={Rankingedit} />
                            <Route exact path="/newteslideedit/:id/:rid" component={Newteslideedit} />
                            <Route exact path="/newteshowroom/:id/:rid" component={Newteshowroom} />
                            <Route exact path={"/newershowroom/:id/:rid"} component={Newershowroom} />
                            <Route exact path={"/newerslideedit/:id/:rid"} component={Newerslideedit} />
                            <Route exact path={"/wordcloudedit/:id/:rid"} component={Wordcloudedit} />
                            <Route exact path={"/wordcloudroom/:id/:rid"} component={Wordcloudroom} />
                            <Route exact path="/teamsideopreviewpager/:id" component={Teamsideopreviewpager} />
                            <Route exact path={"/rehearse/:id"} render={props => {
                                return <Rehearse {...props} />
                            }} />
                            <Route exact path="/qrcodepage/:id" render={props => {
                                return <Qrcodepage {...props} />
                            }} />
                            <Route exact path="/ipqrcodepage/:id" render={props => {
                                return <Ipqrcodepage {...props} />
                            }} />
                            <Route exact path="/ssqrcodepage/:id" render={props => {
                                return <Ssqrcodepage {...props} />
                            }} />
                            <Route exact path={"/editroom/:id"} render={props => {
                                return <Editroom {...props} />
                            }} />
                            <Route exact path={"/teamseditroom/:id"} render={props => {
                                return <Teamseditroom {...props} />
                            }} />
                            <Route exact path={"/selfStudy/:id"} render={props => {
                                return <SelfStudy {...props} />
                            }} />
                            <Route exact path={"/inviteondemandform/:id"} render={props => {
                                return <Inviteondemandform {...props} />
                            }} />
                            <Route exact path="/inviteform1/:id" render={props => {
                                return <Inviteform1 {...props} />
                            }} />
                            <Route exact path="/inviteform/:id" render={props => {
                                return <Inviteform {...props} />
                            }} />
                            <Route exact path="/teams" component={Teams} />
                            <Route exact path="/" component={Dashboard} />
                        </div>
                </Router>
    )
}

export default App
