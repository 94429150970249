import React, {useEffect,useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import Openpage from "../../img/dash/openpage.png";

function Urlshowroom() {
    const [lvfile, setLvfile] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const datasn = datas.playlistlinks;
            const newdatas = datasn.filter(data => data.rid === rid);
            const vbucket = newdatas[0].lurl;
            setLvfile(vbucket);
        }catch(error){
            console.log("can't display")
        }
    }
    const openpages = async e => {
        window.open(`${lvfile}`, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=760, height=700")
    }
    return (
        <>
            <div className="vidshows">
                <img src={Openpage} onClick={openpages} alt="ecommerce" className="urlsvg"/>
            </div>
        </>
    );
}

export default Urlshowroom;
