import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import awsExports from "../../../aws-exports";
import {updatePlayslide} from '../../../graphql/mutations';
import { getPlayslide } from "../../../graphql/queries";
import { UploadFiles } from './FileUpload';
import "../../css/uploadimg.css";
import {Card} from "react-bootstrap";
import {v4 as uuid} from "uuid";


let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
function Uploadgifs({id, handleClose72}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState("");
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const  addImageTopDB = async (input) => {
        try {
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        } catch (error) {
            console.log(error)
        }
    }
    const upload = async (files) => {
        setError('');
        const MAX_FILE_SIZE = 10240;
        const file = files[0];
        const fileSizeKiloBytes = file.size / 1024;
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setError("File size is greater than maximum limit");
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
        const ext = file.name;
        const jnfile = `gif/${d}${ext}`;
        await Storage.put(jnfile, file, {
            contentType: "image/gif",
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods/done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if( loadin === 100){
                        setProcessing("Uploading...")
                }
            },
        }).then(() => {
            const ryrl = `https://exeltis.reachum.com/gifroom/${id}/${d}`;
            const eryrl = `https://exeltis.reachum.com/gifsedit/${id}/${d}`;
            const vvv = {
                title: ext,
                file: {
                    bucket: vbucket,
                    region: vregion,
                    key: jnfile
                },
                rid: d,
                rurl: ryrl,
                eurl: eryrl
            }
            const allImages = [...images, vvv]
            const input = {
                id,
                playlistlinks: allImages
            }
            addImageTopDB(input);
            setOpensmessage(false);
            setSmessage("Uploaded");
            setTimeout(() => {
                handleClose72()
            }, 1000)
        })
            .catch(err => console.log(err));
    }

    return (
        isOwner === isUser ?
        <>
            <div className="uploadpptx">
                <Card className="text-center">
                    <Card.Header as="h6">Upload a GIF (10MB limit)</Card.Header>
                    <Card.Body>
                    {error && <h5>{error}</h5>}
                    <UploadFiles onUpload={upload} />
                    <p>{processing}</p>
                    </Card.Body>
                </Card>
                {opensmessage ?
                    ""
                    :
                    <div className="cccenter">
                        <p className="redthis"> {smessage} </p>
                    </div>
                }
            </div>
        </>
            :""
    );
}

export default Uploadgifs;

