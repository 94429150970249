import {Auth,API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide,listPlayslides,listFolders,listDesktopitems} from "../graphql/queries";
import {useHistory, useParams} from "react-router-dom";
import {newUpdatePlayslide,newDeletePlayslide} from "../graphql/subscriptions";
import {updatePlayslide,updateFolders,updateDesktopitems} from "../graphql/mutations";
import {Modal} from 'react-bootstrap';
import {Form} from "element-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '../img/delete.png';
import Copys from '../img/copy.png';
import Navbars from "../components/Navbar";
import Foot from "../components/Foot";
import "./css/editroom.css";
import {v4 as uuid} from "uuid";
import Leftarrow from "../img/left-arrow.png";
import Noi from "../img/no.png";
import {Helmet} from "react-helmet";
import Duplicate from "../img/dash/Duplicate-Button.png";
import Editb from "../img/dash/edit.png";
import Allmove from "../img/Move.svg";
import Zoomin from "../img/zoom-in.png";
import Zoomout from "../img/zoom-out.png";
import ListGroup from "react-bootstrap/ListGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Editbs from "./dashboard/icons/Edit.svg";
import Inviteform from "./invitepeople/inviteform";
import Invitecoform from "./invitepeople/invitecoform";
import Qrcodes from "../img/host/explain/qrcode.png";
import "./css/contenttypes.css";
import Menus from "./menus";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';
import Newinfo from "../img/info.png";
import Layoute from "../img/host/explain/layout.png";
import Slidelist from "../img/host/explain/slidelist.png";
import Timer from "../img/host/explain/timer.png";
import Plays from "../img/host/explain/play.png";
import Syns from "../img/host/explain/syn.png";
import Backs from "../img/host/explain/left.png";
import Nexts from "../img/host/explain/next.png";
import Cams from "../img/host/explain/cam.png";
import Mics from "../img/host/explain/mic.png";
import Shares from "../img/host/explain/share.png";
import Records from "../img/host/explain/record.png";
import Chats from "../img/host/explain/chat.png";
import Peoples from "../img/host/explain/mpeoplepng.png";
import Leaves from "../img/host/explain/leave.png";
import Inviteform1 from "./invitepeople/inviteform1";
import Maxs from "../img/host/explain/max.png";
import Inviteondemandform from "./invitepeople/inviteondemandform";
import Replays from "../img/host/explain/replay.png";

function Editroom() {
    const { id } = useParams();
    const history = useHistory();
    const [isFixed, setIsFixed] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isUsere, setIsUsere] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [purls, setPurls] = useState([]);
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [copied, setCopied] = useState("");
    const [icopied, setCiopied] = useState("");
    const [ccopied, setCcopied] = useState("");
    const [ymeetingid, setYmeetingid] = useState();
    const [duplicateopen, setDuplicateopen] = useState(true);
    const [indexs, setIndexs] = useState(false);
    const [titless, setTitless] = useState(false);
    const [show2, setShow2] = useState(false);
    const [isopen, setIsopen] = useState(true);
    const [condata, setCondata] = useState([]);
    const [eurls, setEurls] = useState("");
    const [changesavedmessage, setChangesavedmessage] = useState("");
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {setShow1(false)};
    const [cionopied, setCionopied] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleClose = () => setShow(false);
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => {setShow6(false);setShow3(true)};
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {setShow3(false);};
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => {setShow4(false);setShow3(true)};
    const [openinfomation, setOpeninfomation] = useState(true);
    const [openroles, setOpenroles] = useState(false);
    const [opencalenders, setOpencalenders] = useState(false);
    const [openipinfomation, setOpenipinfomation] = useState(true);
    const [openipcalenders, setOpenipcalenders] = useState(false);
    const [openssinfomation, setOpenssinfomation] = useState(true);
    const [opensscalenders, setOpensscalenders] = useState(false);
    const [openssexplainer, setOpenssexplainer] = useState(false);
    const [openGuest, setOpenGuest] = useState(true);
    const [opencohost, setOpencohost] = useState(false);
    const [openexplainer, setOpenexplainer] =useState(false);
    const [openipexplainer, setOpenipexplainer] =useState(false);
    const [selectedOption, setSelectedOption] = useState('Guest');
    const d = uuid();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsUser(user.attributes.email);
                setIsUsere(user.attributes.email)
            })
            .catch(err => console.log(err));
        fetchData();
        fetchcata();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe()
        }
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
        setIsOwner(iowner);
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const emeetingid = datas.mid;
        setYmeetingid(emeetingid);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
            setDuplicateopen(false)
        }
    }
    async function fetchcata() {
        const response = await API.graphql({
            query: listPlayslides
        });
        const datas = response.data.listPlayslides.items;
        setCondata(datas);
    }
    const handleChange = (e, id) => {
        e.preventDefault();
        const newContentIndex = playcontents.findIndex(content => content.rid === id);
        const newContent = playcontents[newContentIndex];
        newContent.title = e.target.value;
        const clonedPlaycontents = [...playcontents];
        clonedPlaycontents.splice(newContentIndex, 1, newContent);
        setPlaycontents(clonedPlaycontents);
    }
    const sid = id;
    const handleDelete = async (id) => {
        const newPlayContents = playcontents.filter(content => content.rid !== id);
        try {
            const input = {
                id: sid,
                playlistlinks: newPlayContents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setPlaycontents(newPlayContents);
    }
    const rendercondata = () => {
        return condata.map(item => (
            item.owner === isUser ?
                <option key={item.id} value={item.id}>{item.meetingname}</option>
                : ""
        ))
    }
    const myFunction = () => {
        var x = document.getElementById("mySelect").value;
        API.graphql({
            query: getPlayslide,
            variables: { id: x }
        }).then(datas => {
            const cdata = datas.data.getPlayslide;
            const condata = cdata.playlistlinks;
            const replaced = condata.map((element) => {
                const urlElements = element.rurl.split('/');
                const ee = element.eurl;
                let eurlElements;
                let neweurl;
                if(ee) {
                    eurlElements = ee.split('/');
                    eurlElements[4] = id;
                    neweurl = eurlElements.join('/');
                }
                urlElements[4] = id;
                const newUrl = urlElements.join('/');
                const newElements = {...element, rurl: newUrl, eurl: neweurl};
                return newElements;
            });
            try {
                const input = {
                    id,
                    playlistlinks: replaced
                };
                API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }catch(err){
                console.log(err)
            }
        })
    }
    const  handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const input = {
                id,
                playlistlinks: playcontents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setChangesavedmessage("Saved")
        setTimeout(() => {
            setChangesavedmessage("")
        }, 1000);
    }
    async function changeroomname(event){
        event.preventDefault();
        setYmeeting(event.target.value);
    }

    useEffect(() => {
        const delay = 2000;
        const timeoutId = setTimeout(() => {
            saveInputValue(ymeeting);
        }, delay);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [ymeeting]);

    const saveInputValue = async (value) => {
        try {
            const input = {
                id,
                meetingname: value
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            const user = await Auth.currentAuthenticatedUser();
            const thisuser = user.attributes.email;
            const response = await API.graphql({
                query: listFolders,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listFolders.items;
            if(datas.length !== 0) {
                const thisfolderid = datas[0].id;
                const thisfolder = JSON.parse(datas[0].folderlists);
                const updatedData = { ...thisfolder };
                for (const key in updatedData) {
                    updatedData[key].items = updatedData[key].items.map(item => {
                        if (item.id === id) {
                            return { ...item, meetingname: value };
                        }
                        return item;
                    });
                }
                const input = {
                    id: thisfolderid,
                    folderlists: JSON.stringify(updatedData)
                }
                await API.graphql({
                    query: updateFolders,
                    variables: {input}
                });
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const thisitemid = datas1[0].id;
                const thisfolderitem = JSON.parse(datas1[0].deskitems);
                const updatedData1 = thisfolderitem.map(item => {
                    if (item.id === id) {
                        return { ...item, meetingname: value };
                    }
                    return item;
                });
                const input1 ={
                    id:thisitemid,
                    deskitems: JSON.stringify(updatedData1)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input:input1}
                });
            }
        } catch (err) {
            console.log(err)
        }
    };
    function gobackthis(){
        history.push(`/`);
    }

    const handleOptionChange = (event) => {
        const cc = event.target.value;
        if(cc === "coHost"){
            setOpenGuest(false);
            setOpencohost(true);
            setSelectedOption("coHost")
        }else{
            setOpenGuest(true);
            setOpencohost(false);
            setSelectedOption("Guest")
        }
    };
    function openqrcodeage(){
        window.open(`https://exeltis.reachum.com/ssqrcodepage/${id}`, "Reachum Self-Study QR code", "popup" )
    }
    function openipqrcodeage(){
        window.open(`https://exeltis.reachum.com/ipqrcodepage/${id}`, "Reachum in-Person QR code", "popup" )
    }
    const renderDatalinks = () => {
        return (
            <ListGroup.Item >
                <Tabs
                    defaultActiveKey="full"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="full" title="Remote">
                        <br/>
                        {openinfomation ?
                            <>
                                <Grid container spacing={0} className="topspace1">
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button onClick={() => {
                                            window.open(`/host/${id}/${ymeetingid}`, "_blank");
                                        }} className="newstartbuttoncolor">
                                            Start Meeting
                                        </button>
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button className="newcalendarbuttoncolor" onClick={()=> {
                                            setOpeninfomation(false);
                                            setOpencalenders(true);
                                            setOpenroles(false);
                                            setOpenexplainer(false)
                                        }}>Calendar Invite</button>
                                    </Grid>
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                </Grid>
                                <br/>
                                <br/>
                                <div className="cccenter">
                                    <p className="newunderline" onClick={()=>{
                                        setOpenexplainer(true);
                                        setOpeninfomation(false);
                                        setOpencalenders(false);
                                        setOpenroles(false);
                                    }}> View the Host Control Guide.</p>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p> Click any of the buttons below to copy a remote class link. <img src={Newinfo} alt="info" onClick={()=> {
                                        setOpeninfomation(false);
                                        setOpenroles(true);
                                        setOpencalenders(false);
                                        setOpenexplainer(false)
                                    }}/></p>
                                </div>
                                <br/>
                                <Grid container spacing={0} className="topspace1">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <CopyToClipboard text={`https://exeltis.reachum.com/view/${id}`} onCopy={() => {
                                            setCopied(true);
                                            setTimeout(() => {
                                                setCopied(false);
                                            }, 3000);
                                        }}>
                                            <button className="newclasslinksbutton"> Guest Link </button>
                                        </CopyToClipboard>
                                        {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <CopyToClipboard text={`https://exeltis.reachum.com/cohost/${id}/${ymeetingid}`}
                                                         onCopy={() => {
                                                             setCcopied(true);
                                                             setTimeout(() => {
                                                                 setCcopied(false);
                                                             }, 3000);
                                                         }}>
                                            <button className="newclasslinksbutton"> CoHost Link </button>
                                        </CopyToClipboard>
                                        {ccopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                </Grid>
                            </>:
                            <div>
                                <p className="sharedropbtn" onClick={()=> setOpeninfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> Remote Meeting Links </span> </p>
                                {openroles ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>What are REACHUM roles?</h5>
                                            <br/>
                                            <p>REACHUM has 3 roles: host, cohost and Guest.<br/>
                                                Hover over the text below to see what each one does.</p>
                                        </div>
                                        <br/>
                                        <Grid container spacing={0} className="topspace1">
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={4} className="cccenter">
                                                <div className="upgradenew20">
                                                    <p>Guest</p>
                                                    <span className="tooltiptext20">Guests have permissions to screenshare without requesting permission each time.</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} className="cccenter">
                                                <div className="upgradenew20">
                                                    <p>CoHost</p>
                                                    <span className="tooltiptext20">Cohosts have the same permissions you do as the meeting host, minus the ability to lock the room. Cohosts can go backwards or forwards in a presentation and silence or delete other users.</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : ""
                                }
                                {openexplainer ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Host Control Guide</h5>
                                            <br/>
                                            <p>Confused on how to use REACHUM Remote? Don’t worry! We got your back.<br/>
                                                Hover over an icon below to see what it does.</p>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew2">
                                                <img src={Layoute} width="50px" alt="layout" className="leftmargin"/>
                                                <span className="tooltiptext2"> Layout button: <br/>  Change the layout of your screen from a video call with the activity window to just a video call. You can also spotlight or remove a spotlight from individual participants by hovering over the upper right hand corner of their window.</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Slidelist} width="50px" alt="slide list" className="leftmargin"/>
                                                <span className="tooltiptext2">Content List button: <br/>
Skip forward or backward in your lesson with a single click. Open your list and click on the desired slide. Then, when you’re done reviewing, click the “next” button to return to the slide after your previous one.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Timer} width="50px" alt="timer button" className="leftmargin"/>
                                                <span className="tooltiptext2">Timer button: <br/>
Instantly start 1 minute timer at any point in your lesson. The timer button is a great classroom management tool that comes in handy for keeping group discussions, writing prompts, and other class activities on track. Click the “next” button to return to the slide after your previous one.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Plays} width="50px" alt="play button" className="leftmargin"/>
                                                <span className="tooltiptext2">Play button: <br/>
Begin a self-embedded video. (Note: Does not work on video links such as linked YouTube videos. To view a YouTube video, click the YouTube play icon in the center of the screen.)
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Syns} width="50px" alt="Sync button" className="leftmargin"/>
                                                <span className="tooltiptext2">Sync button: <br/>
If you manually move forward or backward in a self-embedded video while the video is paused, you’ll see the sync button pop up on your meeting controls. Click the sync button to sync the video playback across all guest devices to your own.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                <span className="tooltiptext2">Back button: <br/> Move to the previous slide in your REACHUM presentation. </span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                <span className="tooltiptext2">Next button: <br/>
Move to the next slide in your REACHUM presentation.
 </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew20">
                                                <img src={Cams} width="50px" alt="cam" className="leftmargin"/>
                                                <span className="tooltiptext20">Cam icon:<br/>
                                                    <ul>
<li>Select a camera other than your computer’s default camera if applicable.</li>
                                                        <li>Background effects: Lightly or strongly blur your background, or replace your background with an image. By default, REACHUM has 6 different background choices, but you can also upload a background image of your own.</li>
    <li>Video settings: Change your default camera, microphone, or speaker, and refine your sound and video settings to your liking. Your video settings give you the option to reduce background noise, prefer HD and/or widescreen video, and mirror your video.</li>
    <li>You can also turn your cam on/off from the Cam icon.</li>
                                                        </ul>
</span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Mics} width="50px" alt="mic" className="leftmargin"/>
                                                <span className="tooltiptext20">Mic icon: <br/>
                                                    <li>Choose your default audio input/output and turn on background noise reduction.</li>
                                                        <li>Audio settings: Change your default camera, microphone, or speaker, and refine your sound and video settings to your liking. Just like your video settings, your audio settings give you the option to reduce background noise, prefer HD and/or widescreen video, and mirror your video.</li>
                                                    <li>You can also turn your cam on/off from the Mic icon.</li>
                                                    </span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Shares} width="50px" alt="share" className="leftmargin"/>
                                                <span className="tooltiptext20">Share icon: <br/>
                                                    <li>Miro whiteboard: Create a miro whiteboard to engage in live collaboration activities such as brainstorming and mind mapping. Instructors can create up to 3 active whiteboards with a free account.</li>
<li>YouTube: Pull up a YouTube video at any time during your lecture by copying and pasting the URL. Click the ellipses in the upper left hand corner and select “Full Screen” to make the video full screen for your class.</li>
                                                   <li>Share screen: Share a tab, window, or your entire screen with your class.</li>
                                                    </span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Records} width="50px" alt="Record" className="leftmargin"/>
                                                <span className="tooltiptext20">Record icon: <br/>
Start a recording of your meeting and export as a video file to share with your class.
</span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Chats} width="50px" alt="chat" className="leftmargin"/>
                                                <span className="tooltiptext20">Chat icon: <br/> Start a text chat with your class. Your participants can also use emojis to react to what you’re saying in real time. The thumbs up emoji can be a way for your learners to say they have no questions and are ready to move on to the next part of your lesson. They can also raise their hand to alert you when they have a question.</span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Peoples} width="50px" alt="people" className="leftmargin"/>
                                                <span className="tooltiptext20">People icon:  <br/>
<li>Breakout Groups: Assign participants to breakout rooms either automatically or manually. The Breakout Groups feature also contains a Shuffle option to automatically redistribute the people in each group.
</li>
                                                        <li>People: See a list of all participants in your room. </li>

                                                    </span>
                                            </div>
                                            <div className="upgradenew20">
                                                <img src={Leaves} width="50px" alt="Leave" className="leftmargin"/>
                                                <span className="tooltiptext20">Leave icon:  <br/> Choose to end the meeting for all participants or leave the room individually while allowing all other participants to remain connected to REACHUM.
</span>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {opencalenders ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Calendar Invite</h5>
                                            <br/>
                                            <p>Choose a role to send an invite for. Then, fill out the rest of the
                                                fields.</p>
                                        </div>
                                        <br/>
                                        <Grid container spacing={0} className="topspace1">
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5} className="cccenter">
                                                <input
                                                    type="radio"
                                                    name="options"
                                                    value="Guest"
                                                    checked={selectedOption === 'Guest'}
                                                    onChange={handleOptionChange}
                                                    className="newinviteradio"
                                                />
                                                <label className="newinviteradiolabel"> Guest </label>
                                            </Grid>
                                            <Grid item xs={5} className="cccenter">
                                                <input
                                                    type="radio"
                                                    name="options"
                                                    value="coHost"
                                                    checked={selectedOption === 'coHost'}
                                                    onChange={handleOptionChange}
                                                    className="newinviteradio"
                                                />
                                                <label className="newinviteradiolabel">
                                                    CoHost
                                                </label>
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                        </Grid>
                                        <div>
                                            {openGuest ?
                                                <Inviteform data={id}/>
                                                : ""
                                            }
                                        </div>
                                        <div>
                                            {opencohost ?
                                                <Invitecoform data={id}/>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        }
                        <br/>
                        <br/>
                    </Tab>
                    <Tab eventKey="share" title="In-Person">
                        <br/>
                        {openipinfomation ?
                            <>
                                <Grid container spacing={0} className="topspace1">
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button onClick={() => {
                                            window.open(`/ihost/${id}/${ymeetingid}`, "_blank");
                                        }} className="newstartbuttoncolor">
                                            Start Meeting
                                        </button>
                                    </Grid>
                                    <Grid item xs={5} className="cccenter">
                                        <button className="newcalendarbuttoncolor" onClick={() => {
                                            setOpenipinfomation(false);
                                            setOpenipcalenders(true);
                                            setOpenipexplainer(false)
                                        }}>Calendar Invite
                                        </button>
                                    </Grid>
                                    <Grid item xs={1} className="cccenter">
                                    </Grid>
                                </Grid>
                                <br/>
                                <div className="cccenter">
                                    <p className="newunderline" onClick={()=>{
                                        setOpenipinfomation(false);
                                        setOpenipcalenders(false);
                                        setOpenipexplainer(true)
                                    }}> View the Host Control Guide.</p>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p> Click the button below to copy the In-Person class link.</p>
                                    <br/>
                                    <Grid container spacing={0} className="topspace1">
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                        <Grid item xs={5} className="cccenter">
                                    <CopyToClipboard text={`https://exeltis.reachum.com/iview/${id}`} onCopy={() => {
                                        setCiopied(true);
                                        setTimeout(() => {
                                            setCiopied(false);
                                        }, 5000);
                                    }}>
                                        <button className="newclasslinksbutton"> Guest Link </button>
                                    </CopyToClipboard>
                                    {icopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <button className="newclasslinksbutton" onClick={openipqrcodeage}>Generate QR Code</button>
                                        </Grid>
                                        <Grid item xs={1} className="cccenter">
                                        </Grid>
                                    </Grid>
                                </div>
                                <br/>
                                <br/>
                            </> :
                            <div>
                                <p className="sharedropbtn" onClick={()=> setOpenipinfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> In-Person Meeting Links </span> </p>
                                {openipcalenders ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Invite Guests</h5>
                                        </div>
                                        <Inviteform1 data={id}/>
                                    </div>
                                    : ""
                                }
                                {openipexplainer ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Host Control Guide</h5>
                                            <br/>
                                            <p>Confused on how to use REACHUM In-Person? Don’t worry! We got your back.<br/>
                                                Hover over an icon below to see what it does.</p>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew2">
                                                <img src={Maxs} width="50px" alt="Max" className="leftmargin"/>
                                                <span className="tooltiptext2">Maximize View button: <br/>
Maximize your screen and bring your lesson into focus.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Slidelist} width="50px" alt="slide list" className="leftmargin"/>
                                                <span className="tooltiptext2">Content List button: <br/>
Skip forward or backward in your lesson with a single click. Open your list and click on the desired slide. Then, when you’re done reviewing, click the “next” button to return to the slide after your previous one.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Qrcodes} width="50px" alt="QR code" className="leftmargin"/>
                                                <span className="tooltiptext2">QR Code button: <br/>
Instantly pull up a QR code at any point in your lesson so users who are late to a class can still participate. Click to toggle between showing and hiding.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Plays} width="50px" alt="play button" className="leftmargin"/>
                                                <span className="tooltiptext2">Play button: <br/>
Begin a self-embedded video. (Note: Does not work on video links such as linked YouTube videos. To view a YouTube video, click the YouTube play icon in the center of the screen.)
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Syns} width="50px" alt="Sync button" className="leftmargin"/>
                                                <span className="tooltiptext2">Sync button: <br/>
If you manually move forward or backward in a self-embedded video while the video is paused, you’ll see the sync button pop up on your meeting controls. Click the sync button to sync the video playback across all Guest devices to your own.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                <span className="tooltiptext2">Back button: <br/> Move to the previous slide in your REACHUM presentation. </span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                <span className="tooltiptext2">Next button: <br/>
Move to the next slide in your REACHUM presentation.
 </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                    : ""
                                }
                            </div>
                        }

                    </Tab>
                    <Tab eventKey="selfstudy" title="Self-Study">
                        <br/>
                        {openssinfomation ?
                            <>
                                <div className="cccenter">
                                    <button className="newcalendarbuttoncolor" onClick={() => {
                                        setOpenssinfomation(false);
                                        setOpenssexplainer(false);
                                        setOpensscalenders(true);
                                    }}>Calendar Invite
                                    </button>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p className="newunderline" onClick={() => {
                                        setOpenssinfomation(false);
                                        setOpenssexplainer(true);
                                        setOpensscalenders(false);
                                    }}> View the Guest Control Guide.</p>
                                </div>
                                <br/>
                                <div className="cccenter">
                                    <p> Click the button below to copy the Self-Study link.</p>
                                    <br/>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} >
                                        </Grid>
                                        <Grid item xs={5}>
                                    <CopyToClipboard text={`https://exeltis.reachum.com/selfStudy/${id}`} onCopy={() => {
                                        setCionopied(true);
                                        setTimeout(() => {
                                            setCionopied(false);
                                        }, 5000);
                                    }}>

                                        <button className="newclasslinksbutton">Guest Link</button>

                                    </CopyToClipboard>
                                    {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <button className="newclasslinksbutton" onClick={openqrcodeage}>Generate QR Code</button>
                                        </Grid>
                                        <Grid item xs={1} >
                                        </Grid>
                                    </Grid>
                                </div>
                            </> :
                            <div>
                                <p className="sharedropbtn" onClick={()=> setOpenssinfomation(true)}><img src={Leftarrow} width="13" height="13" alt="go back to remote class links"/> <span className="roomlistbutton"> Self-Study Link </span> </p>
                                {opensscalenders ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Invite Guests</h5>
                                        </div>
                                        <Inviteondemandform data={id}/>
                                    </div>
                                    : ""
                                }
                                {openssexplainer ?
                                    <div>
                                        <div className="cccenter">
                                            <h5>Guest Control Guide</h5>
                                            <br/>
                                            <p>Confused on how to use REACHUM Self-Study? Don’t worry! We got your back.<br/>
                                                Hover over an icon below to see what it does.</p>
                                        </div>
                                        <br/>
                                        <div className="cccenter">
                                            <div className="upgradenew2">
                                                <img src={Replays} width="50px" alt="replay button" className="leftmargin"/>
                                                <span className="tooltiptext2">Replay button: <br/>
Restart a REACHUM lesson.
</span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Backs} width="50px" alt="back" className="leftmargin"/>
                                                <span className="tooltiptext2">Back button: <br/> Move to the previous slide. </span>
                                            </div>
                                            <div className="upgradenew2">
                                                <img src={Nexts} width="50px" alt="next" className="leftmargin"/>
                                                <span className="tooltiptext2">Next button: <br/>
Move to the next slide.
 </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                    : ""
                                }
                            </div>
                        }
                        <br/>
                        <br/>
                    </Tab>
                </Tabs>
            </ListGroup.Item>

        )
    }
    function thiszoomin(){
        setIsopen(false);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "141px";
            element.style.height = "120px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
    }
    function thiszoomout(){
        setIsopen(true);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "235px";
            element.style.height = "200px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
    }
    class SmartSensor extends PointerSensor {
        static activators = [
            {
                eventName: 'onPointerDown',
                handler: ({ nativeEvent }) => {
                    if (nativeEvent?.target?.tagName?.toLowerCase() === 'input') {
                        return false
                    }
                    return true
                }
            }
        ]
    }
    const sensors = useSensors(
        useSensor(SmartSensor, {
            activationConstraint: {
                distance: 5
            }
        }),
    );
    const [movedIds, setMovedIds] = useState([])
    const [moveTimeout, setMoveTimeout] = useState()
    const [selectedIds, setSelectedIds] = useState([])
    function selectId (id) {
        setSelectedIds(current => {
            return [...current, id]
        })
    }
    function toggleSelect(id) {
        setSelectedIds(current => {
            const selected = current.includes(id)
            if (selected) {
                return current.filter(currentId => currentId !== id)
            } else {
                return [...current, id]
            }
        })
    }
    const itemViews = playcontents.map((itm, index) => {
        function handleMouseDown() {
            toggleSelect(itm.rid)
        }
        const selected = selectedIds.includes(itm.rid);
        const moved = movedIds.includes(itm.rid);
        return (
            <SortableItem
                key={itm.rid}
                id={itm.rid}
                selected={selected}
                moved={moved}
            >
                <div className="lialist">
                    <Grid container spacing={0} className="topedit">
                        <Grid item xs={3} className="leftside">
                            <div className="upgradenew2" onMouseDown={handleMouseDown}>
                                <img src={Allmove} width="12" height="12" className="movethis"/>
                                <span className="editspan">{Number(index) + Number(1)}</span>
                                <span className="tooltiptext2">Choose the items you want to rearrange, and then drag them to reorder.</span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            {!itm.adobeprojectid ?
                                <div className="upgradenew2">
                                    <img src={Editb} onClick={() => {
                                        setEurls(itm.eurl);
                                        setShow(true);
                                    }} width="18" height="18"/>
                                    <span className="tooltiptext2">Click here to edit the content.</span>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={4} className="leftside">
                            <CopyToClipboard text={itm.rurl}>
                                <div className="upgradenew1">
                                    <img src={Copys} width="18" height="18" />
                                    <span className="tooltiptext1">Click to copy link</span>
                                </div>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item xs={2} className="rightside">
                            <div className="upgradenew1">
                                <img src={DeleteIcon} width="18" height="18" onClick={() => {
                                    handleDelete(itm.rid);
                                }} />
                                <span className="tooltiptext1">Click to delete</span>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="imgslist">
                        <div className="upgradenew1">
                            <div className="imgslist">
                                <img src={Noi} className="editnoi" onClick={() => {
                                    setPurls(itm.rurl);
                                    setIndexs(index);
                                    setTitless(itm.title);
                                    setShow2(true);
                                }} />
                                <iframe src={itm.rurl} scrolling="no" className="editsframelook" loading="lazy"></iframe>
                            </div>
                            <span className="tooltiptext1">Click to preview</span>
                        </div>
                    </div>
                    <input
                        value={itm.title}
                        onChange={(e) => {
                            handleChange(e, itm.rid);
                            if (e.keyCode === 13){
                                handleSubmit();
                            }
                        }}
                        name="title"
                        placeholder="title"
                        className="editroomlist"
                    />
                </div>
            </SortableItem>
        )
    })
    const selectedItems = playcontents.filter(item => {
        return selectedIds.includes(item.rid)
    })
    const overlayViews = selectedItems.map((item,index) => {
        return (
            <SortableItem
                key={item.rid}
                id={item.rid}
                selected
                moved={false}
            >
                <div className="centerthis">
                    <br/>
                    <br/>
                    <p>{item.title}</p>
                </div>
            </SortableItem>
        )
    })
    function handleDragStart ({ active }) {
        selectId(active.id)
    }
    function handleDragEnd(event) {
        const { active, over } = event;
        setPlaycontents((current) => {
            const overSelected = selectedIds.includes(over.id)
            if (overSelected) {
                const withoutSelectedExceptOver = current.filter(item => {
                    const selected = selectedIds.includes(item.rid)
                    if (!selected) {
                        return true
                    }
                    const overItem = item.rid === over.id
                    if (overItem) {
                        return true
                    }
                    return false
                })
                const newOverIndex = withoutSelectedExceptOver.findIndex(item => item.rid === over.id)
                const beforeNewOver = withoutSelectedExceptOver.slice(0, newOverIndex)
                const afterNewOver = withoutSelectedExceptOver.slice(newOverIndex + 1)
                const selectedItems = current.filter(item => {
                    return selectedIds.includes(item.rid)
                })
                const newItems = [...beforeNewOver, ...selectedItems, ...afterNewOver]
                return newItems
            } else {
                const activeIndex = current.findIndex(item => item.rid === active.id)
                const overIndex = current.findIndex(item => item.rid === over.id)
                const forward = overIndex > activeIndex
                const selectedItems = current.filter(item => {
                    return selectedIds.includes(item.rid)
                })
                const newWithout = current.filter(item => {
                    return !selectedIds.includes(item.rid)
                })
                const newOverIndex = newWithout.findIndex(item => item.rid === over.id)
                const overFirst = newOverIndex === 0
                if (overFirst && !forward) {
                    return [...selectedItems, ...newWithout]
                }
                const offset = forward ? 1 : 0
                const beforeNew = newWithout.slice(0, newOverIndex + offset)
                const afterNew = newWithout.slice(newOverIndex + offset)
                const newArray = [...beforeNew, ...selectedItems, ...afterNew]
                return newArray
            }
        });
        setMovedIds(selectedIds)
        if (moveTimeout != null) {
            clearTimeout(moveTimeout)
        }
        const newMoveTimeout = setTimeout(() => {
            setMovedIds([])
        }, 2000)
        setMoveTimeout(newMoveTimeout)
        setSelectedIds([])
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsFixed(scrollTop > 450); // Adjust this value based on when you want the div to become fixed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        isOwner === isUser ?
        <>
            <Helmet>
                <title>Content Manager</title>
            </Helmet>
            <Navbars />
            <div className="editroom">
                <div className="input-container">
                    <input type="text" value={ymeeting} onChange={changeroomname} className="editroomnamebig" /><img src={Editbs} className="inputicon" width="28px"/>
                </div>
                <br/>
                    <div className="saves">
                        <Grid container spacing={0} className="topspace1">
                            <Grid item xs={3}>
                                <h5 className="sharedropbtn" onClick={gobackthis}><img src={Leftarrow} width="13" height="13" /> Room List </h5>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                                <span className="editcontents">Content Manager</span>
                            </Grid>
                            <Grid item xs={3} className="cccenter">
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3}>
                                <div className="upgradenew2">
                                <button
                                     onClick={()=>{
                                         history.push(`/rehearse/${id}`);
                                     }} className="rehearsebutton">Rehearse</button>
                                    <span className="tooltiptext2">Rehearse any meeting with full privacy.</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                                {duplicateopen ?
                                    <>
                                        <select id="mySelect" name="meetingname">
                                            <option> Room to Duplicate </option>
                                            {rendercondata()}
                                        </select>
                                        <div className="upgradenew2">
                                            <img src={Duplicate} onClick={myFunction} className="dropbtn" />
                                            <span className="tooltiptext2">Duplicate to this room</span>
                                        </div>
                                    </>
                                    :""
                                }
                            </Grid>
                            <Grid item xs={3} className="cccenter">

                            </Grid>
                        </Grid>
                        <div className={isFixed ? 'fixed-divedit' : ''}>
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3} className="cccenter">
                                <button onClick={() => {
                                    setShow3(true);
                                }} className="golivebutton">Meeting Links</button>
                            </Grid>
                            <Grid item xs={6} className="centerside">

                            </Grid>
                            <Grid item xs={3} className="cccenter">
                                <button className="addcontentbtn" onClick={()=>setShow1(true)}> Add Content</button>
                            </Grid>
                        </Grid>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="saveseditchage">
                            <div className={isFixed ? 'fixed-diveditsave' : ''}>
                            <Grid container spacing={0} className="topspace">
                                <Grid item xs={3} className="cccenter">
                                </Grid>
                                <Grid item xs={6} className="cccenter">
                                    <span className="redthis">{changesavedmessage} </span><input type="submit" value="Save Changes" className="inputsavechanges" />
                                </Grid>
                                <Grid item xs={3} className="cccenter">
                                    <div className="zooms">
                                        {isopen ?
                                            <img src={Zoomin} onClick={thiszoomin} width="32" height="32"/>
                                            :
                                            <img src={Zoomout} onClick={thiszoomout} width="32" height="32"/>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        </div>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <SortableContext items={playcontents}>
                                {itemViews}
                            </SortableContext>
                            <DragOverlay>
                                {overlayViews}
                            </DragOverlay>
                        </DndContext>
                    <br/>
            </Form>
            </div>
            <Modal show={show1} onHide={handleClose1} aria-labelledby="contained-modal-title-vcenter" size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Add Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Menus id={id} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{indexs+1}. {titless}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {purls !== "" && (
                        <iframe
                            src={purls}
                            loading="lazy"
                            width="100%"
                            height="700px"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen,autoplay"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {eurls !== "" && (
                        <iframe
                            src={eurls}
                            loading="lazy"
                            width="100%"
                            height="700px"
                            title="maincontents"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    {ymeeting} - Meeting Links
                </Modal.Header>
                <Modal.Body>
                    {renderDatalinks()}
                </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={handleClose4} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    Calendar Invite
                </Modal.Header>
                <Modal.Body>
                    <Inviteform data={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show6} onHide={handleClose6} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    Calendar Invite
                </Modal.Header>
                <Modal.Body>
                    <Invitecoform data={id}/>
                </Modal.Body>
            </Modal>
            <Foot />
        </>
            :""
    );
}
export default Editroom;

