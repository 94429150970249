import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listPlayslides} from '../../graphql/queries';
import {useHistory,useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "../css/morereportrooms.css";
import {Helmet} from "react-helmet";
import Navbars from "../Navbar";
import Foot from "../Foot";
import Login from "../../pages/login";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import Leftarrow from "../../img/left-arrow.png";
var XLSX = require("xlsx");


function Morereports() {
    const { id } = useParams();
    const history = useHistory();
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    const [thisalldatas, setThisalldatas] = useState([]);
    const [openthis, setOpenthis]= useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errormessage, setErrormessage] = useState("")
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            try{
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: iowner
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listPlayslides.items;
                const dataslength = datas.length;
            if(dataslength > 0){
                setThisdatas(datas)
            }
        }catch(err){
            console.log(err)
        }
        })()
    }, []);
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    async function getdatas() {
        if (endDate > startDate) {
            const filteredDates = thisdatas.filter(item => {
                const updatedAtDate = new Date(item.createdAt);
                return updatedAtDate >= startDate && updatedAtDate <= endDate;
            });
            const filteredIds = filteredDates.map(item => ({
                id: item.id,
                meetingname: item.meetingname
            }));
            const u = [];
            for (let i = 0; i < filteredIds.length; i++) {
                const thisid = filteredIds[i].value;
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: thisid
                            }
                        },
                        limit: 10000
                    }
                })
                const datas = response.data.listLeaderboards.items;
                u.push(datas)
            }
            const aggregatedData = {};
            u.forEach(nestedArray => {
                nestedArray.forEach(item => {
                    const {score, name} = item;
                    if (!aggregatedData[name]) {
                        aggregatedData[name] = {score};
                    } else {
                        aggregatedData[name].score += score;
                    }
                });
            });
            const resultArray = Object.keys(aggregatedData).map(name => ({
                name,
                score: aggregatedData[name].score
            }));
            const scounts = [];
            const dcounts = [];
            resultArray.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setThisalldatas(dcounts);
            setOpenthis(true)
        }
    }
    const renderleaderboard =()=>{
        return thisalldatas.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.score}</td>
                </tr>:""
        ))
    }
    const downloadFile=()=>{
        const rows = thisalldatas.map(row => ({
            name: row.name,
            score: row.score
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");
        XLSX.utils.sheet_add_aoa(worksheet, [["Player Name", "Score"]], { origin: "A1" });
        XLSX.writeFile(workbook,  "REACHUM - leaderboard.xlsx", { compression: true });
    }
    function gobackthis(){
        history.push(`/hostleaderboard/${id}`);
    }
    return (
        <>
            <Helmet>
                <title>Leaderboard - All Rooms</title>
            </Helmet>
            <>
                <Navbars/>
                {!isUser ?
                    <Login/>
                    : (
                        <>
                            <div className="leaderpage">
                                <div className="fastviewleader">
                                    <br/>
                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <h5 className="sharedropbtn" onClick={gobackthis}><img src={Leftarrow} width="13" height="13" /> Leaderboard </h5>
                                        </Grid>
                                        <Grid item xs={4} className="cccenter">
                                            <h3>Leaderboard By Date</h3>
                                        </Grid>
                                        <Grid item xs={4} className="cccenter">
                                            {openthis ?
                                                <button onClick={downloadFile} className="savespread">Save as
                                                    Spreadsheet</button>
                                                : ""
                                            }
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <div>
                                        <Grid container spacing={0} className="topspace1">
                                            <Grid item xs={1} className="cccenter">
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <p>Display From: </p>
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="MM/dd/yyyy"
                                                    className="dateinput"
                                                />
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <p>Display To: </p>
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="MM/dd/yyyy"
                                                    className="dateinput"
                                                />
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <button onClick={getdatas} className="answersubmit">Search</button>
                                            </Grid>
                                            <Grid item xs={1} className="cccenter">
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <p className="redthis">{errormessage}</p>
                                        <br/>
                                    </div>
                                    <br/>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Player Name</th>
                                            <th>Score</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderleaderboard()}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </>)
                }
                <Foot />
            </>
        </>
    )
}

export default Morereports;
