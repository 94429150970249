import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listEngagements} from '../../graphql/queries';
import {useHistory,useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import Deleteroom from "../../img/dash/Delete-Button-X.png";
import {deleteLeaderboard, deleteEngagement} from "../../graphql/mutations";
import Navbars from "../Navbar";
import Login from "../../pages/login";
import Foot from "../Foot";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../img/left-arrow.png";
var XLSX = require("xlsx");

function Hostleaderboard() {
    const [myData, setMyData] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    const [roomname, setRoomname] = useState("");
    const [engagenumber, setEngagenumber] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const myroomname =datas.meetingname;
            setRoomname(myroomname);
        })()
        fetchDas();
        fetchengagedata()
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    async function fetchengagedata() {
        const peoples = [];
        const engages = [];
        try {
            const response = await API.graphql({
                query: listEngagements,
                variables: {
                    filter: {
                        "questionid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listEngagements.items;
            if(datas.length > 0) {
                datas.forEach((data) => {
                    const listdata = data.people;
                    peoples.push(listdata)
                })
                const s = peoples.length;
                datas.forEach((data) => {
                    const listdata = data.engage;
                    engages.push(listdata)
                })
                const sum = engages.reduce((partialSum, a) => partialSum + a, 0);
                const numObj = (sum / s) * 100;
                const ea = Number.parseFloat(numObj).toFixed(1) + "%"
                setEngagenumber(ea)
            }else{
                const tt = "100%";
                setEngagenumber(tt)
            }

        }catch(err){
            console.log(err)
        }
    }
    const handleDeleteperson = async (itemId) => {
        let text = "Are you sure you want to delete this player? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deleteLeaderboard,
                variables: {input}
            });
            window.location.reload();
        }
    };
    const renderleaderboard =()=>{
        return myData.map((item, index) => (
            isOwner === isUser ?
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.score}</td>
                <td><img src={Deleteroom} onClick={()=> {handleDeleteperson(item.id)}} className="dropbtn" alt="delete a person"/></td>
            </tr>:""
        ))
    }
    const downloadFile=()=>{
        const rows = myData.map(row => ({
            name: row.name,
            score: row.score
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");
        XLSX.utils.sheet_add_aoa(worksheet, [["Player", "Score"]], { origin: "A1" });
        XLSX.writeFile(workbook, `${roomname} - leaderboard.xlsx`, { compression: true });
    }
    const clearresults = async ()=>{
        let text = "Are you sure you want to delete all players? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = thisdatas.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: thisdatas[i].id
                    };
                    await API.graphql({
                        query: deleteLeaderboard,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listEngagements.items;
                const datalength = datas.length;
                for(var j=0; j<datalength; j++) {
                    const input = {
                        id: datas[j].id
                    };
                    await API.graphql({
                        query: deleteEngagement,
                        variables: {input}
                    });
                }
            }catch(err){
                console.log(err)
            }
            window.location.reload();
        }
    }
    function gobackthis(){
        history.push(`/`);
    }
    function gomorereports(){
        history.push(`/morereports/${id}`);
    }
    return (
        <>
        <Helmet>
            <title>Leaderboard</title>
        </Helmet>
        <>
            <Navbars/>
            {!isUser ?
                <Login/>
                : (
                    <>
                        <div className="leaderpage">
                            <div className="fastviewleader">
                                <h3>{roomname}</h3>
                                <h5>Leaderboard</h5>
                                <Grid container spacing={0}>
                                    <Grid item xs={4} className="leftsidethis">
                                        <h5 className="sharedropbtn" onClick={gobackthis}><img src={Leftarrow} width="13" height="13" /> Room List </h5>
                                    </Grid>
                                    <Grid item xs={4} className="cccenter">
                                        <p>The results below show the aggregate score<br/>
                                            for all the games in the room.</p>
                                    </Grid>
                                    <Grid item xs={4} className="rightside">
                                        <button onClick={downloadFile} className="savespreadthis">Save as Spreadsheet</button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={4} className="leftsidethis">
                                        <button onClick={clearresults} className="clearresub">Clear Results</button>
                                    </Grid>
                                    <Grid item xs={4} className="cccenter">
                                        Response Rate: {engagenumber}
                                    </Grid>
                                    <Grid item xs={4} className="rightside">
                                        <button onClick={gomorereports} className="morereportgames">Leaderboard By Date</button>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Player</th>
                                        <th>Score</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderleaderboard()}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </>)
            }
            <Foot />
        </>
            </>
    )
}

export default Hostleaderboard;
