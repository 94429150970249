import { Storage, API } from 'aws-amplify';
import { createPptxFile, updatePptxFile} from '../../../graphql/mutations';
import convertPptxToJpeg from './convertPptxToJpeg';
import uploadFromUrlToS3 from './uploadFromUrlToS3';

const Status = {
  UPLOADING: 'UPLOADING',
  CONVERTING: 'CONVERTING',
  READY: 'READY',
  ERROR: 'ERROR',
}

async function createFile(file) {
  console.log('creating file...');
  const fileName = file.name;
  const createFile = { name: fileName, status: Status.UPLOADING };
  const { data } = await API.graphql({
    query: createPptxFile,
    variables: { input: createFile }
  });
  const { createPptxFile: createdFile } = data;
  return createdFile;
}

async function uploadFile(id, file) {
  console.log('uploading file...');
  const fileName = file.name;
  const { key } = await Storage.put(fileName, file);
  const uploadedFile = await Storage.get(key);
  const updateFile = { id, uploadedFile, status: Status.CONVERTING };
  const { data } = await API.graphql({
    query: updatePptxFile,
    variables: { input: updateFile }
  });
  const { updatePptxFile: updatedFile } = data;
  return updatedFile;
}

async function processConversion({ id, uploadedFile }) {
  console.log('processing file...');
  const results = await convertPptxToJpeg(uploadedFile);

  const uploadPromises = results.map(async ({ name, url }) => {
    const s3Url = await uploadFromUrlToS3(url, name);
    return {
      name,
      location: s3Url,
    }
  });
  const images = await Promise.all(uploadPromises);

  const updateFile = { id, images, status: Status.READY };
  const { data } = await API.graphql({
    query: updatePptxFile,
    variables: { input: updateFile }
  });
  const { updatePptxFile: updatedFile } = data;
  console.log('processing file... done!');
  return updatedFile;
}

export async function processFile(file) {
  const createdFile = await createFile(file);
  const updatedFile = await uploadFile(createdFile.id, file);
  return processConversion(updatedFile);
}
