import React from "react";
import "./css/tutorialpage.css";
import {Helmet} from "react-helmet";
import Navbars from "../components/Navbar";
import Foot from "../components/Foot";

function Tutorials() {
    return (
        <>
            <Helmet>
                <title>Tutorials</title>
            </Helmet>
            <Navbars/>
            <div className="tutorialpage">
                <h2>Tutorials</h2>
                <iframe className="youtubevideos"
                        src="https://www.youtube.com/embed/mSpWTl8gmsI?list=PLmdMg3-cmgnjRycuE1dAh74nAVe7wcP0B&rel=0"
                        title="Creating a Room" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <Foot/>
        </>
    );
}

export default Tutorials;
