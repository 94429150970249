import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import logo from '../Black.png';

function Rform() {
    const history = useHistory();
    const { id } = useParams();
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    })
    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            localStorage.setItem("vreachumnewname", ssname);
            history.push(`/view/${id}`);
        }catch(err){
            console.log(err)
        }
    }
    function thisprivacy(){
        history.push("/termandConditions");
    }
    return (
            <>
                        <div className="enterfirstyournameform">
                            <img src={logo} alt="Reachum" className="logos" />
                            <Form className="market-header">
                                <p>Please fill out the fields below to track participation during activities and events. </p>
                                <br/>
                                <h5>Your Full Name</h5>
                                <Form.Item>
                                    <Input
                                        type="text"
                                        value={stateData.meetname}
                                        onChange={meetname => setStateData({
                                            ...stateData,
                                            meetname: meetname
                                        })}
                                        maxLength={80}
                                        placeholder="Full Name"
                                        className="craeteroominput"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={6} className="rightside">
                                            <Button
                                                disabled={!stateData.meetname}
                                                onClick={handleSubmit2}
                                                size="lg"
                                                className="ibuttnsform"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form.Item>
                            </Form>
                            <p className="privacytext">
                                We respect your privacy. Data is stored for game and progress tracking only. REACHUM does not share information with third parties. Read our full privacy policy <span
                                className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                        </div>

        </>
    );
}
export default Rform;
