import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {useHistory} from "react-router-dom";
import { getPlayslide } from "../../../graphql/queries";
import {Button, Modal} from 'react-bootstrap';
import "../../css/uploadimg.css";
import Uploadvideoques from "./vid/uploadvideoques"

function Videoquizfile({id}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [showuploadvideo, setShowuploadvideo] = useState(false);
    const Closeuploadvideo = () => {setShowuploadvideo(false)};
    const history = useHistory();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);

    function goupload(){
        setShowuploadvideo(true)
    }
    function gorecord(){
        history.push(`/videorecord/${id}`);
    }
    return (
        isOwner === isUser ?
            <>
                <div className="uploadpptx">
                    <div className="centerthis">
                        <h3 className="greenthis">Video Quiz</h3>
                    </div>
                    <div className="text-center">
                        <div className="thiscenter">
                            <p>One question for each video</p>
                        </div>
                    <Button className="mr-4" onClick={goupload}>Upload a video file</Button>
                    <Button className="mr-4" onClick={gorecord}>Record a video</Button>
                </div>
                </div>
                <Modal show={showuploadvideo} onHide={Closeuploadvideo} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Uploadvideoques id={id}/>
                    </Modal.Body>
                </Modal>
            </>
            :""
    );
}

export default Videoquizfile;
