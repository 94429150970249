import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {useParams,useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import Newwindow from "../../img/host/Layout.png";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";

function Room() {
    const [curl, setCurl] = useState("");
    const [vurl, setVurl] = useState("");
    const [lsize, setLsize] = useState(3);
    const [rsize, setRsize] = useState(9);
    const { id } = useParams();
    const history = useHistory();
    const [showthis, setShowthis] = useState(false);
    useEffect(() => {
        const reachumnname = localStorage.getItem("vreachumnewname");
        if(!reachumnname){
            history.push(`/rform/${id}`);
        }
    }, []);
    useEffect(() => {
        if (isMobile) {
            history.push(`/view/mc/${id}`)
        }
    }, [])
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            const yourusername = localStorage.getItem("vreachumnewname");
            const cc= `${datas.clientrlink}?embed&minimal&chat=on&screenshare=on&people=off&logo=on&displayName=${yourusername}`;
            setCurl(datas.clink);
            setVurl(cc);
            const fuls = datas.fulls;
            if(fuls === true){
                setLsize(12);
                setRsize(12)
            }else {
                setLsize(3);
                setRsize(9)
            }
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const v = datas.aplaypause;
            if(v === true){
                const thetimer = document.getElementById('theFrame');
                thetimer.src =  "/timer";
            }else{
                setCurl(datas.clink);
            }
            const fuls = datas.fulls;
            if(fuls === true){
                setLsize(12);
                setRsize(12)
            }else{
                setLsize(3);
                setRsize(9)
            }
            const isplay = datas.vplaypause;
            if(isplay === true){
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
            }else{
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
            }
        }catch(error){
            console.log("hi")
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    const closeNavs = () => {
        document.getElementById("myNavs").style.width = "0%";
    }
    useHotkeys('/', () => {
        infobuttons()
    })
    function ends(){
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={lsize}>
                            {vurl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="leftsid"
                                    src={vurl}
                                    loading="lazy"
                                    width="100%"
                                    height="100vh"
                                    title="clienturl"
                                    frameBorder="0"
                                    allow="camera; microphone; fullscreen; speaker; display-capture"
                                />
                            )}
                        </Grid>
                        <Grid item xs={rsize}>
                            {curl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    width="100%"
                                    height="100vh"
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className="mmbottoms">
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={7} className="leftside">
                            <div className="upgradenew3">
                            <img src={Newwindow} onClick={infobuttons} className="viewbimg"/>
                                <span className="tooltiptext3">Screen Layout (or / key)</span>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                                <img src={Ending} onClick={ends} className="viewbimg"/>
                        </Grid>
                    </Grid>
                </div>
                    <div id="myNavs" className="overlay">
                        <div className="closefull">
                            <div className="upgradenew">
                            <p onClick={closeNavs}>Close</p>
                            <span className="tooltiptext">Click Close</span>
                            </div>
                        </div>
                        <iframe
                            className="tutorialiframe"
                            id="theFrame"
                            src={curl}
                            loading="lazy"
                            frameBorder="0"
                            allow="autoplay;fullscreen;payment"
                        />
                    </div>
            </>
    );
}
export default Room;
