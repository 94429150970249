import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import Exeltislogo from "../img/LMS-logos-02.png";
import '@aws-amplify/ui-react/styles.css';
import "./css/login.css";


const Login = () => {
    return (
        <>
            <br/>
            <div className="cccenter">
            <img src={Exeltislogo} width="120" alt="Exeltis"/>
            </div>
            <Authenticator loginMechanisms={['email']} hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Login
