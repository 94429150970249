import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createFasteranswer, updatePlayslide} from '../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Table} from "react-bootstrap";
import {getPlayslide} from "../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../../css/poll.css";
import Grid from "@material-ui/core/Grid";

function Fastanswer({id, handleClose55}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [ftimers, setFtimers] = useState(0);
	const [showtimerform, setShowtimerform] = useState(false);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);
	const {register, handleSubmit} = useForm({});
	const reorderurl = `https://exeltis.reachum.com/fasteranswerroom/${id}/${d}`;
	const erurl = `https://exeltis.reachum.com/fastansweredit/${id}/${d}`;
	const ileaderboardurl = `https://exeltis.reachum.com/leaderboard/${id}`;
	const canser = `https://exeltis.reachum.com/fastercorrctanswer/${id}/${d}`;
	const tt = `${d}1`;
	const cc = `${d}333`;
	const onSubmit = async (data) => {
		try {
			const input = {
				id:d,
				ffname:data.ffname,
				ftimer:ftimers,
				fquestion:data.fquestion,
				fanswer:data.fanswer
			}
			await API.graphql({
				query: createFasteranswer,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.ffname,
				rurl: reorderurl,
				eurl:erurl
			};
			const leaderboard = {
				rid:tt,
				title:"leaderboard",
				rurl:ileaderboardurl
			}
			const correcrur = {
				rid: cc,
				title:"correct answer",
				rurl: canser
			}
			const allImages = [...images, urldata, correcrur, leaderboard]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setOpensmessage(false);
			setSmessage("Submitted");
			setTimeout(() => {
				handleClose55()
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="orderlist">
				<div className="thiscenter">
				<h3>Fill in the Blank</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Game name : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="ffname"
					defaultValue=""
					ref={register({ required: true, maxLength:60 })}
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Timer : &nbsp;
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<div className="labelinlinetimer">
								<label>
									<input
										type="radio"
										name="timer"
										value="AddTimer"
										onChange={()=>{setShowtimerform(true)}}
										className="biggerradiotimer"
									/>
									Add Timer (Seconds) &nbsp;
								</label>
							{showtimerform ?
								<input
									name="ftimer"
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
							</div>
						</Grid>
						<Grid item xs={5} className="cccenter">
							<label>
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} className="biggerradiotimer" /> No Timer
							</label>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="fquestion"
					defaultValue=""
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Correct answer: &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="fanswer"
					defaultValue=""
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Table>
				<tbody>
				<tr><td>
					</td>
				<td className="orderright">
					{opensmessage ?
						<input type="submit" value="Submit" className="answersubmit"/>
						:
						<p className="redthis"> {smessage} </p>
					}
				</td>
				</tr>
				</tbody>
			</Table>
		</form>
			</div>
			</>
			:""
	);
}
export default Fastanswer;
