import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import {updatePlayslide} from "../../graphql/mutations";
import {useHistory,useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import "../css/preview.css";
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import {Helmet} from "react-helmet";
import Ending from "../../img/host/Exit-Button.png";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import Replaybutton from "./replay.png"

function SelfStudy() {
    const history = useHistory();
    const [urls, setUrls] = useState([]);
    const [count, setCount] = useState(0);
    const [showthis, setShowthis] = useState(true);
    const [totalslide, setTotalslide] = useState(1);
    const [currentslide, setCurrentslide] = useState(1);
    const [openthisbutton, setOpenthisbutton] = useState(true)
    const [stateData, setStateData] = useState({
        meetname:""
    })
    const { id } = useParams();
    useEffect(() => {
        (async () => {
            const input = {
                id,
                exiton: true
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        const reachumnname = localStorage.getItem("vreachumnewname");
        if(reachumnname){
            setShowthis(false)
        }
    }, []);
    useEffect(() => {
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });
            const datas = response.data.getPlayslide;
            const h = datas.playlistlinks;
            let i = h.length;
            setTotalslide(i);
            if (count < i) {
                setUrls(h[count].rurl)
                setCurrentslide(count + 1)
            }else if(count >= i){
                setOpenthisbutton(false)
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);

    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            localStorage.setItem("vreachumnewname", ssname);
            setShowthis(false)
        }catch(err){
            console.log(err)
        }
    }
    function ends(){
        history.push(`/`);
    }
    function handlereplay(){
        window.location.reload()
   }
    return (
        <>
            <Helmet>
                <title>Self-Study</title>
            </Helmet>
        <div>
                    {urls !== "" && (
                        <iframe
                            className="preiframe"
                            id="theFrame"
                            src={urls}
                            loading="lazy"
                            width="100%"
                            height="100vh"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                <Grid container spacing={0} className="bottoms">
                    <Grid item xs={2} className="rightside">
                        {openthisbutton ?
                            <p className="greenselfthis">{currentslide} / {totalslide}</p>
                            :
                            <div className="upgradenew3" id="3">
                                <img src={Replaybutton} onClick={handlereplay} className="mimg" alt="Replay"/>
                                <span className="tooltiptext3">Restart</span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={8} className="centerside">
                                    <img src={Left} className="mimg" onClick={() => {
                                        setCount(count - 1);
                                        if (count === 0) {
                                            setCount(0)
                                        }
                                    }} alt="left"/>
                                     &nbsp;
                                    <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="right" />
                    </Grid>
                    <Grid item xs={2}>
                        <div className="endmeetingb">
                            <img src={Ending} onClick={ends} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            {showthis ?
                <Modal
                    size="sm"
                    show={showthis}
                    centered
                >
                    <Modal.Body>
                        <div className="enteryournameform">
                            <Form className="market-header">
                                <br/>
                                <h5>Your Full Name</h5>
                                <Form.Item>
                                    <Input
                                        type="text"
                                        value={stateData.meetname}
                                        onChange={meetname => setStateData({
                                            ...stateData,
                                            meetname: meetname
                                        })}
                                        maxLength={80}
                                        placeholder="Full Name"
                                        className="craeteroominput"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={6} className="rightside">
                                            <Button
                                                disabled={!stateData.meetname}
                                                onClick={handleSubmit2}
                                                size="lg"
                                                className="ibuttnsform"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
                : ""
            }
            </>
    );
}
export default SelfStudy;
