import React, {useEffect, useState} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./inviteform.css";
import {API} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import {Button} from 'react-bootstrap';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Grid from "@material-ui/core/Grid";
import "react-datepicker/dist/react-datepicker.css";


function Invitecoform(props) {
    const  id  = props.data;
    const [ymeeting, setYmeeting] = useState();
    const [isopen, setIsopen] = useState(true);
    const [thisdes, setThisdes] = useState("");
    const [thismessage, setThismessage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [startDated, setStartDated] = useState(new Date());
    const [thistimezoons, setThistimezoons]= useState("");
    const [thislocation, setThislocation] = useState();
    const [startDates, setStartDates] = useState(new Date());
    const [startDatess, setStartDatess] = useState(new Date());
    const [startDateee, setStartDateee] = useState(new Date());
    const [startDatee, setStartDatee] = useState(new Date());

    useEffect(() => {
        fetchData()
        const thistimezoon = moment.tz.guess();
        setThistimezoons(thistimezoon);
    }, []);

    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const emid = datas.mid;
        const thisurls = `https://exeltis.reachum.com/cohost/${id}/${emid}`;
        setThislocation(thisurls);
    }
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const formattedDate = formatDate(startDate);
    function compareTimes(time1, time2) {
        const [hours1, minutes1] = time1.split(':');
        const [hours2, minutes2] = time2.split(':');

        const date1 = new Date(0, 0, 0, hours1, minutes1);
        const date2 = new Date(0, 0, 0, hours2, minutes2);

        if (date1 < date2) {
            return -1; // time1 is earlier
        } else if (date1 > date2) {
            return 1; // time2 is earlier
        } else {
            return 0; // times are equal
        }
    }
    function converttimes(){
        setStartDated(formattedDate);
        setStartDatess(currentTimeFormatted)
        setStartDateee(currentTimeFormattede);
        const comparisonResult = compareTimes(currentTimeFormatted, currentTimeFormattede);
        if (comparisonResult <= 0) {
            setThismessage("");
            setIsopen(false)
        }else{
            setThismessage("End time is earlier than Start time.")
        }
    }
    function getCurrentTime() {
        const currentTime = startDates;
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        return formattedTime;
    }

    const currentTimeFormatted = getCurrentTime();
    function getCurrentTimee() {
        const currentTime = startDatee;
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        return formattedTime;
    }
    const currentTimeFormattede = getCurrentTimee();
    return (
        <div className="inviteformpage">
            <div className="invitforms">
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <p>Title:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p className="ques">{ymeeting}</p>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={1}>
                        <p className="inviteformtext">Date:</p>
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            strictParsing
                        />
                    </Grid>
                    <Grid item xs={2} className="centerthis">
                        <p className="inviteformtext">Start Time:</p>
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            selected={startDates}
                            onChange={(date) => setStartDates(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Start Time"
                            dateFormat="h:mm aa"
                        />
                    </Grid>
                    <Grid item xs={2} className="centerthis">
                        <p className="inviteformtext">End Time:</p>
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            selected={startDatee}
                            onChange={(date) => setStartDatee(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="End Time"
                            dateFormat="h:mm aa"
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <p>Description:</p>
                    </Grid>
                    <Grid item xs={9}>
                             <textarea
                                 name="discription"
                                 value={thisdes}
                                 onChange={(e) => {
                                     setThisdes(e.target.value)
                                 }
                                 }
                                 rows="2"
                                 cols="45"
                                 placeholder="Description"
                             />
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={5}>
                        {thismessage}
                    </Grid>
                    <Grid item xs={7}>
                        {isopen ?
                            <Button onClick={converttimes} variant="success">Add to Calendar</Button>
                            :
                            <add-to-calendar-button
                                name={ymeeting}
                                location={thislocation}
                                description={thisdes}
                                startDate={startDated}
                                startTime={startDatess}
                                endTime={startDateee}
                                timeZone={thistimezoons}
                                options="'Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
                                label="Choose your calendar"
                                listStyle="overlay"
                                lightMode="bodyScheme"
                                trigger="click"
                            ></add-to-calendar-button>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );

}

export default Invitecoform;
