/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qpzlgnukrbb5dljmr3d3qwzzui.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sbm5i6alnvgt5ekvuld6rnrqgq",
    "aws_cloud_logic_custom": [
        {
            "name": "exeltiswhereby",
            "endpoint": "https://w9f7l1itf1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "exeltisinviteguest",
            "endpoint": "https://gpx1mmqlpb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:efc6ad1a-df47-48fe-8c93-869d26e683e6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6tDUHrPXW",
    "aws_user_pools_web_client_id": "1fgr9hu2dl9tp3dbrdnhi0eqj0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "exeltisreachum173239-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "exeltisreachum-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1cptzirysbcj5.cloudfront.net"
};


export default awsmobile;
