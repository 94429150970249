import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import {useParams} from "react-router-dom";
import "../css/uploadurl.css";
import {getPlayslide} from "../../graphql/queries";
import Grid from "@material-ui/core/Grid"

function Vimeoedit() {
    const { id, rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [val, setVal] = useState("");
    const [images, setImages] = useState([]);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [thislurlfile, setThislurlfile] = useState('');
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
            const newdatas = images.filter(data => data.rid === rid);
            const lurls = newdatas[0].lurl;
            setThislurlfile(lurls)
            setVal(lurls)
        })()
    }, []);
    const handleChange = e => {
        setVal(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const old = JSON.stringify(images).replace(thislurlfile, val);
        const newval = JSON.parse(old);
        try {
            const input = {
                id,
                playlistlinks: newval
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setUploadedmessage("Uploading...")
            setUploadedmessage("Updated!")

        }catch(err){
            console.log(err)
        }
    }

    return (
        isOwner === isUser ?
        <>
        <div className="uploadurl">
                    <section>
                        <p>
                            Update a Youtube or Vimeo video
                        </p>
                    </section>
                    <br/>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                <input onChange={handleChange} value={val} className="form-control" name="clink" placeholder="URL"/>
                                </Grid>
                                <Grid item xs={3}>
                                <input type="submit" value="Update" className="answersubmit"/>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                   <p className="uploaded">{uploadedmessage}</p>
                    <br/>
            </div>
            </>
            :""
    );
}

export default Vimeoedit;
