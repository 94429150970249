import React from "react";
import {useHistory} from "react-router-dom";
import "./css/foot.css";

function  Foot(){
    let history = useHistory();
    function gocon(){
        history.push("/termandConditions");
    }
    return (
        <>
        <div className="footer">
            <p><span className="ptext" onClick={gocon}>Terms of Service</span></p>
            <p>© 2024 Click-Video LLC All rights reserved. <br/> Patent No: 11757666</p>
        </div>
            </>
    )
}


export default Foot;
