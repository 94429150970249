import React, {useEffect, useState} from 'react'
import {API, Auth} from "aws-amplify";
import {getDraganddrop} from "../../../graphql/queries";
import {useParams} from "react-router-dom";

export default function Correcttaptap () {
  const { id, rid } = useParams();
  const [dquestion, setDquestion] = useState('')
  const [categoris, setCategoris] = useState([])
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getDraganddrop,
        variables:{id:rid}
      })
      const datas = response.data.getDraganddrop;
      const q = datas.dquestion
      setDquestion(q)
      const t1 = datas.categories;
      setCategoris(t1)
    })()
  }, []);
  const renderData = () => {
    return (
        <div className="wraptaptap">
          {categoris.map((person, index) => (
              <div key={index} className="taptaplist">
                <h5>{person.name}</h5>
                <hr className="greenthis" />
                <div className='listtaptap'>
                  {person.items.map((hobby, hobbyIndex) => (
                      <div key={hobbyIndex} className='taptaplistitem'>
                        {hobby.text}<br/>
                        <img src={hobby.image} className="correctimages"/>
                      </div>
                  ))}
                </div>
              </div>
          ))}
        </div>
    );
  };

  return (
      <div className="correctanswerpage">
      <div className="corrctcentermath">
        <h4>{dquestion}</h4>
        <p>The correct answer is: </p>
      <div>
        {renderData()}
      </div>
        <br/>
        <br/>
        <br/>
    </div>
     </div>
  )
}
