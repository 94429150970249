import {Auth,API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide,listPlayslides,listFolders,listDesktopitems} from "../../graphql/queries";
import {useHistory, useParams} from "react-router-dom";
import {newUpdatePlayslide,newDeletePlayslide} from "../../graphql/subscriptions";
import {updatePlayslide,updateFolders,updateDesktopitems} from "../../graphql/mutations";
import {Modal} from 'react-bootstrap';
import {Form} from "element-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '../../img/delete.png';
import Copys from '../../img/copy.png';
import "../css/editroom.css";
import Leftarrow from "../../img/left-arrow.png";
import Noi from "../../img/no.png";
import Duplicate from "../../img/dash/Duplicate-Button.png";
import Editb from "../../img/dash/edit.png";
import Allmove from "../../img/Move.svg";
import Zoomin from "../../img/zoom-in.png";
import Zoomout from "../../img/zoom-out.png";
import Editbs from "./dashboard/icons/Edit.svg";
import Teamsmenus from "../teams/teamsmenus";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from '../SortableItem';

function Teamseditroom() {
    const { id } = useParams();
    const history = useHistory();
    const [isFixed, setIsFixed] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isUsere, setIsUsere] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [purls, setPurls] = useState([]);
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [ymeetingid, setYmeetingid] = useState();
    const [duplicateopen, setDuplicateopen] = useState(true);
    const [indexs, setIndexs] = useState(false);
    const [titless, setTitless] = useState(false);
    const [show2, setShow2] = useState(false);
    const [isopen, setIsopen] = useState(true);
    const [condata, setCondata] = useState([]);
    const [eurls, setEurls] = useState("");
    const [changesavedmessage, setChangesavedmessage] = useState("");
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {setShow1(false)};
    const handleClose2 = () => setShow2(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsUser(user.attributes.email);
                setIsUsere(user.attributes.email)
            })
            .catch(err => console.log(err));
        fetchData();
        fetchcata();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe()
        }
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
        setIsOwner(iowner);
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const emeetingid = datas.mid;
        setYmeetingid(emeetingid);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
            setDuplicateopen(false)
        }
    }
    async function fetchcata() {
        const response = await API.graphql({
            query: listPlayslides
        });
        const datas = response.data.listPlayslides.items;
        setCondata(datas);
    }
    const handleChange = (e, id) => {
        e.preventDefault();
        const newContentIndex = playcontents.findIndex(content => content.rid === id);
        const newContent = playcontents[newContentIndex];
        newContent.title = e.target.value;
        const clonedPlaycontents = [...playcontents];
        clonedPlaycontents.splice(newContentIndex, 1, newContent);
        setPlaycontents(clonedPlaycontents);
    }
    const sid = id;
    const handleDelete = async (id) => {
        const newPlayContents = playcontents.filter(content => content.rid !== id);
        try {
            const input = {
                id: sid,
                playlistlinks: newPlayContents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setPlaycontents(newPlayContents);
    }
    const rendercondata = () => {
        return condata.map(item => (
            item.owner === isUser ?
                <option key={item.id} value={item.id}>{item.meetingname}</option>
                : ""
        ))
    }
    const myFunction = () => {
        var x = document.getElementById("mySelect").value;
        API.graphql({
            query: getPlayslide,
            variables: { id: x }
        }).then(datas => {
            const cdata = datas.data.getPlayslide;
            const condata = cdata.playlistlinks;
            const replaced = condata.map((element) => {
                const urlElements = element.rurl.split('/');
                const ee = element.eurl;
                let eurlElements;
                let neweurl;
                if(ee) {
                    eurlElements = ee.split('/');
                    eurlElements[4] = id;
                    neweurl = eurlElements.join('/');
                }
                urlElements[4] = id;
                const newUrl = urlElements.join('/');
                const newElements = {...element, rurl: newUrl, eurl: neweurl};
                return newElements;
            });
            try {
                const input = {
                    id,
                    playlistlinks: replaced
                };
                API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }catch(err){
                console.log(err)
            }
        })
    }
    const  handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const input = {
                id,
                playlistlinks: playcontents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setChangesavedmessage("Saved")
        setTimeout(() => {
            setChangesavedmessage("")
        }, 1000);
    }
    async function changeroomname(event){
        event.preventDefault();
        setYmeeting(event.target.value);
    }

    useEffect(() => {
        const delay = 2000;
        const timeoutId = setTimeout(() => {
            saveInputValue(ymeeting);
        }, delay);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [ymeeting]);

    const saveInputValue = async (value) => {
        try {
            const input = {
                id,
                meetingname: value
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            const user = await Auth.currentAuthenticatedUser();
            const thisuser = user.attributes.email;
            const response = await API.graphql({
                query: listFolders,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listFolders.items;
            if(datas.length !== 0) {
                const thisfolderid = datas[0].id;
                const thisfolder = JSON.parse(datas[0].folderlists);
                const updatedData = { ...thisfolder };
                for (const key in updatedData) {
                    updatedData[key].items = updatedData[key].items.map(item => {
                        if (item.id === id) {
                            return { ...item, meetingname: value };
                        }
                        return item;
                    });
                }
                const input = {
                    id: thisfolderid,
                    folderlists: JSON.stringify(updatedData)
                }
                await API.graphql({
                    query: updateFolders,
                    variables: {input}
                });
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const thisitemid = datas1[0].id;
                const thisfolderitem = JSON.parse(datas1[0].deskitems);
                const updatedData1 = thisfolderitem.map(item => {
                    if (item.id === id) {
                        return { ...item, meetingname: value };
                    }
                    return item;
                });
                const input1 ={
                    id:thisitemid,
                    deskitems: JSON.stringify(updatedData1)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input:input1}
                });
            }
        } catch (err) {
            console.log(err)
        }
    };
    function gobackthis(){
        history.push(`/teams`);
    }

    function thiszoomin(){
        setIsopen(false);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "141px";
            element.style.height = "120px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
    }
    function thiszoomout(){
        setIsopen(true);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "235px";
            element.style.height = "200px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
    }
    class SmartSensor extends PointerSensor {
        static activators = [
            {
                eventName: 'onPointerDown',
                handler: ({ nativeEvent }) => {
                    if (nativeEvent?.target?.tagName?.toLowerCase() === 'input') {
                        return false
                    }
                    return true
                }
            }
        ]
    }
    const sensors = useSensors(
        useSensor(SmartSensor, {
            activationConstraint: {
                distance: 5
            }
        }),
    );
    const [movedIds, setMovedIds] = useState([])
    const [moveTimeout, setMoveTimeout] = useState()
    const [selectedIds, setSelectedIds] = useState([])
    function selectId (id) {
        setSelectedIds(current => {
            return [...current, id]
        })
    }
    function toggleSelect(id) {
        setSelectedIds(current => {
            const selected = current.includes(id)
            if (selected) {
                return current.filter(currentId => currentId !== id)
            } else {
                return [...current, id]
            }
        })
    }
    const itemViews = playcontents.map((itm, index) => {
        function handleMouseDown() {
            toggleSelect(itm.rid)
        }
        const selected = selectedIds.includes(itm.rid);
        const moved = movedIds.includes(itm.rid);
        return (
            <SortableItem
                key={itm.rid}
                id={itm.rid}
                selected={selected}
                moved={moved}
            >
                <div className="lialist">
                    <Grid container spacing={0} className="topedit">
                        <Grid item xs={3} className="leftside">
                            <div className="upgradenew2" onMouseDown={handleMouseDown}>
                                <img src={Allmove} width="12" height="12" className="movethis"/>
                                <span className="editspan">{Number(index) + Number(1)}</span>
                                <span className="tooltiptext2">Choose the items you want to rearrange, and then drag them to reorder.</span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            {!itm.adobeprojectid ?
                                <div className="upgradenew2">
                                    <img src={Editb} onClick={() => {
                                        setEurls(itm.eurl);
                                        setShow(true);
                                    }} width="18" height="18"/>
                                    <span className="tooltiptext2">Click here to edit the content.</span>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={4} className="leftside">
                            <CopyToClipboard text={itm.rurl}>
                                <div className="upgradenew1">
                                    <img src={Copys} width="18" height="18" />
                                    <span className="tooltiptext1">Click to copy link</span>
                                </div>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item xs={2} className="rightside">
                            <div className="upgradenew1">
                                <img src={DeleteIcon} width="18" height="18" onClick={() => {
                                    handleDelete(itm.rid);
                                }} />
                                <span className="tooltiptext1">Click to delete</span>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="imgslist">
                        <div className="upgradenew1">
                            <div className="imgslist">
                                <img src={Noi} className="editnoi" onClick={() => {
                                    setPurls(itm.rurl);
                                    setIndexs(index);
                                    setTitless(itm.title);
                                    setShow2(true);
                                }} />
                                <iframe src={itm.rurl} scrolling="no" className="editsframelook" loading="lazy"></iframe>
                            </div>
                            <span className="tooltiptext1">Click to preview</span>
                        </div>
                    </div>
                    <input
                        value={itm.title}
                        onChange={(e) => {
                            handleChange(e, itm.rid);
                            if (e.keyCode === 13){
                                handleSubmit();
                            }
                        }}
                        name="title"
                        placeholder="title"
                        className="editroomlist"
                    />
                </div>
            </SortableItem>
        )
    })
    const selectedItems = playcontents.filter(item => {
        return selectedIds.includes(item.rid)
    })
    const overlayViews = selectedItems.map((item,index) => {
        return (
            <SortableItem
                key={item.rid}
                id={item.rid}
                selected
                moved={false}
            >
                <div className="centerthis">
                    <br/>
                    <br/>
                    <p>{item.title}</p>
                </div>
            </SortableItem>
        )
    })
    function handleDragStart ({ active }) {
        selectId(active.id)
    }
    function handleDragEnd(event) {
        const { active, over } = event;
        setPlaycontents((current) => {
            const overSelected = selectedIds.includes(over.id)
            if (overSelected) {
                const withoutSelectedExceptOver = current.filter(item => {
                    const selected = selectedIds.includes(item.rid)
                    if (!selected) {
                        return true
                    }
                    const overItem = item.rid === over.id
                    if (overItem) {
                        return true
                    }
                    return false
                })
                const newOverIndex = withoutSelectedExceptOver.findIndex(item => item.rid === over.id)
                const beforeNewOver = withoutSelectedExceptOver.slice(0, newOverIndex)
                const afterNewOver = withoutSelectedExceptOver.slice(newOverIndex + 1)
                const selectedItems = current.filter(item => {
                    return selectedIds.includes(item.rid)
                })
                const newItems = [...beforeNewOver, ...selectedItems, ...afterNewOver]
                return newItems
            } else {
                const activeIndex = current.findIndex(item => item.rid === active.id)
                const overIndex = current.findIndex(item => item.rid === over.id)
                const forward = overIndex > activeIndex
                const selectedItems = current.filter(item => {
                    return selectedIds.includes(item.rid)
                })
                const newWithout = current.filter(item => {
                    return !selectedIds.includes(item.rid)
                })
                const newOverIndex = newWithout.findIndex(item => item.rid === over.id)
                const overFirst = newOverIndex === 0
                if (overFirst && !forward) {
                    return [...selectedItems, ...newWithout]
                }
                const offset = forward ? 1 : 0
                const beforeNew = newWithout.slice(0, newOverIndex + offset)
                const afterNew = newWithout.slice(newOverIndex + offset)
                const newArray = [...beforeNew, ...selectedItems, ...afterNew]
                return newArray
            }
        });
        setMovedIds(selectedIds)
        if (moveTimeout != null) {
            clearTimeout(moveTimeout)
        }
        const newMoveTimeout = setTimeout(() => {
            setMovedIds([])
        }, 2000)
        setMoveTimeout(newMoveTimeout)
        setSelectedIds([])
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsFixed(scrollTop > 450); // Adjust this value based on when you want the div to become fixed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        isOwner === isUser ?
        <>
            <div className="editroom">
                <div className="input-container">
                    <input type="text" value={ymeeting} onChange={changeroomname} className="editroomnamebig" /><img src={Editbs} className="inputicon" width="28px"/>
                </div>
                <br/>
                    <div className="saves">
                        <Grid container spacing={0} className="topspace1">
                            <Grid item xs={3}>
                                <h5 className="sharedropbtn" onClick={gobackthis}><img src={Leftarrow} width="13" height="13" /> Room List </h5>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                                <span className="editcontents">Content Manager</span>
                            </Grid>
                            <Grid item xs={3} className="cccenter">
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                                {duplicateopen ?
                                    <>
                                        <select id="mySelect" name="meetingname">
                                            <option> Room to Duplicate </option>
                                            {rendercondata()}
                                        </select>
                                        <div className="upgradenew2">
                                            <img src={Duplicate} onClick={myFunction} className="dropbtn" />
                                            <span className="tooltiptext2">Duplicate to this room</span>
                                        </div>
                                    </>
                                    :""
                                }
                            </Grid>
                            <Grid item xs={3} className="cccenter">

                            </Grid>
                        </Grid>
                        <div className={isFixed ? 'fixed-divedit' : ''}>
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3} className="cccenter">
                                <button onClick={() => {
                                console.log("link")
                                }} className="gosharetoteams">Share to Teams</button>
                            </Grid>
                            <Grid item xs={6} className="centerside">

                            </Grid>
                            <Grid item xs={3} className="cccenter">
                                <button className="addcontentbtn" onClick={()=>setShow1(true)}> Add Content</button>
                            </Grid>
                        </Grid>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="saveseditchage">
                            <div className={isFixed ? 'fixed-diveditsave' : ''}>
                            <Grid container spacing={0} className="topspace">
                                <Grid item xs={3} className="cccenter">
                                </Grid>
                                <Grid item xs={6} className="cccenter">
                                    <span className="redthis">{changesavedmessage} </span><input type="submit" value="Save Changes" className="inputsavechanges" />
                                </Grid>
                                <Grid item xs={3} className="cccenter">
                                    <div className="zooms">
                                        {isopen ?
                                            <img src={Zoomin} onClick={thiszoomin} width="32" height="32"/>
                                            :
                                            <img src={Zoomout} onClick={thiszoomout} width="32" height="32"/>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        </div>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <SortableContext items={playcontents}>
                                {itemViews}
                            </SortableContext>
                            <DragOverlay>
                                {overlayViews}
                            </DragOverlay>
                        </DndContext>
                    <br/>
            </Form>
            </div>
            <Modal show={show1} onHide={handleClose1} aria-labelledby="contained-modal-title-vcenter" size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Add Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Teamsmenus id={id} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{indexs+1}. {titless}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {purls !== "" && (
                        <iframe
                            src={purls}
                            loading="lazy"
                            width="100%"
                            height="700px"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen,autoplay"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {eurls !== "" && (
                        <iframe
                            src={eurls}
                            loading="lazy"
                            width="100%"
                            height="700px"
                            title="maincontents"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
            :""
    );
}
export default Teamseditroom;

