import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import centerAspectCrop from "./centerAspectCrop";
import Grid from "@material-ui/core/Grid";
import 'react-image-crop/dist/ReactCrop.css'
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          250,
          250,
          "JPEG",
          95,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
      );
    });
export default function CategoryItem ({ item, itemIndex, categories, categoryIndex, setCategories }) {
  function handleItemChange (property, value) {
    setCategories(categories.map((category, currentCategoryIndex) => {
      if (currentCategoryIndex === categoryIndex) {
        const items = category.items.map((item, currentItemIndex) => {
          if (currentItemIndex === itemIndex) {
            return {
              ...item,
              [property]: value
            }
          }
          return item
        })
        return {
          ...category,
          items
        }
      }
      return category
    }))
  }
  function handleItemTextChange (event) {
    handleItemChange('text', event.target.value)
  }
  function handleItemImageChange (url) {
    handleItemChange('image', url);
  }
  function handleDelete () {
    setCategories(currentCategories => {
      return currentCategories.map((currentCategory, currentCategoryIndex) => {
        if (currentCategoryIndex === categoryIndex) {
          const newItems = currentCategory.items.filter((currentItem, currentItemIndex) => {
            if (currentItemIndex === itemIndex) return false
            return true
          })
          return {
            ...currentCategory,
            items: newItems
          }
        }
        return currentCategory
      })
    })
  }
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState({ active: 16 / 9 })
  const [completedCrop, setCompletedCrop] = useState()


  async function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const image = await resizeFile(e.target.files[0]);
      handleItemImageChange(image)
    }
  }

  function onImageLoad(e) {
    const { naturalWidth, naturalHeight } = e.currentTarget
    //setCrop(centerAspectCrop(width, height, 1))
  }

  function handleCrop() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const result = reader.result?.toString() || ''
        handleItemImageChange(result)
        setCrop(undefined)
        setCompletedCrop(undefined)
      }
    })
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
        )
      }
    },
    100,
    [completedCrop, itemIndex, item.image],
  )
  return (
    <li key={itemIndex} className="newdragdroplist">

        <Grid container spacing={0}>
          <Grid item xs={10}>
        <input
          style={{ height: '25px', marginBottom:"5px" }}
          placeholder='Item text'
          value={item.text}
          onChange={handleItemTextChange}
          className="ques"
        />
          </Grid>
          <Grid item xs={2} className="rightside">
            <img src={DeleteIcon} onClick={handleDelete} />
          </Grid>
        </Grid>
          <div className="thisbottom">
            <span>PNG or JPG file</span>
            <input type="file" accept="image/*" onChange={onSelectFile}/>
          </div>
          {item.image && (
            <div>
            <ReactCrop
              aspect={16 / 9}
              minWidth={96}
              minHeight={54}
              maxWidth={112}
              maxHeight={64}
              crop={crop}
              onChange={(_, percentCrop) => {

                setCrop(percentCrop)
              }}
              onComplete={(c) => setCompletedCrop(c)}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={item.image}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            </div>
          )}
          {completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
              <div>
                <button onClick={handleCrop}>Crop</button>
              </div>
            </>
          )}
    </li>
  )
}
