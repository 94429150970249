import React, { useState } from "react";
import Foot from "../components/Foot";
import Navbars from "../components/Navbar";
import {Helmet} from "react-helmet";
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Down from "../img/dash/down.png";
import "./css/support.css";

const Support = () => {
    const [key, setKey] = useState('home');
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    function gotothisfastpage(){
        openInNewTab("https://fast.com")
       }
    function gotothisspeedpage(){
        openInNewTab("https://www.speedtest.net")
    }
        return (
            <>
                <Helmet>
                    <title>SUPPORT</title>
                </Helmet>
                <Navbars />
                <div className="supportpage">
                            <div className="supportpageleft">
                                <div className="cccenter">
                            <h2>
                                Troubleshooting
                            </h2>
                                    <br/>
                            <h4>Running into issues?</h4>
                                </div>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Lagging or freezing video <img src={Down} /></Accordion.Header>
                                    <Accordion.Body>
                                      <p>When you are having this issue, is your computer heating up? </p>
                                        <ul>
                                            <li>If yes, it sounds like this could be related to your CPU.</li>
                                            <li>If no, the issue you are experiencing might be related to your network.Sometimes networks are unpredictable and could cause issues at any minute!</li>
                                        </ul>
                                        <p>I would suggest checking out our network requirement guide in the below.</p>
                                        <ul>
                                            <li>
                                                <h5>Required Speeds</h5>
                                            <p>At least 3.2 Mbps outbound (upload) and 3.2 Mbps inbound (download) are required.</p>
                                            <p>Network issues can be diagnosed by using a speed test site like <span onClick={gotothisfastpage} className="thisfasttext">https://fast.com/</span> or <span onClick={gotothisspeedpage} className="thisfasttext"> https://www.speedtest.net/</span>.</p>
                                            </li>
                                            <li>
                                               <h5>Network Configuration & Security</h5>
                                               <ol>
                                                   <li>
                                                   <p>Firewall</p>
                                                   <p>If you or your company have restrictive firewall settings, you may experience some connection problems. For a smooth experience, we recommend:</p>
                                                       <ul>
                                                           <li>
                                                               Opening port 443 for outgoing traffic for both TCP and UDP.
                                                           </li>
                                                            <li>Additionally, outgoing traffic is preferably direct and not sent through a proxy.</li>
                                                       </ul>
                                                       <br/>
                                                   </li>
                                                   <li>
                                                       <p>VPN</p>
                                                       <p>Right now we don't recommend using REACHUM with a VPN.</p>
                                                   </li>
                                                   </ol>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>I can't see/hear everyone in my meeting or only seeing a blank white page <img src={Down} /></Accordion.Header>
                                    <Accordion.Body>
                                   <p>Since REACHUM is browser based, it is important to make sure that everyone's set up is up to par with our set up. If there are black frames or someone cannot be heard, it might be related to permissions, browser, device  or network.</p>
                                   <p>Below are some tips on how to resolve this issue: </p>
                                        <ul>
                                            <li>Refresh your browser</li>
                                            <li>Turn off any software running on your computer that you do not need for the meeting. Close all unnecessary browser windows, tabs, and any video intensive software.</li>
                                            <li>Clear the browser cache and restart the browser</li>
                                            <li>Remove and REACHUM background effect</li>
                                            <li>Turn off REACHUM noise reduction</li>
                                            <li>Make you are running the latest version of the browser. There have been reports in the past where similar issues happen when a connection is made through a Safari or Edge browser. For good practice, we recommend using some of standards compliant browsers such as Chrome or Firefox to see if the issue persists.</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Troubleshoot Screen Sharing Issues <img src={Down} /></Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>
                                        <h5>Browser Screen Sharing Permissions</h5>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                            className="mb-3"
                                        >
                                            <Tab eventKey="home" title="Chrome">
                                               <h6>Chrome</h6>
                                                <p>Chrome doesn't have any specific settings that limit screen access. If you're running into problems or getting an error go to the Operating System Permissions area for further troubleshooting.</p>
                                            </Tab>
                                            <Tab eventKey="profile" title="Firefox">
                                              <h6>Firefox</h6>
                                                <p>When you first use screen sharing in Firefox, you'll see a pop up that asks if you'd like to allow REACHUM to access your screen</p>
                                                <p>If you initially clicked Don't Allow, Firefox won't show this prompt again when you try to share your screen and won't show any sharing options. In this case, you'll need to manually reset the permissions for screen access by doing the following:</p>
                                            <ol>
                                                <li>Click on the "Site information" area, just to the left of the website address</li>
                                                <li>
                                                    Locate the "Share the Screen" item in the list, and click the "X" next to it
                                                </li>
                                                <li>Refresh the page, and click on the Share button in the bottom toolbar to re-prompt the screen sharing notification</li>
                                            </ol>
                                            </Tab>
                                        </Tabs>
                                            </li>
                                        <br/>
                                        <br/>
                                        <li>
                                        <h5>Operating System Permissions</h5>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                            className="mb-3"
                                        >
                                            <Tab eventKey="home1" title="MacOS">
                                                <h6>MacOS</h6>
                                                <p>For devices using MacOS Catalina or newer, Apple has introduced system-level permissions for accessing screen sharing. If you've recently updated or upgraded your device and can't screen share anymore, you'll need to update the preferences by doing the following:</p>
                                            <ol>
                                                <li>Open up System Preferences on your Mac</li>
                                                <li>Click on the Security and Privacy section</li>
                                                <li>Scroll down to the Screen Recording section, and ensure that your browser is check. You may need to unlock your settings first in the bottom left.</li>
                                            <li>After doing this, fully Quit your browser and re-open it so the new permissions are applied</li>
                                            </ol>
                                            </Tab>
                                            <Tab eventKey="profile1" title="Windows">
                                                <h6>Windows</h6>
                                                <p>At the moment Windows doesn't have any system-level permissions for screen access, so if you're running into problems or getting an error, double-check your browser settings.</p>
                                            </Tab>
                                        </Tabs>
                                        </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                                <br/>
                                <br/>
                                <div className="cccenter">
                                <p>Still Need help? <br/><br/> Please reach out to support@reachum.com. <br/>
                                    We'll respond promptly.</p>
                                </div>
                            </div>
                </div>
                <Foot />
        </>
        )
}

export default Support;
