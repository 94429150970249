import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {getVideooptionslist} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/correctanswerpage.css";

function Videocorrctanswer() {
    const [thisqq, setThisqq] = useState("");
    const [ans, setAns] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getVideooptionslist,
            variables:{id:rid}
        })
        const datas = response.data.getVideooptionslist;
       const qq = datas.vquestion;
        const tests = datas.vtest;
        const ct = datas.vanswer;
        const anss = tests[ct].panswer;
        setAns(anss)
       setThisqq(qq);
    }

    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <h4>{thisqq}</h4>
                <p>The correct answer is: </p>
                <div className="redthis">
                    {ans}
                </div>
            </div>
            </div>
    );
}

export default Videocorrctanswer;
