import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import {getPlayslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/poll.css";
import  Drawedit  from "./drawedit";
import Grid from "@material-ui/core/Grid";


function Blackboardedit() {
	const { id, rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [imgdata, setImgdata] = useState("");
	const [uploadedmessages, setUploadedmessages] = useState("");
	const [thislurlfile, setThislurlfile] = useState('');
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
			const newdatas = images.filter(data => data.rid === rid);
			const lurls = newdatas[0].lurl;
			setThislurlfile(lurls)
		})()
	}, []);
	const handleChildData = (data) => {
		setImgdata(data)
	};
	const saveData = async () => {
		const old = JSON.stringify(images).replace(thislurlfile, imgdata);
		const newval = JSON.parse(old);
		try {
			const input = {
				id,
				playlistlinks: newval
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input}
			});
			setUploadedmessages("Updated")
		}catch(err){
			console.log(err)
		}
	}

	return (
		isOwner === isUser ?
				<div className="orderlist">
					<Drawedit onChildData={handleChildData} thislurlfile={thislurlfile}/>
					<Grid container spacing={0} className="topspace1">
						<Grid item xs={5}>
						</Grid>
						<Grid item xs={3}>
							<p className="redthis">{uploadedmessages}</p>
						</Grid>
						<Grid item xs={4} className="centerthis">
					<button className="answersubmit" onClick={saveData}>Update</button>
						</Grid>
					</Grid>
				</div>
			:""
	);
}
export default Blackboardedit;
